import PasswordEditor from "../../components/profile/PasswordEditor";
import CustomerEditor from "../../components/profile/CustomerEditor";
import CustomerDashboardLayout from "../../components/layout/CustomerDashboardLayout";
import CustomerDashboardNavigation from "../../components/layout/CustomerDashboardNavigation";
import DashboardPageHeader from "../../components/layout/DashboardPageHeader";
import Person from "@mui/icons-material/Person";
import { Button, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getCustomerProfile } from "../../clients/customer-profile";
import AccountDeletion from "../../components/profile/AccountDeletion";
import FlexBox from "../../components/FlexBox";
import AgreementsEditor from "../../components/profile/AgreementsEditor";
import Card1 from "../../components/Card1";
import { getPageContent } from "../../clients/cms";

const ProfileEditor = () => {
  const [profile, setProfileValues] = useState({});
  const [profileData, setProfileData] = useState();
  const [checkbox_pagecontent, set_checkbox_pagecontent] = useState();
  const [isValuesLoaded, setIsValuesLoaded] = useState(false);

  useEffect(() => {
    getCustomerProfile()
      .then((response) => {
        const { data } = response;
        const { first_name, last_name, phone_number } = data.attributes;
        setProfileValues({ id: data.id, first_name, last_name, phone_number });
        setIsValuesLoaded(true);
        setProfileData(data);
      })
      .catch((error) => {});
    getPageContent("signup-checkboxes").then((response) => {
      const { data } = response;
      set_checkbox_pagecontent(data);
    });
  }, []);

  return (
    <CustomerDashboardLayout>
      <DashboardPageHeader
        icon={Person}
        title="Edycja profilu"
        button={
          <Link to="/profile">
            <Button
              color="primary"
              sx={{
                px: "2rem",
                bgcolor: "primary.light",
              }}
            >
              Powrót do profilu
            </Button>
          </Link>
        }
        navigation={<CustomerDashboardNavigation />}
      />
      <FlexBox
        sx={{
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <CustomerEditor values={profile} isValuesLoaded={isValuesLoaded} />
        <PasswordEditor
          values={{ old_password: "", password: "", password_confirmation: "" }}
        />
        {(!profileData || !checkbox_pagecontent) && (
          <Card1 sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Card1>
        )}
        {!!profileData && !!checkbox_pagecontent && (
          <AgreementsEditor
            profileData={profileData}
            checkbox_pagecontent={checkbox_pagecontent}
          />
        )}
        <AccountDeletion />
      </FlexBox>
    </CustomerDashboardLayout>
  );
};

export default ProfileEditor;
