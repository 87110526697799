import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const icon = L.icon({
  iconUrl: "/assets/images/leaflet/images/marker-icon.png",
  iconSize: [25, 41], // size of the icon
  iconAnchor: [13, 41],
});

const lineOptionsGray = {
  color: "gray",
};

const makeTransportLines = (markers) => {
  let lines = [];
  markers.forEach((marker, idx) => {
    if (idx !== 0) {
      lines = [
        ...lines,
        <Polyline
          key={`${marker.label}${idx}`}
          pathOptions={lineOptionsGray}
          positions={[marker.pos, markers[idx - 1].pos]}
        />,
      ];
    }
  });
  return lines;
};

const TransportMap = ({ markers = [] }) => {
  return (
    <MapContainer
      style={{ height: "400px" }}
      center={markers[0].pos}
      zoom={6}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {markers.map((marker) => (
        <Marker key={marker.label} position={marker.pos} icon={icon}>
          <Popup>{marker.label}</Popup>
        </Marker>
      ))}
      {makeTransportLines(markers)}
    </MapContainer>
  );
};
export default TransportMap;
