import {
  Skeleton,
  Box,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { H1, H2, H6 } from "../Typography";
import FlexBox from "../FlexBox";

const ProductSkeleton = () => {
  return (
    <Box width="100%">
      <Grid container spacing={3} justifyContent="space-around">
        <Grid item md={8} xs={12} alignItems="center">
          <H1 mb={2}>{<Skeleton variant="text" />}</H1>

          <FlexBox alignItems="center" mb={2}>
            <Box>Producent:</Box>
            <H6 ml={1}>{<Skeleton variant="text" width="30ch" />}</H6>
          </FlexBox>

          <FlexBox alignItems="center" mb={2}>
            <Table size="small" padding="none">
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "0",
                    boxShadow: "none",
                  }}
                >
                  <TableCell>Rok</TableCell>
                  <TableCell>Waga</TableCell>
                  <TableCell>Roboczogodziny</TableCell>
                  <TableCell>Lokalizacja</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "0",
                    boxShadow: "none",
                  }}
                >
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {<Skeleton variant="text" />}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {<Skeleton variant="text" />}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {<Skeleton variant="text" />}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {<Skeleton variant="text" />}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </FlexBox>

          <Box mb={2}>
            <H2 color="primary.main" mb={0.5} lineHeight="1">
              {<Skeleton variant="text" />}
            </H2>
            <H6>{<Skeleton variant="text" />}</H6>
          </Box>
        </Grid>

        <Grid item md={4} xs={12} alignItems="center">
          <Box>
            <FlexBox>
              <Skeleton variant="rectangular" width="400px" height="300px" />
            </FlexBox>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ProductSkeleton;
