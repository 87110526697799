import { Typography } from "@mui/material";
import React from "react";
import FlexBox from "../FlexBox";
import useWindowSize from "../../hooks/useWindowSize";

const OrderStatus = ({ status }) => {
  const getColor = (status) => {
    switch (status) {
      case 0:
        return "grey";

      case 1:
        return "primary";

      case 2:
        return "secondary";

      case 3:
        return "warning";

      case 4:
        return "success";
      default:
        return "";
    }
  };
  const getInfo = (status) => {
    switch (status) {
      case 0:
        return "oczekujące";

      case 1:
        return "przetwarzane";

      case 2:
        return "opłacone";

      case 3:
        return "wysłane";

      case 4:
        return "zakończone";

      default:
        return "";
    }
  };

  const width = useWindowSize();
  const breakpoint = 350;

  return (
    <FlexBox justifyContent={width < breakpoint ? "center" : "flex-end"}>
      <Typography
        p="0.5rem 1rem"
        borderRadius="300px"
        color={!!getColor(status) ? `${getColor(status)}.900` : "inherit"}
        bgcolor={!!getColor(status) ? `${getColor(status)}.100` : "none"}
        textAlign="center"
      >
        <b>{getInfo(status)}</b>
      </Typography>
    </FlexBox>
  );
};

export default OrderStatus;
