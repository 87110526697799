import {
  Card,
  Typography,
  Paper,
  useTheme,
  styled,
  Divider,
} from "@mui/material";
import FlexBox from "../FlexBox";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const Header = styled(Paper)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.primary.main,
  padding: "5px",
}));

// Wrapper for all question components that adds navigation and container
const QuestionRoot = ({
  question = "",
  question_group = "",
  children,
  handle_next,
  handle_prev,
  isLoading,
}) => {
  return (
    <Card
      sx={{
        width: "100%",
        minHeight: "50vh",
        padding: "5px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header>
        <Typography variant="h5">{question}</Typography>
        <Typography variant="h7" color="gray.600">
          {question_group}
        </Typography>
      </Header>
      <FlexBox
        sx={{
          flex: 1,
          flexDirection: "column",
          margin: "10px 0 10px 0",
          gap: "15px",
        }}
      >
        {children}
      </FlexBox>

      <Divider />
      <FlexBox
        sx={{
          justifyContent: "space-between",
          marginTop: "5px",
        }}
      >
        <LoadingButton
          onClick={handle_prev}
          startIcon={<ArrowBackIosNewIcon />}
          loadingPosition="start"
          variant="outlined"
          disabled={isLoading || !handle_prev}
          color="primary"
        >
          Poprzednie
        </LoadingButton>
        <LoadingButton
          onClick={handle_next}
          endIcon={<ArrowForwardIosIcon />}
          loading={isLoading}
          disabled={!handle_next}
          loadingPosition="end"
          variant="outlined"
          color="primary"
        >
          Następne
        </LoadingButton>
      </FlexBox>
    </Card>
  );
};
export default QuestionRoot;
