class StateService {
  constructor() {
    this._state = null;
  }
  set state(state) {
    this._state = state;
  }
  get state() {
    return this._state;
  }
}

const stateService = new StateService();
export default stateService;
