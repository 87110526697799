export const machinesInitialState = {
  machines: [],
  newMachine: null,
  loadingNewMachine: false,
};
export const machinesReducer = (state, action) => {
  switch (action.type) {
    case "ADD_MACHINE":
      return { ...state, newMachine: action.payload, loadingNewMachine: false };
    case "NEW_MACHINE_LOADING":
      return { ...state, loadingNewMachine: true };
    case "NEW_MACHINE_LOADED":
      return { ...state, loadingNewMachine: false };
    default:
      return { ...state };
  }
};
