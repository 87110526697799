import CustomerDashboardLayout from "../components/layout/CustomerDashboardLayout";
import CustomerDashboardNavigation from "../components/layout/CustomerDashboardNavigation";
import DashboardPageHeader from "../components/layout/DashboardPageHeader";
import Favorite from "@mui/icons-material/Favorite";
import { Card, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getFavoriteProducts } from "../clients/favorite-products";
import ProductCard from "../components/product-cards/ProductCard";
import ProductCardSkeleton from "../components/product-cards/ProductCardSkeleton";
import { useAppContext } from "../contexts/app/AppContext";

const WishList = () => {
  const { state } = useAppContext();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fecth favourite products
  useEffect(() => {
    setIsLoading(true);
    getFavoriteProducts()
      .then((response) => {
        const { data } = response;
        setData(data);
        setIsLoading(false);
      })
      .catch(() => {});
  }, []);

  // filter favourites on list change
  useEffect(() => {
    setData(data.filter((elem) => state.user.favourites.includes(elem.id)));
  }, [state.user.favourites]);

  return (
    <CustomerDashboardLayout>
      <DashboardPageHeader
        title="Ulubione"
        icon={Favorite}
        navigation={<CustomerDashboardNavigation />}
      />
      <Grid container spacing={3}>
        {isLoading &&
          [1, 2, 3].map((item) => (
            <Grid item lg={4} sm={6} xs={12} key={item.toString()}>
              <ProductCardSkeleton />
            </Grid>
          ))}
        {!isLoading &&
          data.map((item) => (
            <Grid item lg={4} sm={6} xs={12} key={item.id.toString()}>
              <ProductCard item={item} />
            </Grid>
          ))}
      </Grid>
      {!isLoading && data.length == 0 && (
        <Card
          sx={{
            padding: "20px",
            marginTop: "20px",
          }}
        >
          <Typography color="grey.600" variant="h6">
            Nie masz żadnych ulubionych produktów. Możesz je dodać klikając
            ikonę serca.
          </Typography>
        </Card>
      )}
    </CustomerDashboardLayout>
  );
};

export default WishList;
