import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import Cookies from "js-cookie";
import navigateService from "../utils/navigateService";
import dispatchService from "../utils/dispatchService";
import stateService from "../utils/stateService";
import handleLogout from "../utils/handleLogout";
import { createSearchParams } from "react-router-dom";

const cache = setupCache({
  maxAge: 1000,
});

const apiClient = axios.create({
  baseURL: "https://admin.machiner.pro/api",
  timeout: 50000,
  adapter: cache.adapter,
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
    "Api-Version": 2,
    Authorization:
      Cookies.get("userAuth") === undefined
        ? "THE-SECRET-KEY"
        : "Bearer " + Cookies.get("userAuth"),
  },
});

// Redirect on 401
apiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status == 401) {
      const { user } = stateService.state;
      handleLogout(dispatchService.dispatch, user);
      if (navigateService.location.pathname != "/login") {
        navigateService.navigate({
          pathname: "/login",
          search: `?${createSearchParams({
            next: navigateService.location.pathname,
          })}`,
        });
      }
    }

    return Promise.reject(error);
  }
);

// Update token before every request
apiClient.interceptors.request.use(
  function (config) {
    config.headers["Authorization"] =
      Cookies.get("userAuth") === undefined
        ? "THE-SECRET-KEY"
        : "Bearer " + Cookies.get("userAuth");
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default apiClient;
