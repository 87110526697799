import { Typography, Card, Button } from "@mui/material";
import { Fragment } from "react";
import { Link } from "react-router-dom";

const ContactThankYou = ({ name }) => {
  return (
    <Fragment>
      <Typography mt="50px" variant="h4">
        Dziękujemy za kontakt, {name}
      </Typography>
      <Card
        sx={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          minWidth: "40vw",
          alignItems: "center",
          marginBottom: "50px",
        }}
      >
        <Typography>
          Spróbujemy sie z Panem/Panią skontaktować jak najszybciej to możliwe
        </Typography>
        <Link to="/">
          <Button variant="outlined" color="primary">
            Strona Główna
          </Button>
        </Link>
      </Card>
    </Fragment>
  );
};
export default ContactThankYou;
