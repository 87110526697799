import { Card, Divider, TextField, MenuItem, Typography } from "@mui/material";
import FlexBox from "../FlexBox";
import { layoutConstant } from "../../utils/constants";
import { useState, useMemo } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  getAvilableInspectors,
  orderInspection,
} from "../../clients/inspections";
import * as yup from "yup";
import { useFormik } from "formik";
import InputAdornment from "@mui/material/InputAdornment";
import { useEffect } from "react";
import { getDetailProduct } from "../../clients/products";
import { useAppContext } from "../../contexts/app/AppContext";
import {
  genericErrorToSnackbar,
  handleApiResponse,
} from "../../utils/api/error-handling/apiErrorHandler.js";
import { useNavigate } from "react-router-dom";
import { debounce } from "@mui/material/utils";

// Make inspector menu items from inspectors list - fetched from api on every date change
const makeInspectorMenuItems = (inspectors) => {
  return inspectors.map((inspector) => (
    <MenuItem
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr);",
      }}
      key={`${inspector.type}${inspector.id}`}
      value={inspector.id.toString()}
    >
      <Typography
        sx={{ width: "100%" }}
      >{`${inspector.attributes.first_name} ${inspector.attributes.last_name}`}</Typography>
      <Typography
        sx={{ width: "100%" }}
      >{`Cena: ${inspector.attributes.price}`}</Typography>
      <Typography sx={{ width: "100%" }}>{`Cena dojazdu: ${
        inspector.attributes.price_km
          ? inspector.attributes.price_km
          : "Nie można określić"
      }`}</Typography>
    </MenuItem>
  ));
};

export const OrderInspection = (props) => {
  const { machine_slug } = props;
  const { dispatch } = useAppContext();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingInspectors, setIsFetchingInspectors] = useState(true);
  const [avilableInspectors, setAvilableInspectors] = useState([]);
  const [machineData, setMachineData] = useState();

  // fetch product data by id passed in query params
  useEffect(() => {
    if (machine_slug) {
      getDetailProduct(machine_slug)
        .then((response) => {
          setMachineData(response.data);
        })
        .catch((err) => {
          genericErrorToSnackbar(err, dispatch);
        });
    }
  }, [machine_slug]);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    values.fk_product = machineData.id;
    values.price = values.price.toFixed(2);
    try {
      let response = await orderInspection(JSON.stringify(values));
      handleApiResponse(response, dispatch, {
        on_success: (response) => {
          navigate(`/inspections/${response.data.data.id}`);
        },
      });
      setIsLoading(false);
    } catch (err) {
      genericErrorToSnackbar(err, dispatch);
      setIsLoading(false);
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    validateForm,
  } = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema: formSchema,
  });

  const handleDateChange = debounce((new_date) => {
    setIsFetchingInspectors(true);
    getAvilableInspectors(new_date, machineData.id).then((inspectors) => {
      // reset select field after date change
      setFieldValue("fk_inspector", "");
      setFieldValue("price", "");
      setAvilableInspectors(inspectors);
      setIsFetchingInspectors(false);
    });
  }, 300);

  useEffect(() => {
    validateForm().then((validations) => {
      if (!validations.scheduled_date) {
        handleDateChange(values.scheduled_date);
      }
    });
  }, [values.scheduled_date]);

  // Show loading screen before loaded
  if (!machineData) {
    return (
      <Card
        sx={{
          minHeight: "40vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Card>
    );
  }

  return (
    <Card
      sx={{
        top: layoutConstant.topbarHeight + 50,
      }}
    >
      <FlexBox
        sx={{
          padding: "20px",
          gap: "10px",
          flexDirection: "column",
        }}
      >
        <FlexBox
          sx={{
            flexDirection: "row",
            gap: "5px",
            flexWrap: "wrap",
          }}
        >
          <TextField
            label="Produkt"
            variant="outlined"
            size="small"
            value={`${machineData.attributes.producer_name} ${machineData.attributes.model_name}`}
            onBlur={handleBlur}
            InputProps={{
              readOnly: true,
            }}
            sx={{
              flex: 1,
              minWidth: "15ch",
            }}
          />
          <TextField
            label="VIN"
            variant="outlined"
            size="small"
            value={machineData.attributes.vin}
            onBlur={handleBlur}
            InputProps={{
              readOnly: true,
            }}
            sx={{
              flex: 1,
              minWidth: "10ch",
            }}
          />
        </FlexBox>
        <Divider />
        <TextField
          name="scheduled_date"
          label="Data inspekcji"
          onBlur={(e) => {
            e.target.type = "text";
            handleBlur(e);
          }}
          onFocus={(e) => {
            e.target.type = "date";
          }}
          value={values.scheduled_date || ""}
          error={!!touched.scheduled_date && !!errors.scheduled_date}
          helperText={touched.scheduled_date && errors.scheduled_date}
          onChange={(e) => {
            handleChange(e);
          }}
          variant="outlined"
          size="small"
          placeholder=""
          InputProps={{
            inputProps: {
              min: new Date(Date.now() + 3600 * 1000 * 24)
                .toISOString()
                .split("T")[0],
            },
          }}
        />
        <TextField
          name="fk_inspector"
          label="Wybierz inspektora z listy dostępnych na wybraną datę"
          variant="outlined"
          size="small"
          placeholder="Inspektor"
          value={values.fk_inspector || ""}
          onBlur={handleBlur}
          error={!!touched.fk_inspector && !!errors.fk_inspector}
          helperText={touched.fk_inspector && errors.fk_inspector}
          onChange={(e) => {
            handleChange(e);
            let inspector = avilableInspectors.filter(
              (elem) => elem.id == e.target.value
            )[0];
            setFieldValue(
              "price",
              inspector.attributes.price + inspector.attributes.price_km
            );
          }}
          select
          disabled={isFetchingInspectors}
        >
          {makeInspectorMenuItems(avilableInspectors)}
        </TextField>
        <TextField
          name="price"
          label="Cena za wykonanie inspekcji"
          variant="outlined"
          size="small"
          value={values.price || ""}
          onBlur={handleBlur}
          disabled={!values.fk_inspector}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
          }}
        />
        <LoadingButton
          size="normal"
          color="primary"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={isFetchingInspectors}
          loadingPosition="center"
          variant="contained"
        >
          Zleć inspekcję
        </LoadingButton>
      </FlexBox>
    </Card>
  );
};
const initialValues = {
  fk_product: "",
  fk_inspector: "",
  scheduled_date: "",
  price: "",
};

const formSchema = yup.object().shape({
  fk_inspector: yup.number().required("Wybranie inpektora jest wymagane"),
  scheduled_date: yup
    .date()
    .required("Data inspekcji jest wymagana")
    .min(new Date(Date.now() + 1), "Data nie może wyć wcześniejsza niż jutro"),
  price: yup.number().required("Cena jest wymagana"),
});
