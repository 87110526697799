/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import FlexBox from "../FlexBox";
import PreviewIcon from "@mui/icons-material/Preview";
import BazarCard from "../BazarCard";
import { H3 } from "../Typography";
import { useAppContext } from "../../contexts/app/AppContext";
import { Box, Button, styled } from "@mui/material";
import FavouriteBox from "./FavouriteBox";

const StyledBazarCard = styled(BazarCard)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  margin: "auto",
  overflow: "hidden",
  transition: "all 250ms ease-in-out",
  borderRadius: "10px 10px 10px 10px",
  "&:hover": {
    boxShadow: theme.shadows[2],
    "& .css-1i2n18j": {
      display: "flex",
    },
    "& .controlBox": {
      display: "block",
    },
  },
}));
const ImageWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "inline-block",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));
const ImageBox = styled(Box)(({ theme }) => ({
  padding: 0,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  "& img": {
    borderRadius: "10px 10px 0 0",
  },
}));
const ContentWrapper = styled(Box)(() => ({
  padding: "1rem",
  "& .title, & .categories": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
const ButtonBox = styled(FlexBox)(({ theme }) => ({
  marginTop: "15px",
  justifyContent: "space-between",
  "& button": {
    color: "#fff",
    background: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.primary[400],
    },
  },
}));

const ProductCard14 = ({ item }) => {
  const { state } = useAppContext();

  return (
    <StyledBazarCard>
      <ImageWrapper>
        <ImageBox>
          <Link to={`/product/${item.attributes.slug}`}>
            <img
              src={
                item.attributes?.images[0]?.m || "/assets/images/img_404.png"
              }
              style={{
                height: "270px",
                width: "100%",
                objectFit: "cover",
              }}
            />
          </Link>
        </ImageBox>
      </ImageWrapper>

      <ContentWrapper>
        <Box flex="1 1 0" minWidth="0px" mr={1}>
          <Link to={`/product/` + item.attributes.slug}>
            <H3
              className="title"
              fontSize="14px"
              textAlign="left"
              fontWeight="600"
              color="text.secondary"
              mb={1}
              title={item.attributes.producer_name}
            >
              {item.attributes.producer_name} / {item.attributes.model_name}
            </H3>
            <H3
              className="subtitle1"
              fontSize="13px"
              textAlign="left"
              fontWeight="100"
              color="text.secondary"
              mb={1}
            >
              {item.attributes.machine_year} / {item.attributes.machine_weight}{" "}
              kg / {item.attributes.machine_hour} h
            </H3>
          </Link>

          <FlexBox alignItems="center" mt={0.5}>
            <Box pr={1} fontWeight="600" color="primary.main">
              {item.attributes.price.toFixed(2)} PLN
            </Box>
          </FlexBox>
        </Box>

        <ButtonBox>
          <Button
            component={Link}
            to={"/product/" + item.attributes.slug}
            variant="contained"
            sx={{
              py: "3px",
              width: "100%",
              fontSize: "13px",
            }}
          >
            <PreviewIcon sx={{ mr: "5px" }} /> Zobacz szczegóły
          </Button>
          {state.user?.user && <FavouriteBox product_id={item.id} />}
        </ButtonBox>
      </ContentWrapper>
    </StyledBazarCard>
  );
};

export default ProductCard14;
