import FlexBox from "../../components/FlexBox";
import CustomerDashboardLayout from "../../components/layout/CustomerDashboardLayout";
import CustomerDashboardNavigation from "../../components/layout/CustomerDashboardNavigation";
import DashboardPageHeader from "../../components/layout/DashboardPageHeader";
import { H3, H5, Small } from "../../components/Typography";
import Person from "@mui/icons-material/Person";
import { Avatar, Button, Card, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import React, { useEffect, useState, Fragment } from "react";
import { getCustomerProfile } from "../../clients/customer-profile";
import CompanyDetails from "../../components/profile/CompanyDetails";
import { useAppContext } from "../../contexts/app/AppContext";
import ProfileSkeleton from "../../components/profile/ProfileSkeleton";

const ProfilePage = () => {
  const { state } = useAppContext();

  const [profile, setProfileValues] = useState({});
  const [company, setCompanyValues] = useState({});
  const [isValuesLoaded, setIsValuesLoaded] = useState(false);
  const infoList = [
    {
      title: state.user?.messages?.attributes?.counters?.orders_buyer || 0,
      subtitle: "Zamówienia wysłane",
    },
    {
      title: state.user?.messages?.attributes?.counters?.orders_seller || 0,
      subtitle: "Zamówienia otrzymane",
    },
    {
      title: state.user?.messages?.attributes?.counters?.offers_buyer || 0,
      subtitle: "Oferty złożone",
    },
    {
      title: state.user?.messages?.attributes?.counters?.offers_seller || 0,
      subtitle: "Oferty otrzymane",
    },
  ];
  useEffect(() => {
    getCustomerProfile()
      .then((response) => {
        const { data } = response;
        const {
          first_name,
          last_name,
          email,
          phone_number,
          claim,
          last_login,
          created_at,
          favorite_products,
        } = data.attributes;
        const {
          name,
          address1,
          post_code,
          place,
          country,
          vat_number,
          regon,
          company_phone_number,
        } = data.relationships.company.attributes;
        setProfileValues({
          id: data.id,
          first_name,
          last_name,
          email,
          phone_number,
          claim,
          last_login,
          created_at,
          favorite_products,
        });
        setCompanyValues({
          id: data.id,
          name,
          address1,
          post_code,
          place,
          country,
          vat_number,
          regon,
          phone_number,
        });

        setIsValuesLoaded(true);
      })
      .catch((error) => {});
  }, []);

  const displayClaim = (claim) => {
    const claimMap = {
      seller: "sprzedawca",
      buyer: "kupujący",
      inspector: "inspektor",
    };
    return claim.map((elem, idx) => (
      <Fragment key={idx.toString()}>
        {idx > 0 && " - "}
        {claimMap[elem]}
      </Fragment>
    ));
  };

  return (
    <CustomerDashboardLayout>
      <DashboardPageHeader
        icon={Person}
        title="Mój profil"
        button={
          <Link to="/profile/edit">
            <Button
              color="primary"
              sx={{
                px: "2rem",
                bgcolor: "primary.light",
              }}
            >
              Edycja profilu
            </Button>
          </Link>
        }
        navigation={<CustomerDashboardNavigation />}
      />
      {isValuesLoaded ? (
        <Fragment>
          <Box mb={4}>
            <Grid container spacing={3}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Card
                  sx={{
                    display: "flex",
                    p: "14px 32px",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    src={
                      "https://avatars.dicebear.com/v2/initials/" +
                      profile.first_name +
                      " " +
                      profile.last_name +
                      ".svg"
                    }
                    sx={{
                      height: 64,
                      width: 64,
                    }}
                  />
                  <Box ml={1.5} flex="1 1 0">
                    <FlexBox
                      flexWrap="wrap"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <div>
                        <H5 my="0px">
                          {profile.first_name} {profile.last_name}
                        </H5>
                        <FlexBox alignItems="center">
                          <Typography color="primary.main">
                            {profile.email}
                          </Typography>
                        </FlexBox>
                      </div>

                      <Typography color="grey.600">
                        {displayClaim(profile.claim)}
                      </Typography>
                    </FlexBox>
                  </Box>
                </Card>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Grid container spacing={4}>
                  {infoList.map((item) => (
                    <Grid item lg={3} sm={6} xs={6} key={item.subtitle}>
                      <Card
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          height: "100%",
                          p: "1rem 1.25rem",
                        }}
                      >
                        <H3 color="primary.main" my="0px" fontWeight="600">
                          {item.title}
                        </H3>
                        <Small color="grey.600" textAlign="center">
                          {item.subtitle}
                        </Small>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <CompanyDetails company={company} />
        </Fragment>
      ) : (
        <ProfileSkeleton />
      )}
    </CustomerDashboardLayout>
  );
};

export default ProfilePage;
