import { showSnackbar } from "../../showSnackbar";

export const handleApiResponse = (response, dispatch, config) => {
  let noop = () => {};
  let conf = {
    error_prefix: config.error_prefix || "",
    success_message: config.success_message || "",
    on_success: config.on_success || noop,
    on_error: config.on_error || noop,
  };
  if (response.data.hasOwnProperty("validations")) {
    // push each validation error as snackbar
    response.data.validations.forEach((elem) => {
      showSnackbar(
        {
          text: `${conf.error_prefix ? conf.error_prefix + ": " : ""}${
            elem.title
          }`,
          type: "error",
        },
        dispatch
      );
    });
    conf.on_error(response);
    return false;
  } else if (response.data.hasOwnProperty("errors")) {
    // push each error as snackbar
    showSnackbar(
      {
        text: `${conf.error_prefix ? conf.error_prefix + ": " : ""}${
          response.data.errors.title
        }`,
        type: "error",
      },
      dispatch
    );
    conf.on_error(response);
    return false;
  } else if (response.data.hasOwnProperty("data")) {
    // On success
    if (conf.success_message) {
      dispatch({
        type: "SHOW_SNACKBAR",
        payload: { text: conf.success_message, type: "success" },
      });
    }
    conf.on_success(response);
    return true;
  } else {
    dispatch({
      type: "SHOW_SNACKBAR",
      payload: { text: "Wystąpił niespodziewany błąd", type: "error" },
    });
  }
};

export const genericErrorToSnackbar = (error, dispatch) => {
  showSnackbar(
    {
      text: error.message,
      type: "error",
    },
    dispatch
  );
};
