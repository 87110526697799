import QuestionRoot from "../QuestionRoot";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import {
  CircularProgress,
  TextField,
  FormControlLabel,
  Switch,
  IconButton,
  Autocomplete,
} from "@mui/material";
import { useEffect } from "react";
import { getMachineProducers } from "../../../clients/machine-producers";
import { postInspectionResults } from "../../../clients/inspection-results";
import FlexBox from "../../FlexBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PledgeDialog from "../PledgeDialog";
import { getMachineModelsBelongingToProducer } from "../../../clients/machine-models";
import { getMachineModel } from "../../../clients/machine-models";

const QuestionConfirmDetails = ({
  inspection_id,
  handle_next,
  result_data,
  product_id,
}) => {
  const disabled = result_data !== undefined && result_data !== null;

  const [isLoading, setIsLoading] = useState(false);
  const [machineProducers, setMachineProducers] = useState([]);
  const [machineProducer, setMachineProducer] = useState();
  const [models, setModels] = useState([]);
  const [pledgeDialogOpen, setPledgeDialogOpen] = useState(false);

  const formik = useFormik({
    // if data is already present - aka this step was already done
    // set initial values to show data
    initialValues: (result_data && {
      ...result_data,
      is_pawned: !result_data.is_pawned,
      is_stolen: !result_data.is_stolen,
      fk_machine_model: {
        label: "Ładowanie",
        id: result_data.fk_machine_model,
      },
    }) || {
      fk_inspection: inspection_id,
      fk_machine_model: null,
      vin: "",
      registration_number: "",
      first_registration_date: "",
      is_pawned: false,
      is_stolen: false,
      is_confirmed: false,
    },
    onSubmit: (values) => {
      let new_values = {
        ...values,
        is_pawned: !values.is_pawned,
        is_stolen: !values.is_stolen,
        fk_machine_model: values.fk_machine_model.id,
      };
      // if disabled - this step was already done navigate to next
      if (disabled) {
        handle_next();
        return;
      }
      setIsLoading(true);
      postInspectionResults(new_values).then((response) => {
        setIsLoading(false);
        handle_next(true);
      });
    },
    validationSchema: validationSchema,
  });

  useEffect(() => {
    getMachineProducers()
      .then((response) => {
        setMachineProducers(response);
      })
      .catch((err) => {
        console.warn(err);
      });
    // if disabled set producer and model
    if (disabled) {
      getMachineModel(formik.initialValues.fk_machine_model.id).then(
        (response) => {
          const model = response.data.data;
          const producer = model.relationships.producer;
          setMachineProducer({
            label: producer.attributes.name,
            id: producer.id,
          });
          formik.setFieldValue("fk_machine_model", {
            label: model.attributes.name,
            id: model.id,
          });
        }
      );
    }
  }, []);

  useEffect(() => {
    if (machineProducer !== undefined && machineProducer !== null) {
      makeModelOptions(machineProducer);
    } else {
      setModels([]);
    }
  }, [machineProducer]);

  // Creates array of model menu items
  const makeModelOptions = (machineProducer) => {
    if (machineProducer !== undefined && machineProducer !== null) {
      getMachineModelsBelongingToProducer(machineProducer.id).then(
        (response) => {
          let data = [];
          response.data.forEach((model) => {
            data = [
              ...data,
              {
                label: model.attributes.name,
                id: model.id,
              },
            ];
          });
          setModels(data);
        }
      );
    }
  };

  const handlePledgeDialogClose = () => {
    setPledgeDialogOpen(false);
  };

  if (!machineProducers) {
    return <CircularProgress />;
  }

  return (
    <QuestionRoot
      question="Potwierdzenie danych maszyny"
      handle_next={formik.handleSubmit}
      isLoading={isLoading}
    >
      <Autocomplete
        name="producer"
        value={machineProducer || null}
        noOptionsText="Brak wyszukań"
        disabled={disabled || isLoading}
        onChange={(e, newValue) => {
          setMachineProducer(newValue || null);
          formik.setFieldValue("fk_machine_model", null);
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        options={
          machineProducers?.data?.map((producer) => ({
            label: producer.attributes.name,
            id: producer.id,
          })) || []
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Producent"
            error={
              !!formik.touched.fk_machine_model &&
              !!formik.errors.fk_machine_model
            }
          />
        )}
      />
      <Autocomplete
        name="fk_machine_model"
        value={formik.values.fk_machine_model || null}
        noOptionsText="Brak wyszukań"
        disabled={!models.length || isLoading || disabled}
        onChange={(e, new_value) => {
          formik.setFieldValue("fk_machine_model", new_value);
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        options={models}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Model"
            onBlur={formik.handleBlur}
            error={
              !!formik.touched.fk_machine_model &&
              !!formik.errors.fk_machine_model
            }
          />
        )}
      />
      <TextField
        name="vin"
        onChange={formik.handleChange}
        label="VIN"
        value={formik.values.vin || ""}
        onBlur={formik.handleBlur}
        variant="outlined"
        disabled={disabled || isLoading}
        size="small"
        error={!!formik.touched.vin && !!formik.errors.vin}
        helperText={formik.touched.vin && formik.errors.vin}
      />
      <TextField
        name="registration_number"
        onChange={formik.handleChange}
        label="Numer Rejestracyjny"
        value={formik.values.registration_number || ""}
        onBlur={formik.handleBlur}
        variant="outlined"
        disabled={disabled || isLoading}
        size="small"
        error={
          !!formik.touched.registration_number &&
          !!formik.errors.registration_number
        }
        helperText={
          formik.touched.registration_number &&
          formik.errors.registration_number
        }
      />
      <TextField
        name="first_registration_date"
        label="Data Pierwszej rejestracji"
        onChange={formik.handleChange}
        onBlur={(e) => {
          e.target.type = "text";
          formik.handleBlur(e);
        }}
        onFocus={(e) => {
          e.target.type = "date";
        }}
        disabled={disabled || isLoading}
        value={formik.values.first_registration_date || ""}
        error={
          !!formik.touched.first_registration_date &&
          !!formik.errors.first_registration_date
        }
        helperText={
          formik.touched.first_registration_date &&
          formik.errors.first_registration_date
        }
        variant="outlined"
        size="small"
        placeholder=""
      />
      <FlexBox>
        <FormControlLabel
          disabled={disabled || isLoading}
          control={
            <Switch
              name="is_pawned"
              value={formik.values.is_pawned}
              checked={!!formik.values.is_pawned}
              onChange={formik.handleChange}
            />
          }
          label="Pojazd nie jest zadłużony"
        />
        <IconButton
          onClick={() => {
            setPledgeDialogOpen(true);
          }}
        >
          <HelpOutlineIcon />
        </IconButton>
      </FlexBox>
      <FormControlLabel
        disabled={disabled || isLoading}
        control={
          <Switch
            name="is_stolen"
            value={formik.values.is_stolen}
            checked={!!formik.values.is_stolen}
            onChange={formik.handleChange}
          />
        }
        label="Pojazd nie jest kradziony"
      />
      <FormControlLabel
        disabled={disabled || isLoading}
        control={
          <Switch
            name="is_confirmed"
            value={formik.values.is_confirmed}
            checked={!!formik.values.is_confirmed}
            onChange={formik.handleChange}
          />
        }
        label="Potwierdzam dane pojazdu"
      />
      <PledgeDialog
        open={pledgeDialogOpen}
        on_close={handlePledgeDialogClose}
        product_id={product_id}
      />
    </QuestionRoot>
  );
};
// make all fields required
const validationSchema = Yup.object().shape({
  fk_machine_model: Yup.object().required(),
  vin: Yup.string().required("Pole wymagane"),
  registration_number: Yup.string().required("Pole wymagane"),
  first_registration_date: Yup.string().required("Pole wymagane"),
});
export default QuestionConfirmDetails;
