import AppLayout from "../../components/layout/AppLayout";
import Navbar from "../../components/navbar/Navbar";
import { useAppContext } from "../../contexts/app/AppContext";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { getPageContentWithFallback } from "../../clients/cms";
import Logout from "../..//components/sessions/Logout";
import FlexBox from "../../components/FlexBox";
import CircularProgress from "@mui/material/CircularProgress";
import { genericErrorToSnackbar } from "../../utils/api/error-handling/apiErrorHandler.js";
import handleLogout from "../../utils/handleLogout";

const LogoutPage = () => {
  const { state, dispatch } = useAppContext();
  const { user } = state;

  useEffect(() => {
    handleLogout(dispatch, user);
  }, []);

  const [pagecontent, set_pagecontent] = useState();
  useEffect(() => {
    getPageContentWithFallback("logout")
      .then((pagecontent) => {
        set_pagecontent(pagecontent);
      })
      .catch((error) => {
        set_pagecontent(error);
        genericErrorToSnackbar(error, dispatch);
      });
  }, []);

  if (pagecontent != null && !(pagecontent instanceof Error)) {
    return (
      <AppLayout navbar={<Navbar />}>
        <FlexBox
          flexDirection="column"
          minHeight="60vh"
          alignItems="center"
          justifyContent="center"
        >
          <h2>{pagecontent.data.attributes.title}</h2>
          <Logout pagecontent={pagecontent} />
        </FlexBox>
      </AppLayout>
    );
  }

  return (
    <AppLayout navbar={<Navbar />}>
      <FlexBox
        flexDirection="column"
        minHeight="60vh"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </FlexBox>
    </AppLayout>
  );
};

export default LogoutPage;
