import Error404 from "./pages/404";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import LoadingWrapper from "./components/LoadingWrapper";
import IndexPage from "./pages";
import HelpPage from "./pages/help";
import LoginPage from "./pages/login";
import MobileCategoryNav from "./pages/mobile-category-nav";
import SignUpPage from "./pages/signup";
import WishList from "./pages/wish-list";
import AboutPages from "./pages/about/[page]";
import DownloadsPage from "./pages/about/downloads";
import AddressList from "./pages/address";
import AddressUpdater from "./pages/address/[id]";
import AddressUpdaterAdd from "./pages/address/add";
import LogoutPage from "./pages/auth/logout";
import WelcomePage, { types } from "./pages/auth/welcome";
import Shop from "./pages/catalog/index";
import ShopCategory from "./pages/catalog/[category]";
import EmailVerificationPage from "./pages/email-verification/[token]";
import InspectionsPage from "./pages/inspections";
import InspectionDetailsPage from "./pages/inspections/[id]";
import OrderInspectionPage from "./pages/inspections/order";
import InspectorInspectionsPage from "./pages/inspector-inspections";
import InspectorInspectionsHistory from "./pages/inspector-inspections/history";
import IndexMachinesPage from "./pages/machines";
import AddMachinePage from "./pages/machines/add";
import MachineDetailsPage from "./pages/machines/[id]";
import MakeInspection from "./pages/make-inspection/[id]";
import OfferList from "./pages/offer";
import OrdersPage from "./pages/orders";
import OrderDetails from "./pages/orders/[id]";
import PaymentList from "./pages/payment";
import PaymentDetails from "./pages/payment/[id]";
import ProductDetailsPage from "./pages/product/[slug]";
import ProfileEditor from "./pages/profile/edit";
import ProfilePage from "./pages/profile";
import { useEffect } from "react";
import navigateService from "./utils/navigateService";
import dispatchService from "./utils/dispatchService";
import stateService from "./utils/stateService";
import { useAppContext } from "./contexts/app/AppContext";
import MachineSettingsPage from "./pages/machines/[id]/settings";
import ForgotPasswordPage from "./pages/forgot-password";
import ResetPasswordPage from "./pages/reset-password";

const Router = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useAppContext();

  useEffect(() => {
    navigateService.location = location;
    navigateService.navigate = navigate;
    dispatchService.dispatch = dispatch;
    stateService.state = state;
  }, [navigate, location, state, dispatch]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);

  return <Routes>{makeRoutes(routes)}</Routes>;
};

const makeRoutes = (routes_list = []) => {
  return routes_list.map((route, idx) => (
    // todo fix loading wrapper children
    <Route key={idx.toString()} path={route.path} element={route.element}>
      {makeRoutes(route.children)}
    </Route>
  ));
};

const routes = [
  {
    path: "/",
    element: <IndexPage />,
    children: [],
  },
  {
    path: "/help",
    element: <HelpPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/mobile-category-nav",
    element: <MobileCategoryNav />,
  },
  {
    path: "/signup",
    element: <SignUpPage />,
  },
  {
    path: "/wish-list",
    element: <WishList />,
  },
  {
    path: "/about",
    children: [
      {
        path: ":page",
        element: <AboutPages />,
      },
      {
        path: "downloads",
        element: <DownloadsPage />,
      },
    ],
  },
  {
    path: "/address",
    children: [
      {
        path: "",
        element: <AddressList />,
      },
      {
        path: ":id",
        element: <AddressUpdater />,
      },
      {
        path: "add",
        element: <AddressUpdaterAdd />,
      },
    ],
  },
  {
    path: "/auth",
    children: [
      {
        path: "logout",
        element: <LogoutPage />,
      },
      {
        path: "welcome1",
        element: <WelcomePage type={types.step1} />,
      },
      {
        path: "welcome2",
        element: <WelcomePage type={types.step2} />,
      },
    ],
  },
  {
    path: "/catalog",
    children: [
      {
        path: "",
        element: <Shop />,
      },
      {
        path: ":category",
        element: <ShopCategory />,
      },
    ],
  },
  {
    path: "/email-verification",
    children: [
      {
        path: ":token",
        element: <EmailVerificationPage />,
      },
    ],
  },
  {
    path: "/inspections",
    children: [
      {
        path: "",
        element: <InspectionsPage />,
      },
      {
        path: ":id",
        element: <InspectionDetailsPage />,
      },
      {
        path: "order",
        element: <OrderInspectionPage />,
      },
    ],
  },
  {
    path: "/inspector-inspections",
    children: [
      {
        path: "",
        element: <InspectorInspectionsPage />,
      },
      {
        path: "history",
        element: <InspectorInspectionsHistory />,
      },
    ],
  },
  {
    path: "/machines",
    children: [
      {
        path: "",
        element: <IndexMachinesPage />,
      },
      {
        path: "add",
        element: <AddMachinePage />,
      },
      {
        path: ":id",
        element: <MachineDetailsPage />,
      },
      {
        path: ":id/settings",
        element: <MachineSettingsPage />,
      },
    ],
  },
  {
    path: "/make-inspection",
    children: [
      {
        path: ":id",
        element: <MakeInspection />,
      },
    ],
  },
  {
    path: "/offer",
    element: <OfferList />,
  },
  {
    path: "/orders",
    children: [
      {
        path: "",
        element: <OrdersPage />,
      },
      {
        path: ":id",
        element: <OrderDetails />,
      },
    ],
  },
  {
    path: "/payment",
    children: [
      {
        path: "",
        element: <PaymentList />,
      },
      {
        path: ":id",
        element: <PaymentDetails />,
      },
    ],
  },
  {
    path: "/product",
    children: [
      {
        path: ":slug",
        element: <ProductDetailsPage />,
      },
    ],
  },
  {
    path: "/profile",
    children: [
      {
        path: "",
        element: <ProfilePage />,
      },
      {
        path: "edit",
        element: <ProfileEditor />,
      },
    ],
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/reset-password",
    element: <ResetPasswordPage />,
  },
  { path: "*", element: <Error404 /> },
];
export default Router;
