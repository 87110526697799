import {
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import FlexBox from "../FlexBox";
import { layoutConstant } from "../../utils/constants";
import apiClient from "../../clients/api-client";
import { useState, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { toBase64 } from "../../utils/utils";
import CircularProgress from "@mui/material/CircularProgress";
import update from "immutability-helper";
import ImageList from "./DndImageList";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  marginTop: "10px",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export const PhotoEditorDialog = (props) => {
  const { images, product_id, onClose, open } = props;

  const [images_local, setImages] = useState(images);
  const [isLoading, setIsLoading] = useState(false);

  // on close pass changed images array to parent component
  const handleClose = () => {
    onClose(images_local);
  };

  // Dropzone def, very similar to AddMachine.jsx
  function Dropzone(props) {
    const {
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      accept: {
        "image/*": [".jpeg", ".jpg", ".png"],
      },

      onDrop: async (acceptedFiles) => {
        // on new send images to server
        setIsLoading(true);
        let new_images = [];
        for (const file of acceptedFiles) {
          let imagedata = await toBase64(file);
          let data = {
            fk_product: product_id,
            image_order: images_local.length + new_images.length + 1,
            base64_image: imagedata,
          };
          let response = await apiClient.post(
            "/product-images",
            JSON.stringify(data)
          );
          new_images.push({
            s: imagedata,
            id: response.data.data.id,
          });
        }
        setImages([...images_local, ...new_images]);
        setIsLoading(false);
      },
    });

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject]
    );

    return (
      <section className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>Kliknij lub przeciągnij tutaj zdjęcia</p>
        </div>
      </section>
    );
  }

  // on image dragged update
  const moveImage = (dragIndex, hoverIndex) => {
    // Get the dragged element
    const draggedImage = images_local[dragIndex];
    /*
      - copy the dragged image before hovered element (i.e., [hoverIndex, 0, draggedImage])
      - remove the previous reference of dragged element (i.e., [dragIndex, 1])
      - here we are using this update helper method from immutability-helper package
    */
    let new_images_order = update(images_local, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, draggedImage],
      ],
    });
    setImages(new_images_order);
  };

  const onMoveImageEnd = (new_images_order) => {
    let data = {
      sorting: [],
    };
    new_images_order.forEach((image, index) => {
      data.sorting = [
        ...data.sorting,
        {
          id_image: image.id,
          image_order: index + 1,
        },
      ];
    });
    apiClient.patch("/product-images", JSON.stringify(data));
  };

  return (
    <Dialog
      maxWidth="lg"
      sx={{
        top: layoutConstant.topbarHeight + 50,
        zIndex: "5000",
      }}
      onClose={handleClose}
      open={open}
      fullWidth
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <ImageIcon color="primary" />
        Edycja Zdjęć
      </DialogTitle>
      <Box>
        <Box width="80%" mx="auto">
          <Divider />
        </Box>

        <FlexBox justifyContent="center" mt={-1.625}>
          <Box color="grey.600" bgcolor="background.paper" px={2}>
            Przeciągaj zdjęcia aby ustalić ich kolejność
          </Box>
        </FlexBox>
      </Box>
      <FlexBox
        sx={{
          padding: "20px",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        <ImageList
          images={images_local}
          moveImage={moveImage}
          setImages={setImages}
          onDragEnd={onMoveImageEnd}
        />
      </FlexBox>
      <Box>
        <Box width="80%" mx="auto">
          <Divider />
        </Box>

        <FlexBox justifyContent="center" mt={-1.625}>
          <Box color="grey.600" bgcolor="background.paper" px={2}>
            Dodawanie zdjęć
          </Box>
        </FlexBox>
      </Box>
      <Box p={2}>
        <Dropzone disabled={isLoading} />
      </Box>
      <DialogActions>
        <Box sx={{ display: isLoading ? "flex" : "none", padding: "5px" }}>
          <CircularProgress color="primary" />
        </Box>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          Zamknij
        </Button>
      </DialogActions>
    </Dialog>
  );
};
