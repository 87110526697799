import { Button, Card, Dialog, Typography } from "@mui/material";
import FlexBox from "../FlexBox";
import { deleteProduct } from "../../clients/products-management";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const DeleteDialog = ({ open, on_close, product_id }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    on_close();
  };

  return (
    <Dialog sx={{ zIndex: 2500 }} open={open} onClose={handleClose}>
      <Card
        sx={{
          padding: "10px",
        }}
      >
        <Typography variant="h6">Czy napewno chcesz usunąć ofertę?</Typography>
        <FlexBox sx={{ gap: "10px", marginTop: "10px" }}>
          <Button
            color="primary"
            variant="outlined"
            disabled={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await deleteProduct(product_id);
              setIsLoading(false);
              on_close();
              navigate("/machines");
            }}
            sx={{ flex: 1 }}
          >
            Tak
          </Button>
          <Button
            color="primary"
            variant="outlined"
            disabled={isLoading}
            onClick={() => {
              handleClose();
            }}
            sx={{ flex: 1 }}
          >
            Nie
          </Button>
        </FlexBox>
      </Card>
    </Dialog>
  );
};
export default DeleteDialog;
