export const countriesList = [
  { label: "Afganistan", name_en: "Afghanistan", code: "AF" },
  { label: "Albania", name_en: "Albania", code: "AL" },
  { label: "Algieria", name_en: "Algeria", code: "DZ" },
  { label: "Andora", name_en: "Andorra", code: "AD" },
  { label: "Angola", name_en: "Angola", code: "AO" },
  { label: "Anguilla", name_en: "Anguilla", code: "AI" },
  { label: "Antarktyka", name_en: "Antarctica", code: "AQ" },
  { label: "Antigua i Barbuda", name_en: "Antigua and Barbuda", code: "AG" },
  { label: "Arabia Saudyjska", name_en: "Saudi Arabia", code: "SA" },
  { label: "Argentyna", name_en: "Argentina", code: "AR" },
  { label: "Armenia", name_en: "Armenia", code: "AM" },
  { label: "Aruba", name_en: "Aruba", code: "AW" },
  { label: "Australia", name_en: "Australia", code: "AU" },
  { label: "Austria", name_en: "Austria", code: "AT" },
  { label: "Azerbejdżan", name_en: "Azerbaijan", code: "AZ" },
  { label: "Bahamy", name_en: "Bahamas", code: "BS" },
  { label: "Bahrajn", name_en: "Bahrain", code: "BH" },
  { label: "Bangladesz", name_en: "Bangladesh", code: "BD" },
  { label: "Barbados", name_en: "Barbados", code: "BB" },
  { label: "Belgia", name_en: "Belgium", code: "BE" },
  { label: "Belize", name_en: "Belize", code: "BZ" },
  { label: "Benin", name_en: "Benin", code: "BJ" },
  { label: "Bermudy", name_en: "Bermuda", code: "BM" },
  { label: "Bhutan", name_en: "Bhutan", code: "BT" },
  { label: "Białoruś", name_en: "Belarus", code: "BY" },
  { label: "Boliwia", name_en: "Bolivia, Plurinational State of", code: "BO" },
  {
    label: "Bonaire, Sint Eustatius i Saba",
    name_en: "Bonaire, Sint Eustatius and Saba",
    code: "BQ",
  },
  {
    label: "Bośnia i Hercegowina",
    name_en: "Bosnia and Herzegovina",
    code: "BA",
  },
  { label: "Botswana", name_en: "Botswana", code: "BW" },
  { label: "Brazylia", name_en: "Brazil", code: "BR" },
  { label: "Brunei", name_en: "Brunei Darussalam", code: "BN" },
  {
    label: "Brytyjskie Terytorium Oceanu Indyjskiego",
    name_en: "British Indian Ocean Territory",
    code: "IO",
  },
  {
    label: "Brytyjskie Wyspy Dziewicze",
    name_en: "Virgin Islands, British",
    code: "VG",
  },
  { label: "Bułgaria", name_en: "Bulgaria", code: "BG" },
  { label: "Burkina Faso", name_en: "Burkina Faso", code: "BF" },
  { label: "Burundi", name_en: "Burundi", code: "BI" },
  { label: "Chile", name_en: "Chile", code: "CL" },
  { label: "Chiny", name_en: "China", code: "CN" },
  { label: "Chorwacja", name_en: "Croatia", code: "HR" },
  { label: "Curaçao", name_en: "Curaçao", code: "CW" },
  { label: "Cypr", name_en: "Cyprus", code: "CY" },
  { label: "Czad", name_en: "Chad", code: "TD" },
  { label: "Czarnogóra", name_en: "Montenegro", code: "ME" },
  { label: "Czechy", name_en: "Czech Republic", code: "CZ" },
  {
    label: "Dalekie Wyspy Mniejsze Stanów Zjednoczonych",
    name_en: "United States Minor Outlying Islands",
    code: "UM",
  },
  { label: "Dania", name_en: "Denmark", code: "DK" },
  {
    label: "Demokratyczna Republika Konga",
    name_en: "Congo, the Democratic Republic of the",
    code: "CD",
  },
  { label: "Dominika", name_en: "Dominica", code: "DM" },
  { label: "Dominikana", name_en: "Dominican Republic", code: "DO" },
  { label: "Dżibuti", name_en: "Djibouti", code: "DJ" },
  { label: "Egipt", name_en: "Egypt", code: "EG" },
  { label: "Ekwador", name_en: "Ecuador", code: "EC" },
  { label: "Erytrea", name_en: "Eritrea", code: "ER" },
  { label: "Estonia", name_en: "Estonia", code: "EE" },
  { label: "Etiopia", name_en: "Ethiopia", code: "ET" },
  { label: "Falklandy", name_en: "Falkland Islands (Malvinas)", code: "FK" },
  { label: "Fidżi", name_en: "Fiji", code: "FJ" },
  { label: "Filipiny", name_en: "Philippines", code: "PH" },
  { label: "Finlandia", name_en: "Finland", code: "FI" },
  { label: "Francja", name_en: "France", code: "FR" },
  {
    label: "Francuskie Terytoria Południowe i Antarktyczne",
    name_en: "French Southern Territories",
    code: "TF",
  },
  { label: "Gabon", name_en: "Gabon", code: "GA" },
  { label: "Gambia", name_en: "Gambia", code: "GM" },
  {
    label: "Georgia Południowa i Sandwich Południowy",
    name_en: "South Georgia and the South Sandwich Islands",
    code: "GS",
  },
  { label: "Ghana", name_en: "Ghana", code: "GH" },
  { label: "Gibraltar", name_en: "Gibraltar", code: "GI" },
  { label: "Grecja", name_en: "Greece", code: "GR" },
  { label: "Grenada", name_en: "Grenada", code: "GD" },
  { label: "Grenlandia", name_en: "Greenland", code: "GL" },
  { label: "Gruzja", name_en: "Georgia", code: "GE" },
  { label: "Guam", name_en: "Guam", code: "GU" },
  { label: "Guernsey", name_en: "Guernsey", code: "GG" },
  { label: "Gujana Francuska", name_en: "French Guiana", code: "GF" },
  { label: "Gujana", name_en: "Guyana", code: "GY" },
  { label: "Gwadelupa", name_en: "Guadeloupe", code: "GP" },
  { label: "Gwatemala", name_en: "Guatemala", code: "GT" },
  { label: "Gwinea Bissau", name_en: "Guinea-Bissau", code: "GW" },
  { label: "Gwinea Równikowa", name_en: "Equatorial Guinea", code: "GQ" },
  { label: "Gwinea", name_en: "Guinea", code: "GN" },
  { label: "Haiti", name_en: "Haiti", code: "HT" },
  { label: "Hiszpania", name_en: "Spain", code: "ES" },
  { label: "Holandia", name_en: "Netherlands", code: "NL" },
  { label: "Honduras", name_en: "Honduras", code: "HN" },
  { label: "Hongkong", name_en: "Hong Kong", code: "HK" },
  { label: "Indie", name_en: "India", code: "IN" },
  { label: "Indonezja", name_en: "Indonesia", code: "ID" },
  { label: "Irak", name_en: "Iraq", code: "IQ" },
  { label: "Iran", name_en: "Iran, Islamic Republic of", code: "IR" },
  { label: "Irlandia", name_en: "Ireland", code: "IE" },
  { label: "Islandia", name_en: "Iceland", code: "IS" },
  { label: "Izrael", name_en: "Israel", code: "IL" },
  { label: "Jamajka", name_en: "Jamaica", code: "JM" },
  { label: "Japonia", name_en: "Japan", code: "JP" },
  { label: "Jemen", name_en: "Yemen", code: "YE" },
  { label: "Jersey", name_en: "Jersey", code: "JE" },
  { label: "Jordania", name_en: "Jordan", code: "JO" },
  { label: "Kajmany", name_en: "Cayman Islands", code: "KY" },
  { label: "Kambodża", name_en: "Cambodia", code: "KH" },
  { label: "Kamerun", name_en: "Cameroon", code: "CM" },
  { label: "Kanada", name_en: "Canada", code: "CA" },
  { label: "Katar", name_en: "Qatar", code: "QA" },
  { label: "Kazachstan", name_en: "Kazakhstan", code: "KZ" },
  { label: "Kenia", name_en: "Kenya", code: "KE" },
  { label: "Kirgistan", name_en: "Kyrgyzstan", code: "KG" },
  { label: "Kiribati", name_en: "Kiribati", code: "KI" },
  { label: "Kolumbia", name_en: "Colombia", code: "CO" },
  { label: "Komory", name_en: "Comoros", code: "KM" },
  { label: "Kongo", name_en: "Congo", code: "CG" },
  { label: "Korea Południowa", name_en: "Korea, Republic of", code: "KR" },
  {
    label: "Korea Północna",
    name_en: "Korea, Democratic People's Republic of",
    code: "KP",
  },
  { label: "Kostaryka", name_en: "Costa Rica", code: "CR" },
  { label: "Kuba", name_en: "Cuba", code: "CU" },
  { label: "Kuwejt", name_en: "Kuwait", code: "KW" },
  { label: "Laos", name_en: "Lao People's Democratic Republic", code: "LA" },
  { label: "Lesotho", name_en: "Lesotho", code: "LS" },
  { label: "Liban", name_en: "Lebanon", code: "LB" },
  { label: "Liberia", name_en: "Liberia", code: "LR" },
  { label: "Libia", name_en: "Libyan Arab Jamahiriya", code: "LY" },
  { label: "Liechtenstein", name_en: "Liechtenstein", code: "LI" },
  { label: "Litwa", name_en: "Lithuania", code: "LT" },
  { label: "Luksemburg", name_en: "Luxembourg", code: "LU" },
  { label: "Łotwa", name_en: "Latvia", code: "LV" },
  {
    label: "Macedonia",
    name_en: "Macedonia, the former Yugoslav Republic of",
    code: "MK",
  },
  { label: "Madagaskar", name_en: "Madagascar", code: "MG" },
  { label: "Majotta", name_en: "Mayotte", code: "YT" },
  { label: "Makau", name_en: "Macao", code: "MO" },
  { label: "Malawi", name_en: "Malawi", code: "MW" },
  { label: "Malediwy", name_en: "Maldives", code: "MV" },
  { label: "Malezja", name_en: "Malaysia", code: "MY" },
  { label: "Mali", name_en: "Mali", code: "ML" },
  { label: "Malta", name_en: "Malta", code: "MT" },
  {
    label: "Mariany Północne",
    name_en: "Northern Mariana Islands",
    code: "MP",
  },
  { label: "Maroko", name_en: "Morocco", code: "MA" },
  { label: "Martynika", name_en: "Martinique", code: "MQ" },
  { label: "Mauretania", name_en: "Mauritania", code: "MR" },
  { label: "Mauritius", name_en: "Mauritius", code: "MU" },
  { label: "Meksyk", name_en: "Mexico", code: "MX" },
  {
    label: "Mikronezja",
    name_en: "Micronesia, Federated States of",
    code: "FM",
  },
  { label: "Mjanma", name_en: "Myanmar", code: "MM" },
  { label: "Mołdawia", name_en: "Moldova, Republic of", code: "MD" },
  { label: "Monako", name_en: "Monaco", code: "MC" },
  { label: "Mongolia", name_en: "Mongolia", code: "MN" },
  { label: "Montserrat", name_en: "Montserrat", code: "MS" },
  { label: "Mozambik", name_en: "Mozambique", code: "MZ" },
  { label: "Namibia", name_en: "Namibia", code: "NA" },
  { label: "Nauru", name_en: "Nauru", code: "NR" },
  { label: "Nepal", name_en: "Nepal", code: "NP" },
  { label: "Niemcy", name_en: "Germany", code: "DE" },
  { label: "Niger", name_en: "Niger", code: "NE" },
  { label: "Nigeria", name_en: "Nigeria", code: "NG" },
  { label: "Nikaragua", name_en: "Nicaragua", code: "NI" },
  { label: "Niue", name_en: "Niue", code: "NU" },
  { label: "Norfolk", name_en: "Norfolk Island", code: "NF" },
  { label: "Norwegia", name_en: "Norway", code: "NO" },
  { label: "Nowa Kaledonia", name_en: "New Caledonia", code: "NC" },
  { label: "Nowa Zelandia", name_en: "New Zealand", code: "NZ" },
  { label: "Oman", name_en: "Oman", code: "OM" },
  { label: "Pakistan", name_en: "Pakistan", code: "PK" },
  { label: "Palau", name_en: "Palau", code: "PW" },
  {
    label: "Palestyna",
    name_en: "Palestinian Territory, Occupied",
    code: "PS",
  },
  { label: "Panama", name_en: "Panama", code: "PA" },
  { label: "Papua-Nowa Gwinea", name_en: "Papua New Guinea", code: "PG" },
  { label: "Paragwaj", name_en: "Paraguay", code: "PY" },
  { label: "Peru", name_en: "Peru", code: "PE" },
  { label: "Pitcairn", name_en: "Pitcairn", code: "PN" },
  { label: "Polinezja Francuska", name_en: "French Polynesia", code: "PF" },
  { label: "Polska", name_en: "Poland", code: "PL" },
  { label: "Portoryko", name_en: "Puerto Rico", code: "PR" },
  { label: "Portugalia", name_en: "Portugal", code: "PT" },
  {
    label: "Republika Południowej Afryki",
    name_en: "South Africa",
    code: "ZA",
  },
  {
    label: "Republika Środkowoafrykańska",
    name_en: "Central African Republic",
    code: "CF",
  },
  { label: "Republika Zielonego Przylądka", name_en: "Cape Verde", code: "CV" },
  { label: "Reunion", name_en: "Réunion", code: "RE" },
  { label: "Rosja", name_en: "Russian Federation", code: "RU" },
  { label: "Rumunia", name_en: "Romania", code: "RO" },
  { label: "Rwanda", name_en: "Rwanda", code: "RW" },
  { label: "Sahara Zachodnia", name_en: "Western Sahara", code: "EH" },
  {
    label: "Saint Kitts i Nevis",
    name_en: "Saint Kitts and Nevis",
    code: "KN",
  },
  { label: "Saint Lucia", name_en: "Saint Lucia", code: "LC" },
  {
    label: "Saint Vincent i Grenadyny",
    name_en: "Saint Vincent and the Grenadines",
    code: "VC",
  },
  { label: "Saint-Barthélemy", name_en: "Saint Barthélemy", code: "BL" },
  { label: "Saint-Martin", name_en: "Saint Martin (French part)", code: "MF" },
  {
    label: "Saint-Pierre i Miquelon",
    name_en: "Saint Pierre and Miquelon",
    code: "PM",
  },
  { label: "Salwador", name_en: "El Salvador", code: "SV" },
  { label: "Samoa Amerykańskie", name_en: "American Samoa", code: "AS" },
  { label: "Samoa", name_en: "Samoa", code: "WS" },
  { label: "San Marino", name_en: "San Marino", code: "SM" },
  { label: "Senegal", name_en: "Senegal", code: "SN" },
  { label: "Serbia", name_en: "Serbia", code: "RS" },
  { label: "Seszele", name_en: "Seychelles", code: "SC" },
  { label: "Sierra Leone", name_en: "Sierra Leone", code: "SL" },
  { label: "Singapur", name_en: "Singapore", code: "SG" },
  { label: "Sint Maarten", name_en: "Sint Maarten (Dutch part)", code: "SX" },
  { label: "Słowacja", name_en: "Slovakia", code: "SK" },
  { label: "Słowenia", name_en: "Slovenia", code: "SI" },
  { label: "Somalia", name_en: "Somalia", code: "SO" },
  { label: "Sri Lanka", name_en: "Sri Lanka", code: "LK" },
  { label: "Stany Zjednoczone", name_en: "United States", code: "US" },
  { label: "Suazi", name_en: "Swaziland", code: "SZ" },
  { label: "Sudan", name_en: "Sudan", code: "SD" },
  { label: "Sudan Południowy", name_en: "South Sudan", code: "SS" },
  { label: "Surinam", name_en: "Suriname", code: "SR" },
  {
    label: "Svalbard i Jan Mayen",
    name_en: "Svalbard and Jan Mayen",
    code: "SJ",
  },
  { label: "Syria", name_en: "Syrian Arab Republic", code: "SY" },
  { label: "Szwajcaria", name_en: "Switzerland", code: "CH" },
  { label: "Szwecja", name_en: "Sweden", code: "SE" },
  { label: "Tadżykistan", name_en: "Tajikistan", code: "TJ" },
  { label: "Tajlandia", name_en: "Thailand", code: "TH" },
  { label: "Tajwan", name_en: "Taiwan, Province of China", code: "TW" },
  { label: "Tanzania", name_en: "Tanzania, United Republic of", code: "TZ" },
  { label: "Timor Wschodni", name_en: "Timor-Leste", code: "TL" },
  { label: "Togo", name_en: "Togo", code: "TG" },
  { label: "Tokelau", name_en: "Tokelau", code: "TK" },
  { label: "Tonga", name_en: "Tonga", code: "TO" },
  { label: "Trynidad i Tobago", name_en: "Trinidad and Tobago", code: "TT" },
  { label: "Tunezja", name_en: "Tunisia", code: "TN" },
  { label: "Turcja", name_en: "Turkey", code: "TR" },
  { label: "Turkmenistan", name_en: "Turkmenistan", code: "TM" },
  { label: "Turks i Caicos", name_en: "Turks and Caicos Islands", code: "TC" },
  { label: "Tuvalu", name_en: "Tuvalu", code: "TV" },
  { label: "Uganda", name_en: "Uganda", code: "UG" },
  { label: "Ukraina", name_en: "Ukraine", code: "UA" },
  { label: "Urugwaj", name_en: "Uruguay", code: "UY" },
  { label: "Uzbekistan", name_en: "Uzbekistan", code: "UZ" },
  { label: "Vanuatu", name_en: "Vanuatu", code: "VU" },
  { label: "Wallis i Futuna", name_en: "Wallis and Futuna", code: "WF" },
  { label: "Watykan", name_en: "Holy See (Vatican City State)", code: "VA" },
  {
    label: "Wenezuela",
    name_en: "Venezuela, Bolivarian Republic of",
    code: "VE",
  },
  { label: "Węgry", name_en: "Hungary", code: "HU" },
  { label: "Wielka Brytania", name_en: "United Kingdom", code: "GB" },
  { label: "Wietnam", name_en: "Viet Nam", code: "VN" },
  { label: "Włochy", name_en: "Italy", code: "IT" },
  { label: "Wybrzeże Kości Słoniowej", name_en: "Côte d'Ivoire", code: "CI" },
  { label: "Wyspa Bouveta", name_en: "Bouvet Island", code: "BV" },
  { label: "Wyspa Bożego Narodzenia", name_en: "Christmas Island", code: "CX" },
  { label: "Wyspa Man", name_en: "Isle of Man", code: "IM" },
  {
    label: "Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha",
    name_en: "Saint Helena, Ascension and Tristan Cunha",
    code: "SH",
  },
  { label: "Wyspy Alandzkie", name_en: "Åland Islands", code: "AX" },
  { label: "Wyspy Cooka", name_en: "Cook Islands", code: "CK" },
  {
    label: "Wyspy Dziewicze Stanów Zjednoczonych",
    name_en: "Virgin Islands, U.S.",
    code: "VI",
  },
  {
    label: "Wyspy Heard i McDonalda",
    name_en: "Heard Island and McDonald Islands",
    code: "HM",
  },
  { label: "Wyspy Kokosowe", name_en: "Cocos (Keeling) Islands", code: "CC" },
  { label: "Wyspy Marshalla", name_en: "Marshall Islands", code: "MH" },
  { label: "Wyspy Owcze", name_en: "Faroe Islands", code: "FO" },
  { label: "Wyspy Salomona", name_en: "Solomon Islands", code: "SB" },
  {
    label: "Wyspy Świętego Tomasza i Książęca",
    name_en: "Sao Tome and Principe",
    code: "ST",
  },
  { label: "Zambia", name_en: "Zambia", code: "ZM" },
  { label: "Zimbabwe", name_en: "Zimbabwe", code: "ZW" },
  {
    label: "Zjednoczone Emiraty Arabskie",
    name_en: "United Arab Emirates",
    code: "AE",
  },
];
