import {
  Box,
  Card,
  Typography,
  Paper,
  TableCell,
  CircularProgress,
  Button,
  Tooltip,
  TableContainer,
  Table,
  TableBody,
  TableRow,
} from "@mui/material";
import FlexBox from "../FlexBox";
import Carousel from "../carousel/Carousel";
import { useState, useEffect } from "react";
import { getProduct } from "../../clients/products-management";
import { determineStatus } from "../../clients/products-management";
import { styled } from "@mui/system";
import ImageIcon from "@mui/icons-material/Image";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ConstructionIcon from "@mui/icons-material/Construction";
import { useAppContext } from "../../contexts/app/AppContext";
import { PhotoEditorDialog } from "./PhotoEditorDialog";
import { Link, useNavigate } from "react-router-dom";
import { genericErrorToSnackbar } from "../../utils/api/error-handling/apiErrorHandler.js";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import PricePrediction from "./PricePrediction";
import PublishDialog from "./PublishDialog";
import { createInspection } from "../../clients/inspections";
import { createSearchParams } from "react-router-dom";

const StyledCard = styled(Card)({
  flex: 1,
  padding: 0,
  minWidth: "300px",
});

const MachineDetails = (props) => {
  const [machineData, setMachinesData] = useState();
  const [photoEditorOpen, setPhotoEditorOpen] = useState(false);
  const [showPricePrediction, setShowPricePrediction] = useState(false);
  const [publishDialogOpen, setPublishDialogOpen] = useState(false);
  const { id, setMachineName } = props;
  const { dispatch } = useAppContext();
  const navigate = useNavigate();

  // Get Product by id from url param
  useEffect(() => {
    if (id !== undefined) {
      getProduct(id)
        .then((response) => {
          setMachinesData(response.data.data);
          setMachineName(
            `${response.data.data.relationships.model.relationships.producer.attributes.name} ${response.data.data.relationships.model.attributes.name}`
          );
        })
        .catch((err) => {
          genericErrorToSnackbar(err, dispatch);
        });
    }
  }, [id]);

  // Show loading screen before loaded
  if (!machineData) {
    return (
      <Card
        sx={{
          minHeight: "40vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Card>
    );
  }

  // On image editor dialog close refresh image slider with data from dialog
  const handlePhotoEditorClose = (images) => {
    setPhotoEditorOpen(false);
    setMachinesData({
      ...machineData,
      attributes: {
        ...machineData.attributes,
        images: images,
      },
    });
  };

  const handlePublishDialogClose = (data = undefined) => {
    setPublishDialogOpen(false);
    if (data) {
      setMachinesData((md) => ({
        ...md,
        attributes: data.attributes,
      }));
    }
  };

  return (
    <FlexBox gap="10px" flexDirection="column">
      <FlexBox
        sx={{
          minHeight: "40vh",
          gap: "10px",
          flexWrap: "wrap",
        }}
      >
        <StyledCard>
          <Paper
            elevation={3}
            sx={{
              padding: "10px",
              marginBottom: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ImageIcon color="primary" />
            <Typography fontWeight="bold" color="gray.500">
              Zdjęcia
            </Typography>
          </Paper>
          <Box
            sx={{
              padding: "20px",
            }}
          >
            <Carousel
              naturalSlideWidth={800}
              naturalSlideHeight={600}
              orientation="horizontal"
              visibleSlides={1}
              totalSlides={machineData?.attributes?.images?.length}
            >
              {machineData?.attributes?.images?.map((elem) => {
                return (
                  <Box key={elem.s}>
                    <img width="100%" src={elem.s} />
                  </Box>
                );
              })}
            </Carousel>
          </Box>
        </StyledCard>
        <StyledCard>
          <Paper
            elevation={3}
            sx={{
              padding: "10px",
              marginBottom: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ConstructionIcon color="primary" />
            <Typography fontWeight="bold" color="gray.500">
              Akcje
            </Typography>
          </Paper>
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setPhotoEditorOpen(true);
              }}
              fullWidth
            >
              Edycja Zdjęć
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                let response = await createInspection(machineData.id);
                navigate(`/make-inspection/${response.data.data.id}`);
              }}
              fullWidth
            >
              Zrób samodzielnie inspekcję podstawową
            </Button>
            <Link
              to={{
                pathname: "/inspections/order",
                search: `?${createSearchParams({
                  machine_slug: machineData.attributes.slug,
                })}`,
              }}
            >
              <Button variant="contained" color="primary" fullWidth>
                Zlecenie inspekcji rozszerzonej
              </Button>
            </Link>
            <Tooltip
              title={
                machineData?.attributes?.is_publish
                  ? "Ogłoszenie już jest opublikowane"
                  : ""
              }
            >
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setPublishDialogOpen(true);
                  }}
                  disabled={machineData?.attributes?.is_publish ? true : false}
                >
                  Publikuj
                </Button>
              </span>
            </Tooltip>
            <Link to="settings">
              <Button variant="contained" color="primary" fullWidth>
                Ustawienia Oferty
              </Button>
            </Link>
          </Box>
        </StyledCard>
      </FlexBox>
      <FlexBox
        sx={{
          minHeight: "40vh",
          gap: "10px",
          flexDirection: "column",
        }}
      >
        <StyledCard
          sx={{
            display: showPricePrediction ? "block" : "none",
          }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: "10px",
              marginBottom: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <LocalAtmOutlinedIcon color="primary" />
            <Typography fontWeight="bold" color="gray.500">
              Proponowana cena sprzedaży
            </Typography>
          </Paper>
          <PricePrediction
            machine_id={id}
            setVisibleCallback={setShowPricePrediction}
            current_price={machineData.attributes.price}
          />
        </StyledCard>

        <StyledCard>
          <Paper
            elevation={3}
            sx={{
              padding: "10px",
              marginBottom: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ReceiptLongIcon color="primary" />
            <Typography fontWeight="bold" color="gray.500">
              Dane
            </Typography>
          </Paper>
          <FlexBox>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {machine_wanted_attributes(machineData).map((elem) => (
                    <TableRow
                      key={elem[1]}
                      sx={{ borderTop: "2px solid", borderColor: "grey.300" }}
                    >
                      <TableCell
                        sx={{ fontWeight: "bold" }}
                      >{`${elem[1]}:`}</TableCell>
                      <TableCell align="right">{`${elem[0] ? elem[0] : ""} ${
                        elem[0] && elem[2] ? elem[2] : ""
                      }`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </FlexBox>
        </StyledCard>
      </FlexBox>
      <PhotoEditorDialog
        product_id={machineData.id}
        images={machineData.attributes.images}
        onClose={handlePhotoEditorClose}
        open={photoEditorOpen}
      />
      <PublishDialog
        open={publishDialogOpen}
        on_close={handlePublishDialogClose}
        product_id={machineData.id}
      />
    </FlexBox>
  );
};
export default MachineDetails;

// data used for formatting machine attributes in table [value, display_name, "suffix - optional"]
const machine_wanted_attributes = (machineData) => {
  return [
    [
      machineData.relationships.model.relationships.producer.attributes.name,
      "Producent",
    ],
    [machineData.relationships.model.attributes.name, "Model"],
    [
      machineData?.attributes?.price || machineData?.attributes?.seller_price,
      "Cena",
      "PLN",
    ],
    [machineData.attributes.suggested_price, "Cena sugerowana", "PLN"],
    [
      determineStatus(
        machineData.attributes.is_publish,
        machineData.attributes.status,
        machineData.attributes.is_blocked
      )[0],
      "Status",
    ],
    [machineData.attributes.vin, "VIN"],
    [machineData.attributes.machine_year, "Rok produkcji"],
    [machineData.attributes.machine_hour, "Przepracowane godziny", "H"],
    [machineData.attributes.machine_weight, "Waga", "KG"],
    [machineData.attributes.registration_number, "Numer rejestracyjny"],
    [
      machineData.attributes.first_registration_date
        ? new Date(machineData.attributes.first_registration_date)
            .toISOString()
            .split("T")[0]
        : null,
      "Data pierwszej rejestarcji",
    ],
  ];
};
