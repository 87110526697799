import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  Checkbox,
  Typography,
  FormControlLabel,
  Divider,
} from "@mui/material";
import { saveCustomerProfile } from "../../clients/customer-profile";
import Card1 from "../../components/Card1";
import FlexBox from "../../components/FlexBox";
import { useAppContext } from "../../contexts/app/AppContext";
import { useState } from "react";

const AgreementsEditor = ({ profileData, checkbox_pagecontent }) => {
  const [agreements, setAgreements] = useState(
    profileData.attributes.agreements
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { dispatch } = useAppContext();

  const handleSaveAgreements = () => {
    setIsSubmitting(true);
    saveCustomerProfile({ agreements: agreements })
      .then((response) => {
        dispatch({
          type: "SHOW_SNACKBAR",
          payload: { text: "Dane zostały zmienione", type: "success" },
        });

        setIsSubmitting(false);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  return (
    <Card1>
      <Typography variant="subtitle1" fontWeight={500}>
        Zgoda na otrzymywanie informacji handlowych
      </Typography>

      <Divider />
      {Object.entries(checkbox_pagecontent.attributes)
        .slice(1)
        .map(([key, val]) => (
          <FormControl fullWidth key={key} sx={{ mt: 2 }}>
            <FormControlLabel
              onChange={(e, state) => {
                if (state) {
                  setAgreements((agreements) => [...agreements, key]);
                } else {
                  setAgreements((agreements) =>
                    agreements.filter((elem) => elem != key)
                  );
                }
              }}
              value={key}
              control={
                <Checkbox
                  size="small"
                  color="secondary"
                  checked={agreements.includes(key)}
                />
              }
              label={
                <FlexBox
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Typography color="grey.700" variant="body2">
                    {val}
                  </Typography>
                </FlexBox>
              }
            />
          </FormControl>
        ))}
      <LoadingButton
        color="primary"
        variant="contained"
        sx={{ mt: 2 }}
        loading={isSubmitting}
        onClick={handleSaveAgreements}
      >
        Zapisz
      </LoadingButton>
    </Card1>
  );
};
export default AgreementsEditor;
