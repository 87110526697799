import apiClient from "./api-client";

export const getMachineModel = async (id) => {
  return apiClient.get(
    `machine-models/${id}?additional-fields[]=machine_model_specification`
  );
};

export const getMachineModelsBelongingToProducer = async (id) => {
  const response = await apiClient.get(
    `/machine-producers/${id}/machine-models`,
    {
      cache: {
        maxAge: 60 * 60 * 1000,
      },
    }
  );
  return response.data;
};
