import React, { useEffect, useState } from "react";
import CustomerDashboardNavigation from "../../components/layout/CustomerDashboardNavigation";
import DashboardLayout from "../../components/layout/CustomerDashboardLayout";
import DashboardPageHeader from "../../components/layout/DashboardPageHeader";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import {
  getProducts,
  determineStatus,
} from "../../clients/products-management";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { useAppContext } from "../../contexts/app/AppContext";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import { genericErrorToSnackbar } from "../../utils/api/error-handling/apiErrorHandler.js";

// Render status message using determineStatus
const renderStatusMessage = (row) => {
  const [message, color] = determineStatus(
    row.attributes.is_publish,
    row.attributes.status,
    row.attributes.is_blocked
  );
  return (
    <TableCell align="right">
      <Typography color={color}>{message}</Typography>
    </TableCell>
  );
};

const IndexMachinesPage = () => {
  const { dispatch } = useAppContext();

  // State variables for loading product list from api
  const [isLoading, setIsLoading] = useState(true);
  const [machines, setMachines] = useState(["placeholder"]);

  useEffect(() => {
    getProducts()
      .then((response) => {
        setMachines(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        genericErrorToSnackbar(err, dispatch);
      });
  }, []);

  return (
    <DashboardLayout>
      <DashboardPageHeader
        icon={AgricultureIcon}
        title="Lista dodanych maszyn"
        navigation={<CustomerDashboardNavigation />}
      />
      {/* if there are any machines render table view */}
      {machines.length != 0 ? (
        <TableContainer component={Card}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Producent</TableCell>
                <TableCell align="right">Model</TableCell>
                <TableCell align="right">Rocznik</TableCell>
                <TableCell align="right">Roboczogodziny</TableCell>
                <TableCell align="right">Cena</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Akcja</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Create table after data is fetched */}
              {!isLoading ? (
                machines?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      borderTop: "2px solid",
                      borderColor: "grey.300",
                    }}
                  >
                    <TableCell>
                      {
                        row.relationships.model.relationships.producer
                          .attributes.name
                      }
                    </TableCell>
                    <TableCell align="right">
                      {row.relationships.model.attributes.name}
                    </TableCell>
                    <TableCell align="right">
                      {row.attributes.machine_year}
                    </TableCell>
                    <TableCell align="right">
                      {`${row.attributes.machine_hour} h`}
                    </TableCell>
                    <TableCell align="right">
                      {`${
                        row.attributes.price || row.attributes.seller_price
                      } PLN`}
                    </TableCell>
                    {renderStatusMessage(row)}
                    <TableCell align="right">
                      <Link to={`/machines/${row.id}`}>
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                // Before data is fetched show skeleton
                <TableRow>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell align="right">
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell align="right">
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell align="right">
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell align="right">
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell align="right">
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell align="right">
                    <Skeleton variant="text" />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        // If there are no machines show card
        <Card
          sx={{
            padding: "10px",
          }}
        >
          <h2>Nie masz jeszce żadnej dodanej maszyny</h2>
          <Link to="/machines/add">
            <Button variant="outlined" color="primary">
              Dodaj Maszynę
            </Button>
          </Link>
        </Card>
      )}
    </DashboardLayout>
  );
};
export default IndexMachinesPage;
