import BazarImage from "../components/BazarImage";
import FlexBox from "../components/FlexBox";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import React from "react";

const Error404 = () => {
  const navigate = useNavigate();

  const handleGoBack = async () => {
    navigate(-1);
  };

  return (
    <FlexBox
      flexDirection="column"
      minHeight="100vh"
      justifyContent="center"
      alignItems="center"
      px={2}
    >
      <BazarImage
        src="/assets/images/illustrations/404.svg"
        sx={{
          display: "block",
          maxWidth: "320px",
          width: "100%",
          mb: "1.5rem",
        }}
      />
      <FlexBox flexWrap="wrap">
        <Button
          variant="outlined"
          color="primary"
          sx={{
            m: "0.5rem",
          }}
          onClick={handleGoBack}
        >
          Wstecz
        </Button>
        <Link to="/">
          <Button
            variant="contained"
            color="primary"
            sx={{
              m: "0.5rem",
            }}
          >
            Powrót na stronę główną
          </Button>
        </Link>
      </FlexBox>
    </FlexBox>
  );
};

export default Error404;
