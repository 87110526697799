import FlexBox from "../components/FlexBox";
import Signup from "../components/sessions/Signup";
import AppLayout from "../components/layout/AppLayout";
import Navbar from "../components/navbar/Navbar";
import React, { useEffect } from "react";
import { useTheme } from "@mui/system";
import { getPageContentWithFallback } from "../clients/cms";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppContext } from "../contexts/app/AppContext";
import { genericErrorToSnackbar } from "../utils/api/error-handling/apiErrorHandler.js";

const SignUpPage = () => {
  const { palette } = useTheme();

  const { dispatch } = useAppContext();

  const [pagecontent, set_pagecontent] = React.useState();
  const [checkbox_pagecontent, set_checkbox_pagecontent] = React.useState();
  // Get page content from page api
  useEffect(() => {
    getPageContentWithFallback("signup")
      .then((pagecontent) => {
        set_pagecontent(pagecontent);
      })
      .catch((error) => {
        set_pagecontent(error);
        genericErrorToSnackbar(error, dispatch);
      });
    getPageContentWithFallback("signup-checkboxes")
      .then((pagecontent) => {
        set_checkbox_pagecontent(pagecontent);
      })
      .catch((error) => {
        set_checkbox_pagecontent(error);
        genericErrorToSnackbar(error, dispatch);
      });
  }, []);

  if (
    pagecontent == null ||
    pagecontent instanceof Error ||
    checkbox_pagecontent == null ||
    checkbox_pagecontent instanceof Error
  ) {
    return (
      <AppLayout navbar={<Navbar />}>
        <FlexBox
          flexDirection="column"
          minHeight="60vh"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </FlexBox>
      </AppLayout>
    );
  }

  // Encase signup flexbox in SaleLayout template
  return (
    <AppLayout navbar={<Navbar />}>
      <FlexBox
        flexDirection="column"
        minHeight="60vh"
        alignItems="center"
        justifyContent="center"
      >
        <h2 style={{ color: palette.grey[700] }}>
          {pagecontent.data.attributes.title}
        </h2>
        <Signup
          pagecontent={pagecontent}
          checkbox_pagecontent={checkbox_pagecontent}
        />
      </FlexBox>
    </AppLayout>
  );
};

export default SignUpPage;
