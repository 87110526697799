import {
  CircularProgress,
  Dialog,
  Card,
  Typography,
  IconButton,
} from "@mui/material";
import ProductPledge from "../products/ProductPledge";
import { useEffect } from "react";
import { getProductPledge } from "../../clients/products";
import { useState } from "react";
import { genericErrorToSnackbar } from "../../utils/api/error-handling/apiErrorHandler";
import { useAppContext } from "../../contexts/app/AppContext";
import CloseIcon from "@mui/icons-material/Close";

const PledgeDialog = ({ open, on_close, product_id }) => {
  const { dispatch } = useAppContext();
  const [product, setProduct] = useState();

  useEffect(() => {
    getProductPledge(product_id)
      .then((response) => {
        setProduct(response.data.data);
      })
      .catch((err) => {
        console.log(err);
        genericErrorToSnackbar(err, dispatch);
      });
  }, []);

  const handleClose = () => {
    on_close();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        zIndex: 3000,
      }}
    >
      <Card
        sx={{
          minWidth: "200px",
          minHeight: "200px",
          padding: "15px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {!!product && !!product.attributes.vehicle_pledge && (
          <Typography mb={1} variant="h6">
            Zlaneziono zadłużenie:
          </Typography>
        )}
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        {!product && <CircularProgress />}
        {!!product && !!product.attributes.vehicle_pledge && (
          <ProductPledge
            pledge={product.attributes.vehicle_pledge?.Wierzyciele}
          />
        )}
        {!!product && !product.attributes.vehicle_pledge && (
          <Typography variant="h6">Nie znaleziono zadłużeń</Typography>
        )}
      </Card>
    </Dialog>
  );
};
export default PledgeDialog;
