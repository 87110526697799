import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { H5 } from "../Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getVehicleHistory } from "../../clients/products";
import { genericErrorToSnackbar } from "../../utils/api/error-handling/apiErrorHandler.js";
import { useAppContext } from "../../contexts/app/AppContext";

const ProductVehicleHistory = (props) => {
  const { slug } = props;
  const { dispatch } = useAppContext();

  const [vehicleHistory, setVehicleHistory] = useState();
  useEffect(() => {
    getVehicleHistory(slug)
      .then((response) => {
        setVehicleHistory(response.data);
      })
      .catch((err) => {
        genericErrorToSnackbar(err, dispatch);
      });
  }, [slug]);

  const vehicle = () => {
    const vehicleList = [];
    for (const i in vehicleHistory.attributes.vehicle_history.Pojazd) {
      if (vehicleHistory.attributes.vehicle_history.Pojazd[i] !== null) {
        vehicleList.push(
          <div key={i}>
            {i}: <b>{vehicleHistory.attributes.vehicle_history.Pojazd[i]}</b>
          </div>
        );
      }
    }

    return vehicleList;
  };

  const technicalData = () => {
    const vehicleList = [];
    for (const i in vehicleHistory.attributes.vehicle_history[
      "Dane techniczne"
    ]) {
      if (
        vehicleHistory.attributes.vehicle_history["Dane techniczne"][i] !== null
      ) {
        vehicleList.push(
          <div key={i}>
            {i}:{" "}
            <b>
              {vehicleHistory.attributes.vehicle_history["Dane techniczne"][i]}
            </b>
          </div>
        );
      }
    }

    return vehicleList;
  };

  const time = () => {
    const vehicleList = [];
    for (const i in vehicleHistory.attributes.vehicle_history["Oś czasu"]) {
      if (vehicleHistory.attributes.vehicle_history["Oś czasu"][i] !== null) {
        vehicleList.push(<H5 mt={1}>{i}</H5>);
        for (const j in vehicleHistory.attributes.vehicle_history["Oś czasu"][
          i
        ]) {
          vehicleList.push(
            <div>
              {j}:{" "}
              <b>
                {vehicleHistory.attributes.vehicle_history["Oś czasu"][i][j]}
              </b>
            </div>
          );
        }
      }
    }

    return vehicleList;
  };

  function SimpleAccordion() {
    return (
      <div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Pojazd</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{vehicle()}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Dane techniczne</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{technicalData()}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>Oś czasu</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{time()}</Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }

  if (!vehicleHistory) {
    return (
      <Box>
        <Skeleton variant="rectangular" height="40px" />
      </Box>
    );
  }

  if (!vehicleHistory.attributes.vehicle_history) {
    return (
      <Box>
        <Typography>Brak danych dla pojazdu</Typography>
      </Box>
    );
  }

  return <Box>{SimpleAccordion()}</Box>;
};
export default ProductVehicleHistory;
