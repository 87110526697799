import apiClient from "./api-client";

export const getCustomerOrderList = async () => {
  const response = await apiClient.get("/orders");
  return response.data;
};

export const getOrderDetails = async (id) => {
  const response = await apiClient.get("/orders/" + id);
  return response.data;
};

export const orderProduct = async (id, transport_id = null) => {
  let data = {
    fk_product: id,
  };
  if (transport_id) {
    data.fk_transport = transport_id;
  }
  const response = await apiClient.post("/orders", JSON.stringify(data));
  return response;
};

export const trackOrder = async (order_id) => {
  const response = await apiClient.get(`/orders/track/${order_id}`);
  return response;
};
