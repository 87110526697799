import { Skeleton, Box, Card, Typography, Grid } from "@mui/material";
import { Fragment } from "react";
import FlexBox from "../FlexBox";
import { H3, H5, Small } from "../Typography";
import CompanyDetailsSkeleton from "./CompanyDetailsSkeleton";

const ProfileSkeleton = () => {
  return (
    <Fragment>
      <Box mb={4}>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Card
              sx={{
                display: "flex",
                p: "14px 32px",
                height: "100%",
                alignItems: "center",
              }}
            >
              <Skeleton variant="circular" height={64} width={64} />
              <Box ml={1.5} flex="1 1 0">
                <FlexBox
                  flexWrap="wrap"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <div>
                    <H5 my="0px">
                      <Skeleton variant="text" width="20ch" />
                    </H5>
                    <FlexBox alignItems="center">
                      <Typography ml={0.5} color="primary.main">
                        <Skeleton variant="text" width="20ch" />
                      </Typography>
                    </FlexBox>
                  </div>

                  <Typography color="grey.600" letterSpacing="0.2em">
                    <Skeleton variant="text" width="10ch" />
                  </Typography>
                </FlexBox>
              </Box>
            </Card>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Grid container spacing={4}>
              {[1, 2, 3, 4].map((item) => (
                <Grid item lg={3} sm={6} xs={6} key={item.toString()}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                      p: "1rem 1.25rem",
                    }}
                  >
                    <H3 color="primary.main" my="0px" fontWeight="600">
                      <Skeleton variant="text" width="7ch" />
                    </H3>
                    <Small color="grey.600" textAlign="center">
                      <Skeleton variant="text" width="9ch" />
                      <Skeleton variant="text" width="9ch" />
                    </Small>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <CompanyDetailsSkeleton />
    </Fragment>
  );
};
export default ProfileSkeleton;
