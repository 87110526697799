import apiClient from "./api-client";

export const getRootCategories = async () => {
  const response = await apiClient.get("/machine-categories", {
    cache: {
      maxAge: 60 * 60 * 1000,
    },
  });
  return response.data;
};

export const getCategory = async (id) => {
  const response = await apiClient.get(`/machine-categories/${id}`);
  return response.data;
};

export const getSlugCategories = async () => {
  const response = await apiClient.get("/slugs/categories", {
    cache: {
      maxAge: 60 * 60 * 1000,
    },
  });
  return response.data;
};

export const getFormattedSlugCategories = async () => {
  const response = await getSlugCategories();
  const { data } = response;
  return data.map((category) => {
    return {
      params: {
        category: category.attributes.slug,
      },
    };
  });
};
