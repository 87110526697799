import DashboardLayout from "../../components/layout/CustomerDashboardLayout";
import CustomerDashboardNavigation from "../../components/layout/CustomerDashboardNavigation";
import DashboardPageHeader from "../../components/layout/DashboardPageHeader";
import TableRow from "../../components/TableRow";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import Place from "@mui/icons-material/Place";
import { Button, IconButton, Pagination, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getCompanyAddresses } from "../../clients/company-address";
import { Skeleton } from "@mui/material";

const AddressList = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    getCompanyAddresses()
      .then((response) => {
        const { data } = response;
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {});
  }, []);

  return (
    <DashboardLayout>
      <DashboardPageHeader
        title="Moje adresy"
        icon={Place}
        button={
          <Button
            color="primary"
            sx={{ bgcolor: "primary.light", px: "2rem" }}
            href={"/address/add"}
          >
            Dodaj adres
          </Button>
        }
        navigation={<CustomerDashboardNavigation />}
      />

      {isLoading && (
        <TableRow sx={{ my: "1rem", padding: "6px 18px" }}>
          <Typography whiteSpace="pre" m={0.75} textAlign="left">
            <Skeleton variant="text" />
          </Typography>
          <Typography flex="1 1 260px !important" m={0.75} textAlign="left">
            <Skeleton variant="text" />
          </Typography>

          <Typography whiteSpace="pre" textAlign="right" color="grey.600">
            <Skeleton variant="text" />
          </Typography>
        </TableRow>
      )}

      {data.map((row) => (
        <TableRow sx={{ my: "1rem", padding: "6px 18px" }} key={row.id}>
          <Typography whiteSpace="pre" m={0.75} textAlign="left">
            {row.attributes.name}
          </Typography>
          <Typography flex="1 1 260px !important" m={0.75} textAlign="left">
            {row.attributes.address1}, {row.attributes.post_code}{" "}
            {row.attributes.place}
          </Typography>

          <Typography whiteSpace="pre" textAlign="right" color="grey.600">
            <Link to={`/address/${row.id}`}>
              <IconButton>
                <Edit fontSize="small" color="inherit" />
              </IconButton>
            </Link>
            {/*<IconButton onClick={e => e.stopPropagation()}>*/}
            {/*  <Delete fontSize="small" color="inherit" />*/}
            {/*</IconButton>*/}
          </Typography>
        </TableRow>
      ))}
    </DashboardLayout>
  );
};

export default AddressList;
