import Card1 from "../Card1";
import { Button, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import DashboardLayout from "../layout/CustomerDashboardLayout";
import CircularProgress from "@mui/material/CircularProgress";
import { saveCustomerProfile } from "../../clients/customer-profile";
import { useAppContext } from "../../contexts/app/AppContext";
import { useNavigate } from "react-router-dom";

const CustomerEditor = ({ values, onCloseHandler }) => {
  const { state, dispatch } = useAppContext();
  const navigate = useNavigate();

  const checkoutSchema = yup.object().shape({
    first_name: yup.string().required("required"),
    last_name: yup.string().required("required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: checkoutSchema,
    initialValues: values,
    onSubmit: (values) => {
      saveCustomerProfile(values, values.id)
        .then((response) => {
          const { data, errors, validations } = response;

          if (validations !== undefined) {
            validations.forEach((error) => {
              formik.setFieldError(error.source, error.title);
            });
          }

          if (data !== undefined) {
            dispatch({
              type: "SHOW_SNACKBAR",
              payload: { text: "Dane zostały zmienione", type: "success" },
            });
            // Allow to overrride default redirect
            if (onCloseHandler === undefined) {
              navigate("/profile");
            } else {
              onCloseHandler();
            }
          }

          formik.setSubmitting(false);
        })
        .catch((error) => {
          dispatch({
            type: "SHOW_SNACKBAR",
            payload: { text: "Wystąpił błąd" + error, type: "error" },
          });
        });
    },
  });

  return (
    <div>
      <Card1>
        <h3>Profil</h3>
        <form onSubmit={formik.handleSubmit}>
          <Box mb={4}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  name="first_name"
                  label="Imię"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.first_name || ""}
                  error={
                    !!formik.touched.first_name && !!formik.errors.first_name
                  }
                  helperText={
                    formik.touched.first_name && formik.errors.first_name
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name="last_name"
                  label="Nazwisko"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.last_name || ""}
                  error={
                    !!formik.touched.last_name && !!formik.errors.last_name
                  }
                  helperText={
                    formik.touched.last_name && formik.errors.last_name
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name="phone_number"
                  label="Numer telefonu"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.phone_number || ""}
                  error={
                    !!formik.touched.phone_number &&
                    !!formik.errors.phone_number
                  }
                  helperText={
                    formik.touched.phone_number && formik.errors.phone_number
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting}
          >
            Zapisz zmiany
          </Button>
        </form>
      </Card1>
    </div>
  );
};
CustomerEditor.layout = DashboardLayout;
export default CustomerEditor;
