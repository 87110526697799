class NavigateService {
  constructor() {
    this._navigate = null;
    this._location = null;
  }
  set navigate(navigate) {
    this._navigate = navigate;
  }
  get navigate() {
    return this._navigate;
  }
  set location(location) {
    this._location = location;
  }
  get location() {
    return this._location;
  }
}

const navigateService = new NavigateService();
export default navigateService;
