/* eslint-disable react-hooks/exhaustive-deps */
import BazarMenu from "../BazarMenu";
import FlexBox from "../FlexBox";
import KeyboardArrowDownOutlined from "@mui/icons-material/KeyboardArrowDownOutlined";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import {
  Box,
  Card,
  MenuItem,
  TextField,
  Typography,
  Skeleton,
} from "@mui/material";
import TouchRipple from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";
import { debounce } from "@mui/material/utils";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getRootCategories } from "../../clients/machine-categories";
import {
  genericErrorToSnackbar,
  handleApiResponse,
} from "../../utils/api/error-handling/apiErrorHandler";
import { useAppContext } from "../../contexts/app/AppContext";
import { getSearchSuggestions } from "../../clients/search-suggestions";

// also used in the GrocerySearchBox component
export const SearchOutlinedIcon = styled(SearchOutlined)(({ theme }) => ({
  color: theme.palette.grey[600],
  marginRight: 6,
}));

// also used in the GrocerySearchBox component
export const SearchResultCard = styled(Card)(() => ({
  position: "absolute",
  top: "100%",
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
  width: "100%",
  zIndex: 99,
}));
const DropDownHandler = styled(FlexBox)(({ theme }) => ({
  borderTopRightRadius: 300,
  borderBottomRightRadius: 300,
  whiteSpace: "pre",
  borderLeft: `1px solid ${theme.palette.text.disabled}`,
  [theme.breakpoints.down("xs")]: {
    display: "none",
  },
}));

const SearchBox = () => {
  const [category, setCategory] = useState({
    name: "Wszystkie Maszyny",
    slug: null,
    id: null,
  });
  const [categories, setCategories] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const { dispatch } = useAppContext();
  const parentRef = useRef();

  // Fetch machine root categories for dropdown
  useEffect(() => {
    getRootCategories()
      .then((response) => {
        setCategories([
          { name: "Wszystkie Maszyny", slug: null, id: null },
          ...response.data?.map((elem) => ({
            name: elem.attributes.name,
            slug: elem.attributes.slug,
            id: elem.id,
          })),
        ]);
      })
      .catch((error) => {
        genericErrorToSnackbar(error, dispatch);
      });
  }, []);

  // set new category
  const handleCategoryChange = (cat) => () => {
    setCategory(cat);
  };

  const handleLinkClick = (url) => {
    navigate(url);
    setShowResults(false);
  };

  // Re run searching on category change
  useEffect(() => {
    if (searchString) {
      runSearch();
    }
  }, [category]);

  // make a search suggestion request
  const runSearch = () => {
    setIsFetching(true);
    getSearchSuggestions(searchString, category.slug)
      .then((response) => {
        handleApiResponse(response, dispatch, {
          on_success: () => {
            // build suggestions element
            let suggestions = response.data.data.attributes.categories.map(
              (elem) => ({
                type: "Filtruj kategoria: ",
                name:
                  elem.attributes.id_parent == 0
                    ? elem.attributes.name
                    : `${
                        categories.filter(
                          (parent) => parent.id == elem.attributes.id_parent
                        )[0]?.name
                      } ${elem.attributes.name}`,
                href: `/catalog/${elem.attributes.slug}`,
              })
            );

            suggestions = [
              ...suggestions,
              ...response.data.data.attributes.producers.map((elem) => ({
                type: "Filtruj producent: ",
                name: elem.attributes.name,
                href: `/catalog?${new URLSearchParams({
                  filter_id_producer: elem.id,
                })}`,
              })),
            ];
            suggestions = [
              ...suggestions,
              ...response.data.data.attributes.products.map((elem) => ({
                type: "",
                name: `${elem.relationships?.model.relationships?.producer.attributes.name} ${elem.relationships?.model.attributes.name}`,
                href: `/product/${elem.attributes.slug}`,
                price: elem.attributes.price.toFixed(2),
              })),
            ];
            setResultList(suggestions);
            setIsFetching(false);
          },
        });
      })
      .catch((err) => {
        genericErrorToSnackbar(err, dispatch);
      });
  };

  // on search phrase change re run search
  useEffect(() => {
    if (searchString) {
      setResultList([]);
      runSearch();
    }
  }, [searchString]);

  // debounce searches to 500ms
  const search = debounce((e) => {
    const value = e.target?.value;
    if (!value) {
      setResultList([]);
      setShowResults(false);
    } else {
      setShowResults(true);
      setSearchString(value);
    }
  }, 500);

  const hanldeSearch = useCallback((event) => {
    event.persist();
    search(event);
  }, []);

  const categoryDropdown = (
    <BazarMenu
      direction="left"
      handler={
        <DropDownHandler
          alignItems="center"
          bgcolor="grey.100"
          height="100%"
          px={3}
          color="grey.700"
          component={TouchRipple}
        >
          <Box mr={0.5}>{category.name}</Box>
          <KeyboardArrowDownOutlined fontSize="small" color="inherit" />
        </DropDownHandler>
      }
    >
      {categories.map((item) => (
        <MenuItem key={item.name} onClick={handleCategoryChange(item)}>
          {item.name}
        </MenuItem>
      ))}
    </BazarMenu>
  );
  return (
    <Box
      position="relative"
      flex="1 1 0"
      maxWidth="670px"
      mx="auto"
      {...{
        ref: parentRef,
      }}
    >
      <TextField
        variant="outlined"
        placeholder="Szukaj..."
        fullWidth
        onChange={hanldeSearch}
        onFocus={() => {
          if (searchString) {
            setShowResults(true);
          }
        }}
        onBlur={(e) => {
          if (e.relatedTarget?.getAttribute("ot") !== "suggestions") {
            setShowResults(false);
          }
        }}
        InputProps={{
          sx: {
            height: 44,
            borderRadius: 300,
            paddingRight: 0,
            color: "grey.700",
            overflow: "hidden",
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "primary.main",
            },
          },
          endAdornment: categoryDropdown,
          startAdornment: <SearchOutlinedIcon fontSize="small" />,
        }}
      />
      <SearchResultCard
        id="test"
        sx={{
          display: showResults ? "block" : "none",
        }}
        elevation={2}
      >
        {!resultList.length && isFetching && (
          <MenuItem ot="suggestions">
            <Skeleton width="100%" variant="text" />
          </MenuItem>
        )}
        {!!searchString && !resultList.length && !isFetching && (
          <MenuItem ot="suggestions">
            <Typography color="grey.600">Brak wyników</Typography>
          </MenuItem>
        )}
        {!!resultList.length &&
          resultList.map((item) => (
            <MenuItem
              key={item.href}
              ot="suggestions"
              onClick={() => {
                handleLinkClick(item.href);
              }}
            >
              {item.type ? (
                <Typography mr={1} color="grey.600">
                  {item.type}
                </Typography>
              ) : null}
              {item.name}
              {item.price ? (
                <Typography ml="auto" color="grey.600">
                  {item.price} PLN
                </Typography>
              ) : null}
            </MenuItem>
          ))}
      </SearchResultCard>
    </Box>
  );
};
export default SearchBox;
