import { Box, Card, Grid, Typography, Skeleton } from "@mui/material";
import { H5 } from "../Typography";
import FlexBox from "../FlexBox";

const CompanyDetailsSkeleton = () => {
  return (
    <Box mb={4}>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card
            sx={{
              display: "flex",
              p: "14px 32px",
              height: "100%",
              alignItems: "center",
            }}
          >
            <Box ml={1.5} flex="1 1 0">
              <FlexBox
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems="center"
              >
                <div>
                  <H5 my="0px">
                    <Skeleton variant="text" width="10ch" />{" "}
                  </H5>
                  <FlexBox alignItems="center">
                    <Typography ml={0.5} color="primary.main">
                      <Skeleton variant="text" width="20ch" />
                      <Skeleton variant="text" width="15ch" />
                      <Skeleton variant="text" width="10ch" />
                      <Skeleton variant="text" width="10ch" />
                    </Typography>
                  </FlexBox>
                </div>
                <Typography ml={0.5} color="primary.main">
                  <Skeleton variant="text" width="10ch" />
                  <Skeleton variant="text" width="10ch" />
                </Typography>
              </FlexBox>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
export default CompanyDetailsSkeleton;
