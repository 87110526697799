import { useState } from "react";
import NavbarLayout from "../../components/layout/NavbarLayout";
import ProductInspection from "../../components/products/ProductInspection";
import { Box, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getDetailProduct } from "../../clients/products";
import ProductSpecification from "../../components/products/ProductSpecification";
import ProductEquipment from "../../components/products/ProductEquipment";
import ProductDetails from "../../components/products/ProductDetails";
import { getPageContentWithFallback } from "../../clients/cms";
import { genericErrorToSnackbar } from "../../utils/api/error-handling/apiErrorHandler.js";
import { useAppContext } from "../../contexts/app/AppContext";
import ProductSkeleton from "../../components/products/ProductSkeleton";
import { useEffect } from "react";
import ImageSlider from "../../components/products/ImageSlider";
import ImageSliderSkeleton from "../../components/products/ImageSliderSkeleton";
import ProductVehicleHistory from "../../components/products/ProductVehicleHistory";
import Error404 from "../404";
import PublicInspectionPreview from "../../components/public-inspection-preview/PublicInspectionPreview";
import { useParams } from "react-router-dom";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginTop: 80,
  marginBottom: 24,
  minHeight: 0,
  borderBottom: `1px solid ${theme.palette.text.disabled}`,
  "& .inner-tab": {
    fontWeight: 600,
    minHeight: 40,
    textTransform: "capitalize",
  },
}));

const ProductDetailsPage = () => {
  const [pageContent, setPageContent] = useState();
  const [product, setProduct] = useState();
  const [selectedOption, setSelectedOption] = useState(0);

  const { slug } = useParams();
  const { dispatch } = useAppContext();

  const handleOptionClick = (_event, newValue) => {
    setSelectedOption(newValue);
  };

  useEffect(() => {
    getPageContentWithFallback("product-details")
      .then((response) => {
        setPageContent(response.data.attributes);
      })
      .catch((err) => {
        genericErrorToSnackbar(err, dispatch);
      });
  }, []);

  // Load product data
  useEffect(() => {
    if (slug) {
      // Clear product to show loading animation
      setProduct();
      setSelectedOption(0);
      getDetailProduct(slug)
        .then((response) => {
          setProduct(response.data);
        })
        .catch((err) => {
          genericErrorToSnackbar(err, dispatch);
          setProduct(err);
        });
    }
  }, [slug]);

  // Before data is fetched show page skeleton
  if (!product || !pageContent) {
    return (
      <NavbarLayout>
        <ImageSliderSkeleton />
        <ProductSkeleton />
        <StyledTabs
          variant="scrollable"
          scrollButtons={true}
          value={0}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab className="inner-tab" label="Specyfikacja" />
          <Tab className="inner-tab" label="Wyposażenie" />
          <Tab className="inner-tab" label="Twoja inspekcja" />
          <Tab className="inner-tab" label="Historia Pojazdu" />
          <Tab className="inner-tab" label="Opublikowana Inspekcja" />
        </StyledTabs>

        <Box mb={6} />
      </NavbarLayout>
    );
  }

  if (product instanceof Error) {
    return <Error404 />;
  }

  return (
    <NavbarLayout>
      <ImageSlider images={product.attributes.images} />

      <ProductDetails product={product} pageContent={pageContent} />

      <StyledTabs
        variant="scrollable"
        scrollButtons="auto"
        value={selectedOption}
        onChange={handleOptionClick}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab className="inner-tab" label="Specyfikacja" />
        <Tab className="inner-tab" label="Wyposażenie" />
        <Tab className="inner-tab" label="Twoja inspekcja" />
        <Tab
          className="inner-tab"
          label="Historia pojazdu"
          disabled={
            !product.attributes.vin ||
            !product.attributes.registration_number ||
            !product.attributes.first_registration_date
          }
        />
        <Tab
          className="inner-tab"
          label="Opublikowana Inspekcja"
          disabled={!product.relationships?.public_inspection}
        />
      </StyledTabs>

      <Box mb={6}>
        {selectedOption === 0 && (
          <ProductSpecification
            specification={product.attributes.model_specification}
          />
        )}
        {selectedOption === 1 && (
          <ProductEquipment equipment={product.attributes.model_equipment} />
        )}
        {selectedOption === 2 && (
          <ProductInspection
            product={product}
            slug={slug}
            pageContent={pageContent}
          />
        )}
        {selectedOption === 3 && <ProductVehicleHistory slug={slug} />}
        {selectedOption === 4 && (
          <PublicInspectionPreview
            inspection={product.relationships?.public_inspection}
          />
        )}
      </Box>
    </NavbarLayout>
  );
};
export default ProductDetailsPage;
