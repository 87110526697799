import apiClient from "./api-client";
import { pageApiFallbacks } from "../utils/api/fallbacks/pageApiFallbacks";
export const getPageContent = async (pageKey) => {
  const response = await apiClient.get("/pages/" + pageKey);
  return response.data;
};

export const getPageContentWithFallback = async (pageKey) => {
  try {
    const response = await apiClient.get("/pages/" + pageKey);
    return response.data;
  } catch {
    let response = {
      data: {},
    };
    try {
      let fallback = pageApiFallbacks[pageKey];
      if (fallback !== undefined) {
        response.data.attributes = pageApiFallbacks[pageKey];
        return response;
      }
      throw new Error("Błąd pobierania danych z servera");
    } catch {
      throw new Error("Błąd pobierania danych z servera");
    }
  }
};
