import apiClient from "./api-client";

export const getSearchSuggestions = async (search, category = null) => {
  if (category) {
    return await apiClient.post(
      `/search-suggestions/${category}`,
      JSON.stringify({
        search: search,
      })
    );
  } else {
    return await apiClient.post(
      "/search-suggestions",
      JSON.stringify({
        search: search,
      })
    );
  }
};
