import { Skeleton } from "@mui/material";

const ImageSliderSkeleton = () => {
  return (
    <Skeleton
      sx={{ marginBottom: "20px" }}
      variant="rectangular"
      width="100%"
      height="20rem"
    />
  );
};
export default ImageSliderSkeleton;
