import AppStore from "../AppStore";
import { Paragraph, H1 } from "../Typography";
import { Box, Container, Grid, styled } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import { getNavigationElements } from "../about/Routes";
import { contact_mail, contact_phone } from "../../utils/constants";

const StyledLink = styled(Link)(({ theme }) => ({
  position: "relative",
  display: "block",
  padding: "0.3rem 0rem",
  color: theme.palette.grey[500],
  cursor: "pointer",
  borderRadius: 4,
  "&:hover": {
    color: theme.palette.grey[100],
  },
}));

const Footer = () => {
  const aboutLinks = getNavigationElements();
  return (
    <footer>
      <Box bgcolor="#0c0e30">
        <Container
          sx={{
            p: "1rem",
            color: "white",
          }}
        >
          <Box py={10} pb={1} overflow="hidden">
            <Grid container spacing={3}>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <Link to="/">
                  {/*<Image mb={2.5} src="/assets/images/logo.svg" alt="logo" />*/}
                  machiner.pro
                </Link>

                <Paragraph
                  mb={2.5}
                  color="grey.500"
                  sx={{ textAlign: "justify" }}
                >
                  W obecnych czasach rozwój sektora maszyn budowlanych wymagać
                  będzie od nas transformacji cyfrowej, która pozwoli zwiększyć
                  firmom wydajność. Nowe zaawansowane technologie, takie jak
                  sztuczna inteligencja, pomogą wzbogacić cały proces,
                  poczynając od projektowania poprzez składanie ofert, prace
                  budowlane, aż po przekształcenie modelu biznesowego. Aby
                  poprawa wydajności była zauważalna, cyfryzacja powinna objąć
                  cały cykl budowlany, w tym łańcuch dostaw. Idąc z duchem czasu
                  stworzyliśmy machiner.pro czyli pomocne narzędzie dla firm
                  zajmujących się maszynami budowlanymi jako początek tej
                  zmiany.
                </Paragraph>

                <AppStore />
              </Grid>

              <Grid item lg={2} md={6} sm={6} xs={12}></Grid>

              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Box
                  fontSize="25px"
                  fontWeight="600"
                  mb={2.5}
                  lineHeight="1"
                  color="white"
                >
                  Informacje
                </Box>

                <div>
                  {aboutLinks.map((item, ind) => (
                    <StyledLink to={item.href} key={ind}>
                      {item.display_name}
                    </StyledLink>
                  ))}
                </div>
              </Grid>

              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Box
                  fontSize="25px"
                  fontWeight="600"
                  mb={2.5}
                  lineHeight="1"
                  color="white"
                >
                  Kontakt z nami
                </Box>
                <Box py={0.6} color="grey.500">
                  ul. Sokołowska 13/7 <br />
                  01-142 Warszawa
                </Box>
                <Box py={0.6} color="grey.500">
                  Email: {contact_mail}
                </Box>
                <Box py={0.6} mb={2} color="grey.500">
                  Telefon: {contact_phone}
                </Box>
                {/* <FlexBox className="flex" mx={-0.625}>
                  {iconList.map((item, ind) => (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noreferrer noopenner"
                      key={ind}
                    >
                      <BazarIconButton
                        m={0.5}
                        bgcolor="rgba(0,0,0,0.2)"
                        fontSize="12px"
                        padding="10px"
                      >
                        <item.icon fontSize="inherit" />
                      </BazarIconButton>
                    </a>
                  ))}
                </FlexBox> */}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "64px",
        }}
      >
        <img
          style={{
            maxHeight: "150px",
            maxWidth: "80%",
          }}
          src="/assets/images/program_logos.jpeg"
          alt="program logos"
        />
      </Box>
    </footer>
  );
};
// const iconList = [
//   {
//     icon: Facebook,
//     url: "",
//   },
//   {
//     icon: Twitter,
//     url: "",
//   },
//   {
//     icon: Youtube,
//     url: "",
//   },
//   {
//     icon: Google,
//     url: "",
//   },
//   {
//     icon: Instagram,
//     url: "",
//   },
// ];
export default Footer;
