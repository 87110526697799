import FlexBox from "../components/FlexBox";
import AppLayout from "../components/layout/AppLayout";
import Navbar from "../components/navbar/Navbar";
import ProductCard from "../components/product-cards/ProductCard";
import { H1 } from "../components/Typography";
import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { getFeaturedProducts } from "../clients/search-products";
import ProductCardSkeleton from "../components/product-cards/ProductCardSkeleton";
import { getPageContent } from "../clients/cms";

const IndexPage = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageContent, setPageContent] = useState();

  // Fetch products
  useEffect(() => {
    setIsLoading(true);
    getFeaturedProducts()
      .then((response) => {
        const { data } = response;
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    getPageContent("parent-page")
      .then((response) => {
        setPageContent(response.data.attributes);
      })
      .catch((err) => {});
  }, []);

  return (
    <AppLayout navbar={<Navbar />}>
      <Container
        sx={{
          mt: "2rem",
        }}
      >
        <Box>
          <FlexBox mb={4} flexWrap="wrap">
            <H1 color="primary.main" mr={1} lineHeight="1">
              {pageContent?.index_heading?.split(" ").slice(0, 2).join(" ") ||
                ""}
            </H1>
            <H1 color="grey.600" lineHeight="1">
              {pageContent?.index_heading?.split(" ").slice(2).join(" ") || ""}
            </H1>
          </FlexBox>
        </Box>

        <Grid container spacing={3} minHeight={500}>
          {isLoading &&
            [1, 2, 3].map((item, ind) => (
              <Grid item lg={4} md={4} sm={6} xs={12} key={ind}>
                <ProductCardSkeleton />
              </Grid>
            ))}
          {!isLoading &&
            data.map((item, ind) => (
              <Grid item lg={4} md={4} sm={6} xs={12} key={ind}>
                <ProductCard item={item} />
              </Grid>
            ))}
        </Grid>

        <FlexBox
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
          my="4rem"
        >
          {/*<Span>{renderProductCount()}</Span>*/}
          {/*<Pagination*/}
          {/*	page={page}*/}
          {/*	variant="outlined"*/}
          {/*	color="primary"*/}
          {/*	count={Math.ceil(productDB.length / productPerPage)}*/}
          {/*	onChange={handlePageChange}*/}
          {/*/>*/}
        </FlexBox>
      </Container>
    </AppLayout>
  );
};

const saleCategoryList = [
  // {
  // 	icon: Truck,
  // 	title: "Tuck",
  // },
  // {
  // 	icon: TruckFilled,
  // 	title: "Trucks 2",
  // },
];
export default IndexPage;
