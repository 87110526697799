import { Box } from "@mui/material";
import React from "react";

const ProductPledge = (props) => {
  const { pledge } = props;

  const renderPledge = () => {
    const PledgeList = [];
    for (const i in pledge) {
      if (pledge[i] !== null) {
        PledgeList.push(
          <div key={i}>
            {i}: <b>{pledge[i]}</b>
          </div>
        );
      }
    }

    return PledgeList;
  };

  return (
    <Box>
      <Box>{renderPledge()}</Box>
    </Box>
  );
};

export default ProductPledge;
