import { Button, Card, Typography } from "@mui/material";
import FlexBox from "../FlexBox";

const Downloads = () => {
  return (
    <FlexBox
      sx={{
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Typography variant="h4">Załączniki</Typography>
      <Card
        sx={{
          padding: "10px",
          minWidth: "40vw",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {download_links.map((elem) => (
          <a key={elem.name} href={elem.href}>
            <Button
              fullWidth
              target="_blank"
              color="primary"
              variant="outlined"
            >
              {elem.name}
            </Button>
          </a>
        ))}
      </Card>
    </FlexBox>
  );
};
const download_links = [
  {
    name: "Załącznik 1 - Towary Zakazane",
    href: "/pdfs/zalacznik_1.pdf",
  },
  {
    name: "Załącznik 2 - Zasady tworzenia oferty",
    href: "/pdfs/zalacznik_2.pdf",
  },
  {
    name: "Załącznik 3 - Polityka przerw technicznych",
    href: "/pdfs/zalacznik_3.pdf",
  },
  {
    name: "Załącznik 4 - Opłaty i prowizje",
    href: "/pdfs/zalacznik_4.pdf",
  },
  {
    name: "Załącznik 5 - Polityka prywatności",
    href: "/pdfs/zalacznik_5.pdf",
  },
  {
    name: "Załącznik 6 - Odstapieniu od umowy oraz wzór oświadczenia",
    href: "/pdfs/zalacznik_6.pdf",
  },
  {
    name: "Załącznik 7 - Polityka cookies",
    href: "/pdfs/zalacznik_7.pdf",
  },
  {
    name: "Regulamin Machiner.pro",
    href: "/pdfs/regulamin.pdf",
  },
];
export default Downloads;
