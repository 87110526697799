import FlexBox from "../components/FlexBox";
import Login from "../components/sessions/Login";
import AppLayout from "../components/layout/AppLayout";
import Navbar from "../components/navbar/Navbar";
import { useNavigate, useSearchParams } from "react-router-dom";

const LoginPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  // get next param from url
  const next = searchParams.get("next");

  // callback function for login component to execute on successful login
  const on_success = () => {
    if (next) {
      navigate(next);
    } else {
      navigate("/");
    }
  };

  return (
    <AppLayout navbar={<Navbar />}>
      <FlexBox
        flexDirection="column"
        minHeight="60vh"
        alignItems="center"
        justifyContent="center"
      >
        <Login on_success={on_success} />
      </FlexBox>
    </AppLayout>
  );
};

export default LoginPage;
