import FlexBox from "../FlexBox";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Person from "@mui/icons-material/Person";
import Place from "@mui/icons-material/Place";
import Logout from "@mui/icons-material/Logout";
import ShoppingBagOutlined from "@mui/icons-material/ShoppingBagOutlined";
import PercentIcon from "@mui/icons-material/Percent";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import HistoryIcon from "@mui/icons-material/History";
import { Typography, useTheme, useMediaQuery } from "@mui/material";
import React, { Fragment } from "react";
import {
  DashboardNavigationWrapper,
  StyledDashboardNav,
} from "./DashboardStyle";
import { useAppContext } from "../../contexts/app/AppContext";
import { useLocation } from "react-router-dom";

const CustomerDashboardNavigation = () => {
  const { pathname } = useLocation();
  const { state } = useAppContext();
  const { user } = state;
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const counters = {
    "/machines": user?.messages?.attributes?.counters?.products || 0,
    "/wish-list": user?.favourites?.length || 0,
    "/offer": user?.messages?.attributes?.counters?.offers || 0,
    "/orders": user?.messages?.attributes?.counters?.orders || 0,
    "/payment": user?.messages?.attributes?.counters?.payments || 0,
    "/inspections": user?.messages?.attributes?.counters?.inspections || 0,
  };

  const makeMenu = (item) => {
    return (
      <Fragment key={item.title}>
        <Typography p="26px 30px 1rem" color="grey.600" fontSize="12px">
          {item.title}
        </Typography>
        {item.list.map((item) => (
          <StyledDashboardNav
            isCurrentPath={pathname == item.href}
            href={item.href}
            key={item.href}
          >
            <FlexBox alignItems="center">
              <item.icon
                className="nav-icon"
                fontSize="small"
                color="inherit"
                sx={{
                  mr: "10px",
                }}
              />

              <span>{item.title}</span>
            </FlexBox>
            <span>{counters[item.href] || ""}</span>
          </StyledDashboardNav>
        ))}
      </Fragment>
    );
  };

  return (
    <DashboardNavigationWrapper
      sx={{
        px: "0px",
        pb: "1.5rem",
        color: "grey.900",
      }}
    >
      {linkList.map(
        (item) =>
          item.claim.some((elem) =>
            user?.user?.attributes?.claim.includes(elem)
          ) && (!item.mobile_only ? makeMenu(item) : isMobile && makeMenu(item))
      )}
    </DashboardNavigationWrapper>
  );
};

const linkList = [
  {
    claim: ["buyer", "seller", "inspector"],
    title: "TWOJE KONTO",
    list: [
      {
        href: "/profile",
        title: "Profil",
        icon: Person,
      },
      {
        href: "/wish-list",
        title: "Ulubione",
        icon: FavoriteBorder,
      },
      {
        href: "/address",
        title: "Adresy",
        icon: Place,
        count: 16,
      },
      {
        href: "/payment",
        onclick: "",
        title: "Płatności",
        icon: PercentIcon,
      },
      {
        href: "/auth/logout",
        title: "Wyloguj",
        icon: Logout,
      },
    ],
  },
  {
    claim: ["buyer", "seller"],
    title: "SPRZEDAŻ / KUPNO",
    list: [
      {
        href: "/machines/add",
        onclick: "",
        title: "Dodaj maszynę",
        icon: ShoppingBagOutlined,
      },
      {
        href: "/machines",
        onclick: "",
        title: "Wystawione maszyny",
        icon: ShoppingBagOutlined,
      },
      {
        href: "/inspections",
        onclick: "",
        title: "Inspekcje",
        icon: FactCheckOutlinedIcon,
      },
      {
        href: "/offer",
        onclick: "",
        title: "Oferty",
        icon: PercentIcon,
      },
      {
        href: "/orders",
        onclick: "",
        title: "Zamówienia",
        icon: AssignmentTurnedInOutlinedIcon,
      },
    ],
  },
  {
    claim: ["inspector"],
    title: "INSPEKCJA",
    mobile_only: true,
    list: [
      {
        name: "inspector-inspections",
        href: "/inspector-inspections",
        onclick: "",
        title: "Lista inspekcji",
        icon: FactCheckOutlinedIcon,
      },
      {
        name: "inspector-inspections-history",
        href: "/inspector-inspections/history",
        onclick: "",
        title: "Historia inspekcji",
        icon: HistoryIcon,
      },
    ],
  },
];
export default CustomerDashboardNavigation;
