import { Typography, Box } from "@mui/material";
import { Fragment } from "react";

const ContactThankYouProduct = ({ name }) => {
  return (
    <Fragment>
      <Typography mt="50px" variant="h4">
        Dziękujemy za przesłanie wiadomości, {name}
      </Typography>
      <Box
        sx={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          minWidth: "40vw",
          alignItems: "center",
          marginBottom: "50px",
        }}
      >
        <Typography>Prosimy oczekiwać na kontakt zwrotny</Typography>
      </Box>
    </Fragment>
  );
};
export default ContactThankYouProduct;
