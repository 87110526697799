import apiClient from "./api-client";

export const getCustomerProfile = async () => {
  const response = await apiClient.get("/customer-profile");
  return response.data;
};

export const saveCustomerPassword = async (payload) => {
  const response = await apiClient.patch(
    "/customer-profile",
    JSON.stringify(payload)
  );
  return response.data;
};

export const saveCustomerProfile = async (payload) => {
  const response = await apiClient.patch(
    "/customer-profile",
    JSON.stringify(payload)
  );
  return response.data;
};

export const deleteCustomerProfile = async () => {
  return await apiClient.delete("/customer-profile");
};
