import { CircularProgress, Card } from "@mui/material";
import FlexBox from "../FlexBox";

const LoadingCard = () => {
  return (
    <FlexBox justifyContent="center" alignItems="center">
      <Card
        sx={{
          margin: "30px 0 30px 0",
          minWidth: "40vw",
          minHeight: "60vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Card>
    </FlexBox>
  );
};
export default LoadingCard;
