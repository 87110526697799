import DashboardLayout from "../../components/layout/CustomerDashboardLayout";
import React, { useState } from "react";
import DashboardPageHeader from "../../components/layout/DashboardPageHeader";
import CustomerDashboardNavigation from "../../components/layout/CustomerDashboardNavigation";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import MachineDetails from "../../components/machines/MachineDetails";
import { useParams } from "react-router-dom";

const MachineDetailsPage = () => {
  // get id from url
  const { id } = useParams();

  // state var for machine model and producer string
  const [machineName, setMachineName] = useState("Widok Maszyny");

  return (
    <DashboardLayout>
      <DashboardPageHeader
        icon={AgricultureIcon}
        title={machineName}
        navigation={<CustomerDashboardNavigation />}
      />
      <MachineDetails setMachineName={setMachineName} id={id} />
    </DashboardLayout>
  );
};

export default MachineDetailsPage;
