import apiClient from "./api-client";

export const postInspectionResults = async (data) => {
  const response = await apiClient.post(
    "/order-inspections/create-result",
    JSON.stringify(data)
  );
  return response.data;
};

export const postQuestionResults = async (
  id_inspection,
  id_inspection_step,
  answers,
  image = null
) => {
  let data = {
    id_inspection: id_inspection,
    id_inspection_step: id_inspection_step,
    result: {},
  };
  Object.entries(answers).forEach((elem) => {
    data.result[elem[0]] = elem[1];
  });
  if (image) {
    data.result = {
      ...data.result,
      [image.field]: image.data,
    };
  }
  const response = await apiClient.post(
    "/order-inspections/steps",
    JSON.stringify(data)
  );
  return response.data;
};

export const finishInspection = async (inspection_id) => {
  const response = await apiClient.post(
    "/finish-inspection",
    JSON.stringify({
      id_inspection: inspection_id,
    })
  );
  return response.data;
};

export const postQuestionVideo = async (
  id_inspection,
  id_inspection_step,
  field,
  video
) => {
  const formData = new FormData();
  formData.append("id_inspection", id_inspection);
  formData.append("id_inspection_step", id_inspection_step);
  formData.append(field, video);
  return await apiClient.post("/order-inspections/steps", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
