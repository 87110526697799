import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

export default function WithLoading(loading) {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: 5000,
      }}
      open={!!loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
