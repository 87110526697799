import React, { Fragment } from "react";
import nprogress from "nprogress";
import "nprogress/nprogress.css";

class LoadingWrapper extends React.Component {
  constructor(props) {
    nprogress.start();
    super(props);
  }

  componentDidMount() {
    window.addEventListener("load", handleLoadingDone);
  }

  componentWillUnmount() {
    window.removeEventListener("load", handleLoadingDone);
  }

  render() {
    return <Fragment>{this.props.children}</Fragment>;
  }
}
const handleLoadingDone = () => {
  nprogress.done();
};

export default LoadingWrapper;
