import AddressEditor from "../../components/address/AddressEditor";
import DashboardLayout from "../../components/layout/CustomerDashboardLayout";
import React, { useEffect, useState } from "react";
import { getCompanyAddress } from "../../clients/company-address";
import DashboardPageHeader from "../../components/layout/DashboardPageHeader";
import Place from "@mui/icons-material/Place";
import { Link, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import CustomerDashboardNavigation from "../../components/layout/CustomerDashboardNavigation";

const AddressUpdater = () => {
  const [values, setValues] = useState({});
  const [isValuesLoaded, setIsValuesLoaded] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      getCompanyAddress(id)
        .then((response) => {
          const { data } = response;
          const { name, address1, address2, post_code, place, country } =
            data.attributes;
          setValues({
            id: data.id,
            name,
            address1,
            address2,
            post_code,
            place,
            country,
          });
          setIsValuesLoaded(true);
        })
        .catch((error) => {});
    }
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardPageHeader
        icon={Place}
        title="Edytuj adres"
        button={
          <Link to="/address">
            <Button
              color="primary"
              sx={{ bgcolor: "primary.light", px: "2rem" }}
            >
              Powrót
            </Button>
          </Link>
        }
        navigation={<CustomerDashboardNavigation />}
      />
      <AddressEditor values={values} isValuesLoaded={isValuesLoaded} />
    </DashboardLayout>
  );
};

export default AddressUpdater;
