import apiClient from "./api-client";

// returns id_customer and id_company from api call
export const getVerificationCustomer = async (token) => {
  let data = { token: token };
  let response = await apiClient.post(
    "/email-verification-customers",
    JSON.stringify(data)
  );
  if (response.data.data) {
    return [
      response.data.data.id,
      response.data.data.attributes.fk_company,
      response.data.data.attributes.claim,
    ];
  }
  throw new Error("Token jest nieważny, lub wygasł");
};

// sends b64encoded file
export const sendVerificationFile = async (
  id_customer,
  id_company,
  b64file
) => {
  let data = {
    id_customer: id_customer,
    id_company: id_company,
    base64_document: b64file,
  };
  return await apiClient.post("/register-documents", JSON.stringify(data));
};

export const sendInspectorPrices = async (values) => {
  return await apiClient.post(
    "/email-verification-inspectors",
    JSON.stringify(values)
  );
};
