import { LoadingButton } from "@mui/lab";
import { Card, TextField, Typography } from "@mui/material";
import FlexBox from "../../components/FlexBox";
import AppLayout from "../../components/layout/AppLayout";
import Navbar from "../../components/navbar/Navbar";
import * as yup from "yup";
import { useFormik } from "formik";
import { postForgotPassword } from "../../clients/reset-password";
import { handleApiResponse } from "../../utils/api/error-handling/apiErrorHandler";
import { useAppContext } from "../../contexts/app/AppContext";
import { Fragment, useState } from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

const ForgotPasswordPage = () => {
  const [isSent, setIsSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { dispatch } = useAppContext();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      postForgotPassword(values).then((response) => {
        handleApiResponse(response, dispatch, {
          on_success: () => {
            setIsSent(true);
            setIsSubmitting(false);
          },
          on_error: () => {
            setIsSubmitting(false);
          },
        });
      });
    },
  });

  return (
    <AppLayout navbar={<Navbar />}>
      <FlexBox
        sx={{
          minHeight: "40vh",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 2,
        }}
      >
        <Card
          sx={{
            padding: 2,
          }}
        >
          <FlexBox
            sx={{
              flexDirection: "column",
              gap: 1,
            }}
          >
            {!isSent && (
              <Fragment>
                <Typography>
                  Podaj email podany przy rejestracji konta. Reszta instrukcji
                  zostanie wysłana na podany adres.
                </Typography>
                <TextField
                  label="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={isSubmitting}
                  error={!!formik.touched.email && !!formik.errors.email}
                  helperText={!!formik.touched.email && formik.errors.email}
                />
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  onClick={formik.handleSubmit}
                >
                  Resetuj Hasło
                </LoadingButton>
              </Fragment>
            )}
            {isSent && (
              <Fragment>
                <Typography component="span" sx={{ display: "flex", gap: 1 }}>
                  <CheckCircleOutlinedIcon color="success" />
                  Dalsze instrukcje resetowania hasła zostały przesłane na
                  podany adres email. Sprawdź skrzynkę pocztową.
                </Typography>
              </Fragment>
            )}
          </FlexBox>
        </Card>
      </FlexBox>
    </AppLayout>
  );
};
const initialValues = {
  email: "",
};
const validationSchema = yup.object({
  email: yup
    .string()
    .email("Niepoprawny adres email")
    .required("Pole wymagane"),
});
export default ForgotPasswordPage;
