import { useState, useEffect } from "react";
import { Card, CardContent, Typography, Stack, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Logout = ({ pagecontent }) => {
  const [time_left, set_time_left] = useState(
    parseInt(pagecontent.data.attributes.timer)
  );

  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      set_time_left(time_left - 1);
    }, 1000);
    return () => clearInterval(timer);
  });

  if (time_left == 0) {
    navigate("/");
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {pagecontent.data.attributes.content}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {`Za ${time_left} sekund nastąpi przekierowanie na stronę główną`}
        </Typography>
        <Stack
          direction="row"
          justifyContent="center"
          spacing={2}
          sx={{ marginTop: 2 }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate("/");
            }}
          >
            Strona Główna
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate("/login");
            }}
          >
            Zaloguj się
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Logout;
