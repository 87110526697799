import apiClient from "./api-client";

export const getCompanyAddresses = async () => {
  const response = await apiClient.get("/company-address");
  return response.data;
};

export const getCompanyAddress = async (idAddress) => {
  const response = await apiClient.get("/company-address/" + idAddress);
  return response.data;
};

export const saveCompanyAddress = async (payload, idAddress) => {
  if (parseInt(idAddress)) {
    const response = await apiClient.patch(
      "/company-address/" + idAddress,
      JSON.stringify(payload)
    );
    return response.data;
  }

  const response = await apiClient.post(
    "/company-address",
    JSON.stringify(payload)
  );
  return response.data;
};
