import { Box, Typography } from "@mui/material";
import React from "react";

const ProductEquipment = (props) => {
  const { equipment } = props;

  return (
    <Box>
      <Box>
        {equipment.map((equipmentValue, idx) => (
          <Typography fontSize={16} key={`${equipmentValue}${idx}`}>
            {equipmentValue}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default ProductEquipment;
