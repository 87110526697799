import Template1 from "./templates/Template1";
import Template2 from "./templates/Template2";
import Template3 from "./templates/Template3";
import Template4 from "./templates/Template4";

const templateMap = {
  1: Template1,
  2: Template2,
  3: Template3,
  4: Template4,
};

// Route definistions where key => path in /about/[path]
export const routes = {
  about: {
    display_name: "O Nas",
  },
  contact: {
    display_name: "Kontakt",
  },
  ads_and_marketing: {
    display_name: "Reklama i marketing",
  },
  privacy_policy: {
    display_name: "Polityka Prywatności",
  },
  terms_and_conditions: {
    display_name: "Regulamin Machiner.pro",
  },
  downloads: {
    display_name: "Załączniki",
  },
};

export const makeDisplayElement = (pageContent) => {
  const RenderElement = templateMap[pageContent.template] || templateMap["1"];
  return <RenderElement pageContent={pageContent} />;
};

export const getNavigationElements = () => {
  return Object.entries(routes).map((element) => {
    return {
      href: `/about/${element[0]}`,
      display_name: element[1].display_name,
    };
  });
};
