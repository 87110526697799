import { Button, Card, Dialog, Typography } from "@mui/material";
import FlexBox from "../FlexBox";
import { publishProduct } from "../../clients/products-management";
import { useState } from "react";

const PublishDialog = ({ open, on_close, product_id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => {
    on_close();
  };

  return (
    <Dialog sx={{ zIndex: 2500 }} open={open} onClose={handleClose}>
      <Card
        sx={{
          padding: "10px",
        }}
      >
        <Typography variant="h6">
          Czy napewno chcesz opublikować ofertę?
        </Typography>
        <FlexBox sx={{ gap: "10px", marginTop: "10px" }}>
          <Button
            color="primary"
            variant="outlined"
            disabled={isLoading}
            onClick={async () => {
              setIsLoading(true);
              let res = await publishProduct(product_id);
              setIsLoading(false);
              on_close(res.data?.data);
            }}
            sx={{ flex: 1 }}
          >
            Tak
          </Button>
          <Button
            color="primary"
            variant="outlined"
            disabled={isLoading}
            onClick={() => {
              handleClose();
            }}
            sx={{ flex: 1 }}
          >
            Nie
          </Button>
        </FlexBox>
      </Card>
    </Dialog>
  );
};
export default PublishDialog;
