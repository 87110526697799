import { TextField, IconButton, Paper } from "@mui/material";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import QuestionRoot from "../QuestionRoot";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { postQuestionResults } from "../../../clients/inspection-results";

const QuestionInputDynamic = ({
  definition,
  handle_next,
  handle_prev,
  question_idx,
  disabled,
  inspection_id,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [shownFields, setShownFields] = useState([definition.steps.answers[0]]);
  const maxElements = definition.steps.answers.length;

  // for all shown fields create a yup shape
  const shape = {};
  for (let x = 0; x < shownFields.length; x++) {
    shape[shownFields[x].field] = Yup.string().required("Pole Wymagane");
  }
  // make all fields required
  const validationSchema = Yup.object().shape(shape);

  const initialValues = {};
  for (let x = 0; x < shownFields.length; x++) {
    initialValues[shownFields[x].field] = "";
  }

  const formik = useFormik({
    // if data is already present - aka this step was already done
    // set initial values to show data
    initialValues: initialValues,
    onSubmit: async (values) => {
      // if disabled - this step was already done navigate to next
      if (disabled) {
        handle_next();
        return;
      }
      setIsLoading(true);
      postQuestionResults(inspection_id, question_idx, values)
        .then((response) => {
          handle_next(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    validationSchema: validationSchema,
  });

  useEffect(() => {
    if (disabled) {
      let shown_fields_local = [];
      definition.steps.answers.forEach((elem) => {
        if (definition.result?.hasOwnProperty(elem.field)) {
          shown_fields_local = [...shown_fields_local, elem];
        }
      });
      setShownFields(shown_fields_local);
      let read_only_values = {};
      for (let x = 0; x < shown_fields_local.length; x++) {
        read_only_values[shown_fields_local[x].field] =
          definition.result?.[shown_fields_local[x].field] || "";
      }
      formik.setValues(read_only_values);
    } else {
      setShownFields([definition.steps.answers[0]]);
    }
  }, [definition]);

  return (
    <QuestionRoot
      question={definition.steps.question}
      question_group={definition.group_name}
      isLoading={isLoading}
      handle_prev={handle_prev}
      handle_next={formik.handleSubmit}
    >
      {shownFields.map((elem) => (
        <TextField
          key={elem.label}
          label={elem.label}
          value={formik.values[elem.field] || ""}
          onChange={formik.handleChange(elem.field)}
          onBlur={formik.handleBlur(elem.field)}
          error={formik.touched[elem.field] && !!formik.errors[elem.field]}
          helperText={formik.errors[elem.field]}
          mode="outlined"
          disabled={disabled || isLoading}
        />
      ))}

      {shownFields.length < maxElements && (
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            disabled={isLoading || disabled}
            onClick={() => {
              setShownFields((shownFields) => [
                ...shownFields,
                definition.steps.answers[shownFields.length],
              ]);
            }}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Paper>
      )}
    </QuestionRoot>
  );
};
export default QuestionInputDynamic;
