import FavoriteIconFilled from "@mui/icons-material/Favorite";
import {
  addFavouriteProduct,
  removeFavouriteProduct,
} from "../../clients/favorite-products";
import { handleApiResponse } from "../../utils/api/error-handling/apiErrorHandler";
import FavoriteIcon from "@mui/icons-material/FavoriteBorder";
import { Button, styled } from "@mui/material";
import { useAppContext } from "../../contexts/app/AppContext";
import { useState, useEffect } from "react";

const StyledButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  background: theme.palette.primary.main,
  "&:hover": {
    background: theme.palette.primary[400],
  },
}));

const FavouriteBox = ({ product_id }) => {
  const { state, dispatch } = useAppContext();

  const is_favourite = !!state.user?.favourites?.includes(product_id);
  // create local copy of the variable for faster changes is UI
  const [is_favourite_display, set_is_favourite_display] =
    useState(is_favourite);

  // on acual is_favourite change update local variable used for UI displaying
  useEffect(() => {
    set_is_favourite_display(is_favourite);
  }, [is_favourite]);

  return (
    <StyledButton
      variant="outlined"
      sx={{ p: "3px 8px", ml: "10px" }}
      onClick={() => {
        set_is_favourite_display(
          (is_favourite_display) => !is_favourite_display
        );
        if (is_favourite) {
          removeFavouriteProduct(product_id)
            .then((response) => {
              handleApiResponse(response, dispatch, {
                success_message: "Usunięto z ulubionych",
                on_success: () => {
                  dispatch({
                    type: "SET_FAVOURITES",
                    payload: response.data.data.attributes.favorite_products,
                  });
                },
              });
            })
            .catch((err) => {});
        } else {
          addFavouriteProduct(product_id)
            .then((response) => {
              handleApiResponse(response, dispatch, {
                success_message: "Dodano do ulubionych",
                on_success: () => {
                  dispatch({
                    type: "SET_FAVOURITES",
                    payload: response.data.data.attributes.favorite_products,
                  });
                },
              });
            })
            .catch((err) => {});
        }
      }}
    >
      {is_favourite_display ? (
        <FavoriteIconFilled sx={{ fontSize: "16px" }} />
      ) : (
        <FavoriteIcon sx={{ fontSize: "16px" }} />
      )}
    </StyledButton>
  );
};
export default FavouriteBox;
