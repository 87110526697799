import styled from "@emotion/styled";
import { Box, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useRef } from "react";
import { useState } from "react";
import FullscreenImageViewer from "../products/FullscreenImageViewer";

// Styles for image slider
const imgStyle = {
  cursor: "pointer",
  height: "100%",
  objectFit: "contain",
};

const ImageContainer = styled(Box)(() => ({
  display: "flex",
  height: "20rem",
  overflowY: "hidden",
  overflowX: "scroll",
  marginBottom: "20px",
  gap: "15px",
  scrollbarColor: "gray #0000",
}));

const ButtonScroll = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  color: "white",
  background: theme.palette.grey[600],
  borderRadius: 0,
  "&:hover": {
    background: theme.palette.grey[800],
  },
  "@media (max-width: 780px)": {
    display: "none",
  },
}));

const ImageSlider = (props) => {
  const { images } = props;
  const [fullScreenImageViewerOpen, setFullScreenImageViewerOpen] =
    useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const containerRef = useRef(null);

  const handleImageViewerClose = () => {
    setFullScreenImageViewerOpen(false);
  };

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <ButtonScroll
        onClick={(_) => {
          containerRef.current?.scroll({
            top: 0,
            left: containerRef.current.scrollLeft - 415,
            behavior: "smooth",
          });
        }}
      >
        <ArrowBackIosNewIcon />
      </ButtonScroll>
      <ButtonScroll
        onClick={(_) => {
          containerRef.current?.scroll({
            top: 0,
            left: containerRef.current.scrollLeft + 415,
            behavior: "smooth",
          });
        }}
        sx={{ right: 0, transform: "translate(50%, -50%) !important" }}
      >
        <ArrowForwardIosIcon />
      </ButtonScroll>
      <ImageContainer ref={containerRef}>
        {images.map((elem, idx) => (
          <img
            key={elem.s}
            style={imgStyle}
            onClick={() => {
              setCurrentImage(idx);
              setFullScreenImageViewerOpen(true);
            }}
            loading="lazy"
            src={elem.s}
          />
        ))}
      </ImageContainer>

      <FullscreenImageViewer
        onClose={handleImageViewerClose}
        images={images}
        open={fullScreenImageViewerOpen}
        currentImage={currentImage}
        setCurrentImage={setCurrentImage}
      />
    </Box>
  );
};
export default ImageSlider;
