export const snackbarInitialState = {
  open: false,
  type: "success",
  text: "Success",
};

export const snackbarReducer = (state, action) => {
  switch (action.type) {
    case "SHOW_SNACKBAR":
      return {
        ...state,
        open: true,
        ...action.payload,
      };
    case "HIDE_SNACKBAR":
      return { ...state, open: false };
    default:
      return { ...state };
  }
};
