import apiClient from "./api-client";

export const getFavoriteProducts = async () => {
  const response = await apiClient.get("/favorite-products");
  return response.data;
};

export const removeFavouriteProduct = async (id) => {
  const response = await apiClient.delete(`/favorite-products/${id}`);
  return response;
};

export const addFavouriteProduct = async (id) => {
  const response = await apiClient.post(
    `/favorite-products`,
    JSON.stringify({ id_product: id })
  );
  return response;
};
