import ProductCardList from "../products/ProductCard1List";
import ProductFilterCard from "../products/ProductFilterCard";
import Sidenav from "../sidenav/Sidenav";
import useWindowSize from "../../hooks/useWindowSize";
import FilterList from "@mui/icons-material/FilterList";
import { Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getSearchProducts } from "../../clients/search-products";
import ProductLoadingList from "../products/ProductLoadingList";
import { useFormik } from "formik";
import { useParams, useSearchParams } from "react-router-dom";

const Search = (props) => {
  const { filterRanges, categories, producers } = props;
  const [searchParams, _] = useSearchParams();

  const { category } = useParams();

  const width = useWindowSize();
  const isTablet = width < 1025;

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      search: null,
      category: category !== undefined ? category : null,
      id_producer: null,
      min_price: null,
      max_price: null,
      min_year: null,
      max_year: null,
      min_hour: null,
      max_hour: null,
      min_weight: null,
      max_weight: null,
      is_featured: false,
      has_inspection: false,
    },
    onSubmit: (values) => {
      setIsLoading(true);
      let new_values = { ...values };
      let url_filter_id_producer = searchParams.get("filter_id_producer");
      if (url_filter_id_producer) {
        new_values.id_producer = url_filter_id_producer;
      } else {
        new_values.id_producer = values.id_producer?.id || null;
      }
      getSearchProducts(new_values, currentPage)
        .then((response) => {
          const { data, meta } = response;
          setData(data);
          setPagination(meta);
          setIsLoading(false);
        })
        .catch((error) => {});
    },
  });

  useEffect(() => {
    let producer = producers.find(
      (producer) =>
        producer.id.toString() === searchParams.get("filter_id_producer")
    );
    if (producer !== undefined) {
      formik.setFieldValue("id_producer", {
        label: producer.attributes.name,
        id: producer.id,
      });
    }
    onSubmitForm();
  }, [category, searchParams]);

  const onSubmitForm = (...params) => {
    setCurrentPage(1);
    return formik.handleSubmit(...params);
  };

  const changePage = (e, page) => {
    setCurrentPage(page);
    formik.submitForm();
  };

  return (
    <Grid container spacing={3}>
      <Grid
        item
        md={3}
        xs={12}
        sx={{
          "@media only screen and (max-width: 1024px)": { display: "none" },
        }}
      >
        <ProductFilterCard
          formik={formik}
          onSubmitForm={onSubmitForm}
          filterRanges={filterRanges}
          categories={categories}
          producers={producers}
          isLading={isLoading}
        />
      </Grid>

      <Grid item md={9} xs={12}>
        {isTablet && (
          <Sidenav
            position="left"
            handle={
              <IconButton
                sx={{
                  marginLeft: "auto",
                  display: "block",
                }}
              >
                <FilterList fontSize="small" />
              </IconButton>
            }
          >
            <ProductFilterCard
              formik={formik}
              onSubmitForm={onSubmitForm}
              filterRanges={filterRanges}
              categories={categories}
              producers={producers}
              isLading={isLoading}
            />
          </Sidenav>
        )}
        {isLoading && <ProductLoadingList />}
        {!isLoading && (
          <ProductCardList
            products={data}
            pagination={pagination}
            changePage={changePage}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Search;
