import { Card, Skeleton, Typography } from "@mui/material";
import { H5 } from "../../components/Typography";
import { useEffect } from "react";
import { trackOrder } from "../../clients/orders";
import { useState } from "react";
import FlexBox from "../../components/FlexBox";
import Map from "../../components/orders/TransportMap.jsx";

const TransportTracking = ({ id_order }) => {
  const [trackingData, setTrackingData] = useState();
  const [markers, setMarkers] = useState();

  useEffect(() => {
    trackOrder(id_order)
      .then((response) => {
        let markers = [];
        let transport_from =
          response.data.data.relationships.transport.attributes.geopoint_from;
        markers = [
          ...markers,
          {
            pos: [transport_from.lat, transport_from.lon],
            label: "Punkt Startowy",
          },
        ];
        if (
          response.data.data.relationships?.transport?.relationships
            ?.port_info_from
        ) {
          let port_from =
            response.data.data.relationships.transport.relationships
              .port_info_from;
          markers = [
            ...markers,
            {
              pos: port_from.attributes.geopoint,
              label: "Port startowy",
            },
          ];
        }
        if (
          response.data.data.relationships?.transport?.relationships
            ?.port_info_to
        ) {
          let port_to =
            response.data.data.relationships.transport.relationships
              .port_info_to;
          markers = [
            ...markers,
            {
              pos: port_to.attributes.geopoint,
              label: "Port Końcowy",
            },
          ];
        }
        let transport_to =
          response.data.data.relationships.transport.attributes.geopoint_to;
        markers = [
          ...markers,
          {
            pos: [transport_to.lat, transport_to.lon],
            label: "Punkt Dostawy",
          },
        ];

        setMarkers(markers);
        setTrackingData(response.data.data);
      })
      .catch((e) => {
        console.warn(e);
      });
  }, []);

  if (!trackingData) {
    return (
      <Card
        sx={{
          width: "100%",
          mt: "30px",
          padding: "20px 30px",
        }}
      >
        <H5 mt={0} mb={2}>
          Śledzenie transportu
        </H5>
        <Skeleton variant="rectangular" height={150} />
      </Card>
    );
  }

  return (
    <Card
      sx={{
        width: "100%",
        mt: "30px",
        padding: "20px 30px",
      }}
    >
      <H5 mt={0} mb={2}>
        Śledzenie transportu
      </H5>
      <FlexBox justifyContent="space-between" alignItems="center" mb={1}>
        <Typography fontSize="14px" color="grey.600">
          Status Transportu
        </Typography>
        <Typography fontSize="14px" fontWeight="blod">
          {determineTransportStatus(trackingData.attributes.current_stage)}
        </Typography>
      </FlexBox>
      <FlexBox justifyContent="space-between" alignItems="center" mb={1}>
        <Typography fontSize="14px" color="grey.600">
          Typ transportu
        </Typography>
        <Typography fontSize="14px" fontWeight="blod">
          {trackingData.relationships.transport.attributes
            .transportation_mode === "truck"
            ? "Lądowy"
            : "Lądowy i morski"}
        </Typography>
      </FlexBox>
      <FlexBox justifyContent="space-between" alignItems="center" mb={1}>
        <Typography fontSize="14px" color="grey.600">
          Procent ukończenia trasy
        </Typography>
        <Typography fontSize="14px" fontWeight="blod">
          {trackingData.attributes.percentage_total_distance.toFixed(2)} %
        </Typography>
      </FlexBox>
      <FlexBox justifyContent="space-between" alignItems="center" mb={1}>
        <Typography fontSize="14px" color="grey.600">
          Całkowita trasa transportu
        </Typography>
        <Typography fontSize="14px" fontWeight="blod">
          {trackingData.relationships.transport.attributes.distance} km
        </Typography>
      </FlexBox>
      <FlexBox justifyContent="space-between" alignItems="center" mb={1}>
        <Typography fontSize="14px" color="grey.600">
          Przewidywany czas transportu
        </Typography>
        <Typography fontSize="14px" fontWeight="blod">
          {portionTime(
            trackingData.relationships.transport.attributes.transit_time * 3600
          )}
        </Typography>
      </FlexBox>
      <Map markers={markers} />
    </Card>
  );
};

const determineTransportStatus = (current_stage) => {
  if (current_stage[0] === "transport_position") return "Nie rozpoczęty";
  if (current_stage[current_stage.length - 1] === "transport_position")
    return "Zakończony";
  return "W trakcie realizacji";
};

const portionTime = (durationInSeconds) => {
  let days = durationInSeconds / 3600 / 24;
  let hours = durationInSeconds / 3600;
  let mins = (durationInSeconds % 3600) / 60;
  let secs = (mins * 60) % 60;

  days = Math.trunc(days);
  hours = Math.trunc(hours - days * 24);
  mins = Math.trunc(mins);

  if (!days && !hours && !mins && !secs) {
    return "None";
  }

  if (days) {
    return `${days} dni ${hours} hr`;
  } else if (hours) {
    if (mins) {
      return secs ? `${hours} hr ${mins} min` : `${hours} hr & ${mins} min`;
    } else {
      return secs ? `${hours} hr` : `${hours} hr`;
    }
  } else {
    if (mins) {
      return secs ? `${mins} min` : `${mins} min`;
    } else {
      return secs ? `${secs} sec` : `None`;
    }
  }
};

export default TransportTracking;
