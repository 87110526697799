import apiClient from "./api-client";

export const getCurrentInspections = async () => {
  const response = await apiClient.get("/inspector-inspections");

  return response;
};

export const getInspectionInfo = async (inspectionId) => {
  const response = await apiClient.get("/order-inspections/" + inspectionId);

  return response;
};
