import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";

export const notificationTypes = {
  1: {
    icon: <ShoppingBagOutlinedIcon />,
    color: "success.main",
    title: "Zamówienie",
  },
  2: {
    icon: <LocalOfferOutlinedIcon />,
    color: "primary.main",
    title: "Oferty",
  },
  3: {
    icon: <PaidOutlinedIcon />,
    color: "warning.main",
    title: "Płatności",
  },
  4: {
    icon: <ErrorOutlineIcon />,
    color: "warning.main",
    title: "Inspekcje",
  },
  default: {
    icon: <FactCheckOutlinedIcon />,
    color: "grey.900",
    title: "Informacja",
  },
};
