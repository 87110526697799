import { Button, Card, CardActions, Dialog, Typography } from "@mui/material";
import { useState } from "react";
import { deleteCustomerProfile } from "../../clients/customer-profile";
import { useAppContext } from "../../contexts/app/AppContext";
import handleLogout from "../../utils/handleLogout";
import { useNavigate } from "react-router-dom";

const AccountDeletion = () => {
  const { state, dispatch } = useAppContext();
  const { user } = state;
  const navigate = useNavigate();

  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] = useState(false);

  const handleDeleteAccountDialogOpen = () => {
    setDeleteAccountDialogOpen(true);
  };
  const handleDeleteAccountDialogClose = () => {
    setDeleteAccountDialogOpen(false);
  };

  const handleAccountDelete = () => {
    dispatch({
      type: "USER_LOADING",
      payload: {},
    });
    deleteCustomerProfile()
      .then(() => {
        handleLogout(dispatch, user);
        dispatch({
          type: "SHOW_SNACKBAR",
          payload: { text: "Konto zostało usunięte", type: "success" },
        });
        navigate("/");
      })
      .catch(() => {})
      .finally(() => {
        dispatch({
          type: "USER_LOADED",
          payload: {},
        });
      });
  };
  return (
    <Card
      sx={{
        padding: "10px",
      }}
    >
      <Button
        variant="contained"
        color="error"
        onClick={handleDeleteAccountDialogOpen}
        fullWidth
      >
        Usuń konto
      </Button>
      <Dialog
        sx={{
          zIndex: 3000,
        }}
        onClose={handleDeleteAccountDialogClose}
        open={deleteAccountDialogOpen}
      >
        <Card
          sx={{
            padding: "10px",
          }}
        >
          <Typography variant="h5">
            Czy na pewno chcesz usunąć konto?
          </Typography>
          <CardActions>
            <Button
              variant="outlined"
              color="error"
              sx={{ flex: 1 }}
              onClick={handleAccountDelete}
            >
              Tak
            </Button>
            <Button
              variant="outlined"
              color="primary"
              sx={{ flex: 1 }}
              onClick={handleDeleteAccountDialogClose}
            >
              Nie
            </Button>
          </CardActions>
        </Card>
      </Dialog>
    </Card>
  );
};
export default AccountDeletion;
