import BazarButton from "../BazarButton";
import CategoryMenu from "../categories/CategoryMenu";
import FlexBox from "../FlexBox";
import Category from "../icons/Category";
import Login from "../sessions/Login";
import { useAppContext } from "../../contexts/app/AppContext";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import PersonIcon from "@mui/icons-material/Person";
import PersonOutline from "@mui/icons-material/PersonOutline";
import FavoriteIcon from "@mui/icons-material/FavoriteBorder";
import {
  Box,
  Container,
  Dialog,
  IconButton,
  styled,
  useMediaQuery,
  Drawer,
  Badge,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { layoutConstant } from "../../utils/constants";
import clsx from "clsx";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import SearchBox from "../search-box/SearchBox"; // component props interface
import { useNavigate, useLocation } from "react-router-dom";
import { getRootCategories } from "../../clients/machine-categories";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationSidePanel from "../notifications/NotificationSidePanel";
import { genericErrorToSnackbar } from "../../utils/api/error-handling/apiErrorHandler";
import { getNotifications } from "../../clients/notifications";
import { getFavoriteProducts } from "../../clients/favorite-products";

// styled component
export const HeaderWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  height: layoutConstant.headerHeight,
  background: theme.palette.background.paper,
  transition: "height 250ms ease-in-out",
  [theme.breakpoints.down("sm")]: {
    height: layoutConstant.mobileHeaderHeight,
  },
}));

const Header = ({ isFixed, className }) => {
  const { state, dispatch } = useAppContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = state;
  const sidenavOpen = user.showNotifications;

  const [data, setData] = useState([]);
  useEffect(() => {
    getRootCategories()
      .then((response) => {
        const { data } = response;
        setData(data);
      })
      .catch((error) => {
        genericErrorToSnackbar(error, dispatch);
      });
  }, []);

  useEffect(() => {
    setDialogOpen(false);
  }, [user?.user]);

  // Load Messages from api and store in app reducer
  const getMessages = () => {
    getNotifications()
      .then((response) => {
        dispatch({
          type: "SET_NOTIFICATIONS",
          payload: { ...response.data.data },
        });
      })
      .catch((err) => {
        genericErrorToSnackbar(err, dispatch);
      });
  };
  // get messages and re-fetch every 3 minutes
  useEffect(() => {
    // fetch only if logged in and path not in excluded
    if (user?.user) {
      // do first fetch only if messages are null
      if (!user.messages) {
        getMessages();
      }
      // Set interval if not already set
      if (!user.messagesInterval) {
        // fetch messages every 3 minutes
        const interval = setInterval(getMessages, 1000 * 60 * 3);
        // set interval id to user, used for cleanup later
        dispatch({
          type: "SET_INTERVAL",
          payload: interval,
        });
      }
    } else {
      // if user is not logged in try clearing the message fetching interval
      clearInterval(user?.messagesInterval);
      dispatch({
        type: "SET_INTERVAL",
        payload: null,
      });
    }
  }, [user.user]);

  useEffect(() => {
    if (user?.user && !user.favourites) {
      getFavoriteProducts()
        .then((response) => {
          dispatch({
            type: "SET_FAVOURITES",
            payload: response.data.map((elem) => elem.id),
          });
        })
        .catch((err) => {});
    }
  }, [user.user]);

  const toggleDialog = () => setDialogOpen(!dialogOpen);

  const toggleSidenav = () => {
    if (user.showNotifications) {
      dispatch({
        type: "HIDE_NOTIFICATIONS",
        payload: {},
      });
    } else {
      dispatch({
        type: "SHOW_NOTIFICATIONS",
        payload: {},
      });
    }
  };

  const handleAccountClick = () => {
    user?.user ? navigate("/profile") : setDialogOpen(true);
  };

  return (
    <HeaderWrapper className={clsx(className)}>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <FlexBox
          alignItems="center"
          mr={2}
          minWidth="170px"
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        >
          <Link to="/">
            <img
              width={150}
              src="/assets/images/machiner-logo.svg"
              alt="logo"
            />
          </Link>

          {isFixed && (
            <CategoryMenu categories={data}>
              <FlexBox color="grey.600" alignItems="center" ml={2}>
                <BazarButton color="inherit">
                  <Category fontSize="small" color="inherit" />
                  <KeyboardArrowDown fontSize="small" color="inherit" />
                </BazarButton>
              </FlexBox>
            </CategoryMenu>
          )}
        </FlexBox>

        <FlexBox justifyContent="center" flex="1 1 0">
          <SearchBox />
        </FlexBox>

        <FlexBox
          alignItems="center"
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        >
          <Box>
            <Box
              component={IconButton}
              ml={2}
              p={1.25}
              bgcolor="grey.200"
              onClick={handleAccountClick}
            >
              {user?.user ? <PersonIcon /> : <PersonOutline />}
            </Box>
            {!user?.user && <Typography>Zaloguj się</Typography>}
            {!!user?.user && (
              <Typography
                sx={{
                  position: "absolute",
                }}
                ml={1}
              >
                Aktywny
              </Typography>
            )}
          </Box>
          {user?.user && (
            <Link to="/wish-list">
              <Badge
                badgeContent={state.user?.favourites?.length || 0}
                color="primary"
                overlap="circular"
              >
                <Box component={IconButton} ml={2} p={1.25} bgcolor="grey.200">
                  <FavoriteIcon />
                </Box>
              </Badge>
            </Link>
          )}
          {user?.user && (
            <Badge
              badgeContent={
                state.user?.messages?.attributes?.counters?.messages || 0
              }
              color="primary"
              overlap="circular"
            >
              <Box
                component={IconButton}
                ml={2}
                p={1.25}
                bgcolor="grey.200"
                onClick={toggleSidenav}
              >
                <NotificationsNoneIcon />
              </Box>
            </Badge>
          )}
        </FlexBox>

        <Dialog
          open={dialogOpen}
          fullWidth={isMobile}
          scroll="body"
          onClose={toggleDialog}
        >
          <Login />
        </Dialog>

        <Drawer
          open={sidenavOpen}
          anchor="right"
          onClose={toggleSidenav}
          PaperProps={{
            sx: { width: "380px", paddingRight: "5px" },
          }}
          sx={{ zIndex: 2500 }}
        >
          <NotificationSidePanel close_action={toggleSidenav} />
        </Drawer>
      </Container>
    </HeaderWrapper>
  );
};

export default Header;
