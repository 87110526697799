import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import { getMachineModel } from "../../clients/machine-models";
import { Fragment, useEffect } from "react";
import { useState } from "react";
import QuestionHandler from "./QuestionHandler";

const PublicInspectionPreview = ({ inspection }) => {
  const inspection_step_0 = inspection.relationships["0"].result.attributes;
  const { steps } = inspection.relationships;

  const steps_grouped = groupBy(steps, "group_name");

  const [machineModel, setMachineModel] = useState("");

  // set machine model and producer from model.id
  useEffect(() => {
    getMachineModel(inspection_step_0.fk_machine_model).then((response) => {
      const { producer } = response.data.data.relationships;
      const model = response.data.data;
      setMachineModel(`${producer.attributes.name} ${model.attributes.name}`);
    });
  }, []);

  const step_0_wanted_attributes = [
    ["Producent i Model", machineModel],
    ["VIN", inspection_step_0.vin],
    ["Numer Rejestracyjny", inspection_step_0.registration_number],
    ["Data pierwszej rejestracji", inspection_step_0.first_registration_date],
    ["Pojazd Zadłużony", inspection_step_0.is_pawned ? "Tak" : "Nie"],
    ["Pojazd Kradziony", inspection_step_0.is_stolen ? "Tak" : "Nie"],
    ["Dane Potwierdzone", inspection_step_0.is_confirmed ? "Tak" : "Nie"],
  ];

  return (
    <Box>
      <Typography variant="h6">Dane Pojazdu</Typography>
      <TableContainer component={Box}>
        <Table sx={{ width: "unset !important" }}>
          <TableBody>
            {step_0_wanted_attributes.map((row) => (
              <TableRow
                key={row[0]}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row[0]}
                </TableCell>
                <TableCell align="left">
                  <Typography fontWeight="bold">{row[1]}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {Object.entries(steps_grouped).map(([key, val]) => (
        <Fragment key={key}>
          <Typography mt={2} variant="h6">
            {key}
          </Typography>
          <TableContainer component={Box}>
            <Table sx={{ width: "unset !important" }}>
              <TableBody>
                {val.map((question) => (
                  <QuestionHandler key={question.name} question={question} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
      ))}
    </Box>
  );
};
const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
export default PublicInspectionPreview;
