import { Fragment, useEffect } from "react";
import {
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  TextField,
  MenuItem,
  Skeleton,
} from "@mui/material";
import { H3, H6 } from "../Typography";
import { getOffers } from "../../clients/offers";
import {
  genericErrorToSnackbar,
  handleApiResponse,
} from "../../utils/api/error-handling/apiErrorHandler";
import { useAppContext } from "../../contexts/app/AppContext";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { orderProduct } from "../../clients/orders";
import DoneIcon from "@mui/icons-material/Done";
import FlexBox from "../FlexBox";
import { getTransportPrice } from "../../clients/transport";
import { getCompanyAddresses } from "../../clients/company-address";

// Creates array of address menu items
const makeAddressesMenu = (companyAddresses) => {
  let data = [];
  companyAddresses?.data?.forEach((elem) => {
    data.push(
      <MenuItem key={`address${elem.id}`} value={elem.id.toString()}>
        {elem.attributes.name}
      </MenuItem>
    );
  });
  return data;
};

const OrderProduct = ({ product, pageContent }) => {
  const { state, dispatch } = useAppContext();
  const [acceptedOffer, setAcceptedOffer] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSendingData, setIsSendingData] = useState(false);
  const [isProductOrdered, setIsProductOrdered] = useState(false);
  const [orderTransport, setOrderTransport] = useState(false);
  const [companyAddresses, setCompanyAddresses] = useState();
  const [transportPrice, setTransportPrice] = useState(0);
  const [transportAddress, setTransportAddress] = useState(null);
  const [transportDate, setTransportDate] = useState(
    new Date(Date.now() + 12096e5).toISOString().split("T")[0]
  );
  const [isfetchingTransport, setIsFetchingTransport] = useState(false);
  const [transportId, setTransportId] = useState();

  // Fetch company addresses
  useEffect(() => {
    getCompanyAddresses()
      .then((response) => {
        setCompanyAddresses(response);
        setTransportAddress(response.data[0].id);
      })
      .catch(() => {});
  }, []);

  // Get offers from api, update on user state change
  useEffect(() => {
    if (state.user?.user) {
      getOffers()
        .then((response) => {
          // filter offers by accepted
          setAcceptedOffer(
            response.data.filter((elem) => elem.attributes.status === 2)[0]
          );
          setIsLoading(false);
        })
        .catch((err) => {
          genericErrorToSnackbar(err, dispatch);
        });
    }
  }, [state.user.user]);

  const calculateTransportPrice = () => {
    setIsFetchingTransport(true);
    setTransportPrice(0);
    let data = {
      id_product: product.id,
      id_address_to: transportAddress,
      date: transportDate,
    };
    getTransportPrice(data)
      .then((response) => {
        setTransportId(response.data.data.id);
        setTransportPrice(
          parseFloat(response.data.data.attributes.price.toFixed(2))
        );
      })
      .catch((err) => {
        setTransportId(null);
        setTransportPrice(0);
      })
      .finally(() => {
        setIsFetchingTransport(false);
      });
    return 0;
  };

  useEffect(() => {
    if (orderTransport && transportAddress && transportDate) {
      calculateTransportPrice();
    }
  }, [orderTransport, transportAddress, transportDate]);

  // Display nothing if data is still loading
  if (isLoading) {
    return <Fragment></Fragment>;
  }

  // On order button clicked
  const handleOrder = () => {
    setIsSendingData(true);
    orderProduct(product.id, transportId * orderTransport)
      .then((response) => {
        handleApiResponse(response, dispatch, {
          on_success: () => {
            setIsProductOrdered(true);
            setIsSendingData(false);
          },
        });
      })
      .catch((err) => {
        genericErrorToSnackbar(err, dispatch);
      });
  };

  return (
    <Fragment>
      {!isProductOrdered && (
        <Fragment>
          <Box>
            <H6 color="primary.400" mb={0.5} lineHeight="1">
              Twoja cena
            </H6>
            <H3 color="primary.main" mb={0.5} lineHeight="1">
              {acceptedOffer?.attributes?.price?.toFixed(2) ||
                product.attributes.price.toFixed(2)}{" "}
              PLN
            </H3>
          </Box>
          <FormGroup>
            <FormControlLabel
              label="Zamówienie Transportu"
              control={
                <Checkbox
                  checked={orderTransport}
                  onChange={(e) => {
                    setOrderTransport((old_state) => !old_state);
                  }}
                />
              }
            />
          </FormGroup>
          {orderTransport && (
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
              }}
            >
              <TextField
                name="transport_date"
                label="Data Transportu"
                onChange={(e) => {
                  setTransportDate(e.target.value);
                }}
                onBlur={(e) => {
                  e.target.type = "text";
                }}
                onFocus={(e) => {
                  e.target.type = "date";
                }}
                disabled={isfetchingTransport}
                value={transportDate || ""}
                variant="standard"
                InputProps={{
                  inputProps: {
                    min: new Date(Date.now() + 12096e5)
                      .toISOString()
                      .split("T")[0],
                  },
                }}
                size="small"
                placeholder=""
                fullWidth
                sx={{
                  flex: 1,
                  minWidth: "200px",
                }}
              />
              <TextField
                name="company_address"
                onChange={(e) => {
                  setTransportAddress(e.target.value);
                }}
                label="Adres Dostawy"
                value={transportAddress || ""}
                variant="standard"
                size="small"
                disabled={isfetchingTransport}
                select
                fullWidth
                sx={{
                  flex: 1,
                  minWidth: "200px",
                }}
              >
                {makeAddressesMenu(companyAddresses)}
              </TextField>
              <Box>
                <H6 color="primary.400" mb={0.5} lineHeight="1">
                  Cena Transportu
                </H6>
                <H3 color="primary.main" mb={0.5} lineHeight="1">
                  {isfetchingTransport ? (
                    <Skeleton variant="text" width="10ch" />
                  ) : transportPrice ? (
                    `${transportPrice} PLN`
                  ) : (
                    "Nie można obliczyć"
                  )}
                </H3>
              </Box>
            </Box>
          )}
          <Divider />
          <Box mt={1}>
            <H6 color="primary.400" mb={0.5} lineHeight="1">
              Cena Końcowa
            </H6>
            <H3 color="primary.main" mb={0.5} lineHeight="1">
              {(
                (acceptedOffer?.attributes?.price || product.attributes.price) +
                transportPrice * orderTransport
              ).toFixed(2)}{" "}
              PLN
            </H3>
          </Box>
          <LoadingButton
            loading={isSendingData}
            onClick={handleOrder}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ marginTop: "12px" }}
          >
            Zamów Maszynę
          </LoadingButton>
        </Fragment>
      )}

      {isProductOrdered && (
        <FlexBox alignItems="center" mb={1}>
          <DoneIcon fontSize="large" color="primary" />
          <Box ml={1}>
            <H6 color="grey.700" mb={0.5} sx={{ fontWeight: "normal" }}>
              {pageContent.product_ordered}
            </H6>
          </Box>
        </FlexBox>
      )}
    </Fragment>
  );
};
export default OrderProduct;
