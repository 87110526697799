import { CircularProgress, Skeleton } from "@mui/material";
import FlexBox from "../FlexBox";
import QuestionRoot from "./QuestionRoot";

const QuestionSkeleton = () => {
  return (
    <QuestionRoot question="Ładowanie">
      <FlexBox
        sx={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </FlexBox>
    </QuestionRoot>
  );
};
export default QuestionSkeleton;
