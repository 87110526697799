import { Button, Card, CircularProgress, Tooltip } from "@mui/material";
import { useState, useEffect, Fragment } from "react";
import { getProduct } from "../../clients/products-management";
import { useAppContext } from "../../contexts/app/AppContext";
import { genericErrorToSnackbar } from "../../utils/api/error-handling/apiErrorHandler.js";
import DeleteDialog from "./DeleteDialog";
import UnpublishDialog from "./UnpublishDialog";

const MachineSettings = ({ machineId }) => {
  const [machineData, setMachineData] = useState();
  const [unpublishDialogOpen, setUnpublishDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { dispatch } = useAppContext();

  // Get Product by id from url param
  useEffect(() => {
    if (machineId !== undefined) {
      getProduct(machineId)
        .then((response) => {
          setMachineData(response.data.data);
        })
        .catch((err) => {
          genericErrorToSnackbar(err, dispatch);
        });
    }
  }, [machineId]);

  const handleUnpublishDialogClose = (new_data = null) => {
    if (new_data) {
      setMachineData(new_data);
    }
    setUnpublishDialogOpen(false);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  if (!machineData) {
    return (
      <Card
        sx={{
          minHeight: "40vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Card>
    );
  }

  return (
    <Fragment>
      <Card
        sx={{
          display: "flex",
          padding: "10px",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Tooltip
          title={
            machineData?.attributes?.is_publish
              ? ""
              : "Ogłoszenie nie jest opublikowane"
          }
        >
          <span>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              disabled={!machineData?.attributes?.is_publish}
              onClick={() => {
                setUnpublishDialogOpen(true);
              }}
            >
              Deaktywacja Oferty
            </Button>
          </span>
        </Tooltip>
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            setDeleteDialogOpen(true);
          }}
        >
          Usuń ofertę
        </Button>
      </Card>
      <UnpublishDialog
        open={unpublishDialogOpen}
        on_close={handleUnpublishDialogClose}
        product_id={machineId}
      />
      <DeleteDialog
        open={deleteDialogOpen}
        on_close={handleDeleteDialogClose}
        product_id={machineId}
      />
    </Fragment>
  );
};
export default MachineSettings;
