import Header from "../components/header/Header";
import MobileCategoryNavStyle from "../components/mobile-category-nav/MobileCategoryNavStyle";
import MobileNavigationBar from "../components/mobile-navigation/MobileNavigationBar";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Category from "../components/icons/Category";
import { layoutConstant } from "../utils/constants";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getRootCategories } from "../clients/machine-categories";
import { useAppContext } from "../contexts/app/AppContext";
import { genericErrorToSnackbar } from "../utils/api/error-handling/apiErrorHandler.js";
import { useNavigate } from "react-router-dom";

const createCategory = (
  category,
  handle_click,
  open_map,
  children_categories,
  handle_child_click
) => {
  let children = [];
  children.push(
    createButton(
      // Add category itself as a button
      `Wszystkie ${category.attributes.name}`,
      category.attributes.slug,
      handle_child_click
    )
  );
  // Add all children as a button
  children_categories.forEach((elem) => {
    children.push(
      createButton(
        elem.attributes.name,
        elem.attributes.slug,
        handle_child_click
      )
    );
  });
  return (
    <Box key={category.attributes.name}>
      <ListItemButton
        onClick={() => {
          handle_click(category.attributes.name);
        }}
      >
        <ListItemText
          primary={
            <Typography fontWeight={600}>{category.attributes.name}</Typography>
          }
        />
        {open_map[category.attributes.name] ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        in={!!open_map[category.attributes.name]}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </Box>
  );
};

// Create a button with onClick => redirect to url
const createButton = (name, url, handleClick) => {
  return (
    <ListItemButton
      onClick={() => {
        handleClick(url);
      }}
      key={name}
      sx={{ pl: 4 }}
    >
      <ListItemText
        primary={<Typography fontWeight={600}>{name}</Typography>}
      />
    </ListItemButton>
  );
};

const MobileCategoryNav = () => {
  // 2 state variables for storing which categories should be open/collapsed
  // and categories fetched from api
  const [categories, setCategories] = useState([]);
  const [open_map, set_open_map] = useState({});

  const navigate = useNavigate();

  const { dispatch } = useAppContext();

  // fetch categories from api and set data to state variables
  useEffect(() => {
    getRootCategories()
      .then((response) => {
        const { data } = response;
        setCategories(data);
        data.forEach((elem) => {
          open_map[elem.attributes.name] = false;
        });
        set_open_map(open_map);
      })
      .catch((error) => {
        genericErrorToSnackbar(error, dispatch);
      });
  }, []);

  // Onclick change category open/collapsed var
  const handleClick = (category_name) => {
    open_map[category_name] = !open_map[category_name];
    set_open_map({ ...open_map });
  };

  // push router to url
  const redirectClick = (url) => {
    navigate(`/catalog/${url}`);
  };

  // Create array of collapseable categories via createCategory
  let category_objects = [];
  categories.forEach((elem) => {
    category_objects.push(
      createCategory(
        elem,
        handleClick,
        open_map,
        elem.relationships.subcategories,
        redirectClick
      )
    );
  });

  return (
    <MobileCategoryNavStyle>
      <Header className="header" />

      <Box>
        <List
          sx={{
            width: "100%",
            position: "fixed",
            left: 0,
            top: layoutConstant.mobileHeaderHeight,
            bottom: layoutConstant.mobileNavHeight,
            overflow: "auto",
            backgroundColor: "white",
          }}
          component="nav"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontWeight: 600,
              }}
            >
              <Category fontSize="small" />
              Maszyny
            </ListSubheader>
          }
        >
          {category_objects}
        </List>
      </Box>

      <MobileNavigationBar />
    </MobileCategoryNavStyle>
  );
};

export default MobileCategoryNav;
