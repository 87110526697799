import {
  Dialog,
  Box,
  IconButton,
  DialogContent,
  AppBar,
  Toolbar,
  Typography,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

// Styles for img and button components
const imgStyle = {
  maxWidth: "100%",
  maxHeight: "100%",
  height: "100%",
  objectFit: "contain",
};

const ActionButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  color: "white",
  background: theme.palette.grey[600],
  borderRadius: 0,
  "&:hover": {
    background: theme.palette.grey[800],
  },
}));

const FullscreenImageViewer = (props) => {
  const { open, onClose, images, currentImage, setCurrentImage } = props;

  const [pointerXstart, setPointerXstart] = useState(0);

  const imageLeft = () => {
    if (currentImage > 0) {
      setCurrentImage((currentImage) => currentImage - 1);
    }
  };
  const imageRight = () => {
    if (currentImage < images.length - 1) {
      setCurrentImage((currentImage) => currentImage + 1);
    }
  };

  return (
    <Dialog
      sx={{
        maxWidth: "100%",
        zIndex: 2000,
      }}
      fullScreen
      open={open}
      onClose={onClose}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {`${currentImage + 1}/${images.length}`}
          </Typography>
          <IconButton
            edge="end"
            sx={{
              color: "white",
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "@media only screen and (max-width: 1024px)": {
            padding: "5px",
            button: {
              transform: "translate(0, -50%) !important",
            },
          },
        }}
      >
        <Box
          onPointerDown={(e) => {
            setPointerXstart(e.clientX);
          }}
          onPointerUp={(e) => {
            let delta = e.clientX - pointerXstart;
            if (delta > 150) {
              imageLeft();
            } else if (delta < -150) {
              imageRight();
            }
          }}
          sx={{
            position: "relative",
            maxHeight: "100%",
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActionButton
            sx={{ left: 0 }}
            onClick={() => {
              imageLeft();
            }}
          >
            <ArrowBackIosNewIcon />
          </ActionButton>
          <ActionButton
            sx={{ right: 0, transform: "translate(50%, -50%) !important" }}
            onClick={() => {
              imageRight();
            }}
          >
            <ArrowForwardIosIcon />
          </ActionButton>
          <img style={imgStyle} loading="lazy" src={images[currentImage].xl} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default FullscreenImageViewer;
