import { Box, Button, Typography } from "@mui/material";
import { useAppContext } from "../../contexts/app/AppContext";
import FlexBox from "../FlexBox";
import { Link } from "react-router-dom";
import { createSearchParams } from "react-router-dom";
import navigateService from "../../utils/navigateService";

const ProductInspection = (props) => {
  const { product, slug, pageContent } = props;
  const { state } = useAppContext();
  const { user } = state;
  const make_inspection_box = () => {
    if (product.relationships?.hasOwnProperty("private_inspection")) {
      if (!product.relationships.private_inspection.attributes.is_finished) {
        return (
          <Box>
            <Typography fontSize={16} mb="10px">
              Inspekcja w trakcie realizacji
            </Typography>
          </Box>
        );
      }
      return (
        <Box>
          <Typography fontSize={16} mb="10px">
            Inspekcja Ukończona
          </Typography>
          <Link
            to={`/inspections/${product.relationships.private_inspection.id}`}
          >
            <Button variant="contained" color="primary">
              Podgląd Inspekcji
            </Button>
          </Link>
        </Box>
      );
    } else {
      return (
        <Box>
          <Typography fontSize={16} mb="10px">
            {pageContent.order_inspection}
          </Typography>
          <Link
            to={{
              pathname: "/inspections/order",
              search: `?${createSearchParams({
                machine_slug: slug,
              })}`,
            }}
          >
            <Button variant="contained" color="primary">
              Zleć inspekcję
            </Button>
          </Link>
        </Box>
      );
    }
  };

  return (
    <Box>
      {user?.user && make_inspection_box()}
      {!user?.user && (
        <FlexBox flexDirection="column">
          <Typography fontSize={16}>
            Inspekcje pozwalają poznać stan pojazdu przed jej kupnem. Zleć
            inspekcję jednemu ze zweryfikowanych inspektorów, który oceni stan
            pojazdu odpowiadając na serię pytań dotyczących stanu pojazdu.
            Następnie rezultaty inspekcji zostaną tobie przedstawione, dzięki
            czemu będziesz miał wgląd w kondycję maszyny.
          </Typography>
          <FlexBox mt={1}>
            <Typography
              fontSize={16}
              onClick={() => {
                navigateService.navigate("/login");
              }}
              component="a"
              color="primary"
              sx={{ cursor: "pointer" }}
            >
              Zaloguj się
            </Typography>
            <Typography fontSize={16}>&nbsp;aby zlecić inspekcję</Typography>
          </FlexBox>
        </FlexBox>
      )}
    </Box>
  );
};
export default ProductInspection;
