import BazarButton from "../BazarButton";
import BazarCard from "../BazarCard";
import CategoryMenu from "../categories/CategoryMenu";
import Category from "../icons/Category";
import { Paragraph } from "../Typography";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { Box, Container, styled } from "@mui/material";
import { useEffect, useState } from "react"; // component props interface
import { getRootCategories } from "../../clients/machine-categories";

// const common css style
const navLinkStyle = {
  cursor: "pointer",
  marginRight: "2rem",
  transition: "color 150ms ease-in-out",
  "&:hover": {
    color: "primary.main",
  },
  "&:last-child": {
    marginRight: "0",
  },
}; // style components

const ParentNav = styled(Box)(({ theme }) => ({
  "&:hover": {
    color: theme.palette.primary.main,
    "& > .parent-nav-item": {
      display: "block",
    },
  },
}));
const ParentNavItem = styled(Box)(() => ({
  display: "none",
  position: "absolute",
  top: 0,
  left: "100%",
  zIndex: 5,
}));
const NavBarWrapper = styled(BazarCard)(({ theme }) => ({
  display: "block",
  position: "relative",
  height: "60px",
  borderRadius: "0px",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
const InnerContainer = styled(Container)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%",
}));
const CategoryMenuButton = styled(BazarButton)(({ theme }) => ({
  width: "278px",
  height: "40px",
  px: "1rem",
  backgroundColor: theme.palette.grey[100],
}));

const Navbar = ({ navListOpen, hideCategories }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getRootCategories()
      .then((response) => {
        const { data } = response;
        setData(data);
      })
      .catch((error) => {});
  }, []);

  return (
    <NavBarWrapper elevation={2} hoverEffect={false}>
      {!hideCategories ? (
        <InnerContainer>
          <CategoryMenu open={navListOpen} categories={data}>
            <CategoryMenuButton variant="text">
              <Category fontSize="small" />
              <Paragraph
                fontWeight="600"
                textAlign="left"
                flex="1 1 0"
                ml={1.25}
                color="grey.600"
              >
                Maszyny
              </Paragraph>
              <ChevronRight className="dropdown-icon" fontSize="small" />
            </CategoryMenuButton>
          </CategoryMenu>
        </InnerContainer>
      ) : (
        <InnerContainer
          sx={{
            justifyContent: "flex-end",
          }}
        ></InnerContainer>
      )}
    </NavBarWrapper>
  );
};

export default Navbar;
