import React from "react";
import Search from "../../components/search/Search";
import NavbarLayout from "../../components/layout/NavbarLayout";
import { getFilterRanges } from "../../clients/search-products";
import { getRootCategories } from "../../clients/machine-categories";
import { getMachineProducers } from "../../clients/machine-producers";
import { useState, useEffect } from "react";
import { genericErrorToSnackbar } from "../../utils/api/error-handling/apiErrorHandler.js";
import { useAppContext } from "../../contexts/app/AppContext";
import { CircularProgress, Card } from "@mui/material";
import { fallback_filter_ranges } from ".";

const Shop = () => {
  const { dispatch } = useAppContext();
  const [filterRanges, setFilterRanges] = useState();
  const [categories, setCategories] = useState();
  const [producers, setProducers] = useState();

  useEffect(() => {
    getFilterRanges()
      .then((response) => {
        setFilterRanges(response.data.attributes);
      })
      .catch((err) => {
        setFilterRanges(fallback_filter_ranges);
      });
    getRootCategories()
      .then((response) => {
        setCategories(response.data);
      })
      .catch((err) => {
        genericErrorToSnackbar(err, dispatch);
      });
    getMachineProducers()
      .then((response) => {
        setProducers(response.data);
      })
      .catch((err) => {
        genericErrorToSnackbar(err, dispatch);
      });
  }, []);

  if (!filterRanges || !categories || !producers) {
    return (
      <NavbarLayout>
        <Card
          sx={{
            width: "100%",
            minHeight: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Card>
      </NavbarLayout>
    );
  }
  return (
    <NavbarLayout>
      <Search
        filterRanges={filterRanges}
        categories={categories}
        producers={producers}
      />
    </NavbarLayout>
  );
};
export default Shop;
