/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { H1, H2, H6 } from "../Typography";
import FlexBox from "../FlexBox";
import ProductOfferBox from "./ProductOfferBox";
import useWindowSize from "../../hooks/useWindowSize";

const ProductDetails = ({ product, pageContent }) => {
  // get viewport width, used for conditional rendering for mobile devices
  const width = useWindowSize();
  const isMobile = width < 600;

  return (
    <Box width="100%">
      <Grid container spacing={3} justifyContent="space-around">
        <Grid item md={8} xs={12} alignItems="center">
          <H1 mb={2}>{product.attributes.model_name}</H1>

          <FlexBox alignItems="center" mb={2}>
            <Box>Producent:</Box>
            <H6 ml={1}>{product.attributes.producer_name}</H6>
          </FlexBox>

          <FlexBox alignItems="center" mb={2}>
            {!isMobile && (
              <Table size="small" padding="none">
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "transparent",
                      borderRadius: "0",
                      boxShadow: "none",
                    }}
                  >
                    <TableCell>Rok</TableCell>
                    <TableCell>Waga</TableCell>
                    <TableCell>Roboczogodziny</TableCell>
                    <TableCell>Lokalizacja</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      backgroundColor: "transparent",
                      borderRadius: "0",
                      boxShadow: "none",
                    }}
                  >
                    <TableCell sx={{ fontWeight: "bold" }}>
                      {product.attributes.machine_year}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      {product.attributes.machine_weight} kg
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      {product.attributes.machine_hour}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      {product.attributes.localization_post_code}{" "}
                      {product.attributes.localization_place} /{" "}
                      {product.attributes.localization_country}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}

            {isMobile && (
              <Box>
                <Box>
                  <Box component="span">Rok: </Box>
                  <Box component="span" sx={{ fontWeight: "bold" }}>
                    {product.attributes.machine_year}
                  </Box>
                </Box>
                <Box>
                  <Box component="span">Waga: </Box>
                  <Box component="span" sx={{ fontWeight: "bold" }}>
                    {product.attributes.machine_weight} kg
                  </Box>
                </Box>
                <Box>
                  <Box component="span">Roboczogodziny: </Box>
                  <Box component="span" sx={{ fontWeight: "bold" }}>
                    {product.attributes.machine_hour}
                  </Box>
                </Box>
                <Box>
                  <Box component="span">Lokalizacja: </Box>
                  <Box component="span" sx={{ fontWeight: "bold" }}>
                    {product.attributes.localization_post_code}{" "}
                    {product.attributes.localization_place} /{" "}
                    {product.attributes.localization_country}
                  </Box>
                </Box>
              </Box>
            )}
          </FlexBox>

          <Box mb={2}>
            <H2 color="primary.main" mb={0.5} lineHeight="1">
              {product.attributes.price.toFixed(2)} PLN
            </H2>
            <H6>{pageContent?.price_info}</H6>
          </Box>
        </Grid>

        <Grid item md={4} xs={12} alignItems="center">
          <Box>
            <FlexBox>
              <ProductOfferBox product={product} pageContent={pageContent} />
            </FlexBox>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductDetails;
