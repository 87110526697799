import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import CustomerDashboardNavigation from "../../components/layout/CustomerDashboardNavigation";
import DashboardLayout from "../../components/layout/CustomerDashboardLayout";
import DashboardPageHeader from "../../components/layout/DashboardPageHeader";
import { useEffect, useState } from "react";
import { getInspectionInfo } from "../../clients/inspector-inspections";
import QuestionConfirmDetails from "../../components/inspection-question/question-types/QuestionConfirmDetails";
import QuestionHandler from "../../components/inspection-question/QuestionHandler";
import FinishInspection from "../../components/inspection-question/FinishInspection.jsx";
import QuestionSkeleton from "../../components/inspection-question/QuestionSkeleton.jsx";
import { useParams } from "react-router-dom";

const MakeInspection = () => {
  const { id } = useParams();

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [inspectionStep, setInspectionStep] = useState();

  // get data from api
  const fetchInspectionData = (id) => {
    setIsLoading(true);
    getInspectionInfo(id).then((response) => {
      const { data } = response.data;
      setData(data);
      if (data) {
        if (!data.attributes.is_finished) {
          // if results are null display step 0 - confirm machine details
          if (data.relationships["0"].result === null) {
            setInspectionStep(0);
          } else {
            let question_to_answer = data.relationships.steps.find(
              (elem) => elem.result === null
            );
            setInspectionStep(
              question_to_answer?.id_step || "finish-inspection"
            );
          }
        } else {
          setInspectionStep(0);
        }
      }
      setIsLoading(false);
    });
  };

  // on load fetch data
  useEffect(() => {
    if (id) {
      fetchInspectionData(id);
    }
  }, [id]);

  const findNext = (current_idx) => {
    const { steps } = data.relationships;
    let current_step = steps.findIndex((step) => step.id_step === current_idx);
    let next_step = steps[current_step + 1]?.id_step || "finish-inspection";
    return next_step;
  };

  const findPrevious = (current_idx) => {
    const { steps } = data.relationships;
    if (current_idx === "finish-inspection") {
      return steps[steps.length - 1].id_step;
    }
    let current_step = steps.findIndex((step) => step.id_step === current_idx);
    let previous_step = steps[current_step - 1]?.id_step || 0;
    return previous_step;
  };

  // on click navigate to next question and optionally refetch data
  const handleNext = (refetch = false) => {
    if (refetch) {
      fetchInspectionData(id);
    } else {
      setInspectionStep((inspectionStep) => findNext(inspectionStep));
    }
  };

  // on click navigate to previous question
  const handlePrev = () => {
    setInspectionStep((inspectionStep) => findPrevious(inspectionStep));
  };

  return (
    <DashboardLayout>
      <DashboardPageHeader
        icon={FactCheckOutlinedIcon}
        title="Inspekcja"
        navigation={<CustomerDashboardNavigation />}
      />

      {isLoading && <QuestionSkeleton />}
      {!!data && !isLoading && inspectionStep === 0 && (
        <QuestionConfirmDetails
          inspection_id={data.id}
          inspectionStep={inspectionStep}
          handle_next={handleNext}
          result_data={data?.relationships["0"]?.result?.attributes}
          product_id={data?.attributes.fk_product}
        />
      )}
      {!!data && !isLoading && inspectionStep > 0 && (
        <QuestionHandler
          step={data.relationships.steps.find(
            (elem) => elem.id_step === inspectionStep
          )}
          handle_next={handleNext}
          handle_prev={handlePrev}
          inspection_id={data.id}
        />
      )}
      {!!data && !isLoading && inspectionStep === "finish-inspection" && (
        <FinishInspection
          handle_prev={handlePrev}
          data={data}
          inspection_id={data.id}
        />
      )}
    </DashboardLayout>
  );
};
export default MakeInspection;
