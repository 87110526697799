import CustomerDashboardNavigation from "../layout/CustomerDashboardNavigation";
import DashboardPageHeader from "../layout/DashboardPageHeader";
import TableRow from "../TableRow";
import { H5 } from "../Typography";
import ShoppingBag from "@mui/icons-material/ShoppingBag";
import { Skeleton, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import OrderRow from "./OrderRow";
import { getCustomerOrderList } from "../../clients/orders";

const CustomerOrderList = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    getCustomerOrderList()
      .then((response) => {
        const { data } = response;
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {});
  }, []);

  return (
    <Fragment>
      <DashboardPageHeader
        title="Moje zamówienia"
        icon={ShoppingBag}
        navigation={<CustomerDashboardNavigation />}
      />

      <TableRow
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
          padding: "0px 18px",
          background: "none",
        }}
        elevation={0}
      >
        <H5
          flex="0 0 0 !important"
          color="grey.600"
          px={2.75}
          py={0.5}
          my={0}
        ></H5>
        <H5 color="grey.600" my="0px" mx={0.75} textAlign="left">
          Status
        </H5>
        <H5 color="grey.600" my="0px" mx={0.75} textAlign="left">
          Numer
        </H5>
        <H5 color="grey.600" my="0px" mx={0.75} textAlign="left">
          Data zamówienia
        </H5>
        <H5 color="grey.600" my="0px" mx={0.75} textAlign="left">
          Cena
        </H5>
        <H5 color="grey.600" my="0px" mx={0.75} textAlign="left">
          Transport
        </H5>
        <H5 color="grey.600" my="0px" mx={0.75} textAlign="left">
          Cena razem
        </H5>
        <H5
          flex="0 0 0 !important"
          color="grey.600"
          px={2.75}
          py={0.5}
          my={0}
        ></H5>
      </TableRow>

      {isLoading && (
        <TableRow sx={{ my: "1rem", padding: "6px 18px" }}>
          <Typography whiteSpace="pre" m={0.75} textAlign="left">
            <Skeleton variant="text" />
          </Typography>
          <Typography flex="1 1 260px !important" m={0.75} textAlign="left">
            <Skeleton variant="text" />
          </Typography>

          <Typography whiteSpace="pre" textAlign="right" color="grey.600">
            <Skeleton variant="text" />
          </Typography>
        </TableRow>
      )}
      {!isLoading && data.length === 0 && (
        <TableRow sx={{ my: "1rem", padding: "6px 18px" }}>
          <Typography whiteSpace="pre" m={0.75} textAlign="left">
            Brak zamówień
          </Typography>
        </TableRow>
      )}
      {data.map((row) => (
        <OrderRow key={row.id} item={row} />
      ))}
      {/*
      <FlexBox justifyContent="center" mt={5}>
        <Pagination count={5} variant="outlined" color="primary" onChange={data => {
      }} />
      </FlexBox>
      */}
    </Fragment>
  );
};

export default CustomerOrderList;
