import apiClient from "./api-client";
import { sortProductImages } from "../utils/utils";

export const getProducts = async () => {
  return apiClient.get("/products");
};

export const getProduct = async (id) => {
  const response = await apiClient.get(`/products/${id}`);
  response.data.data = sortProductImages(response.data.data);
  return response;
};

export const publishProduct = async (id) => {
  const response = await apiClient.put(`/products/${id}/publish`);
  response.data.data = sortProductImages(response.data.data);
  return response;
};

export const unpublishProduct = async (id) => {
  const response = await apiClient.put(`/products/${id}/unpublish`);
  response.data.data = sortProductImages(response.data.data);
  return response;
};

export const deleteProduct = (id) => {
  return apiClient.delete(`/products/${id}`);
};

export const updatePrice = (id, price) => {
  let data = {
    price: price,
  };
  return apiClient.put(`/products/${id}/change-price`, JSON.stringify(data));
};

// Returs status string and text color to be used
export const determineStatus = (is_publish, status, is_blocked) => {
  if (is_blocked) {
    return ["zablokowana", "error"];
  }
  switch (is_publish) {
    case 0:
      return ["nieaktywna", "warning.main"];
    case 1:
      switch (status) {
        case 0:
          return ["aktywna", "success.800"];
        case 1:
          return ["zarezerwowana", "text"];
        case 2:
          return ["sprzedana", "text"];
        case 5:
          return ["niedostepna", "error"];
      }
  }
  return ["Błąd", "error"];
};
