import AppLayout from "../../components/layout/AppLayout";
import Navbar from "../../components/navbar/Navbar";
import Downloads from "../../components/about/Downloads";

export const DownloadsPage = () => {
  return (
    <AppLayout navbar={<Navbar />}>
      <Downloads />
    </AppLayout>
  );
};
export default DownloadsPage;
