import { Box, TextField, Typography, Divider } from "@mui/material";
import FlexBox from "../FlexBox";
import { useFormik } from "formik";
import * as yup from "yup";
import { Fragment, useState } from "react";
import { sendHelpMessage } from "../../clients/help-contact";
import { useAppContext } from "../../contexts/app/AppContext";
import {
  genericErrorToSnackbar,
  handleApiResponse,
} from "../../utils/api/error-handling/apiErrorHandler";
import { LoadingButton } from "@mui/lab";
import ContactThankYouProduct from "./ContactThankYouProduct";

const ContactFormProduct = ({ initialValues }) => {
  const [response, setResponse] = useState();
  const [isSending, setIsSending] = useState(false);
  const { dispatch } = useAppContext();

  const handleFormSubmit = (values) => {
    setIsSending(true);
    sendHelpMessage(values)
      .then((response) => {
        handleApiResponse(response, dispatch, {
          on_success: () => {
            setResponse(response.data.data);
          },
        });
      })
      .catch((err) => {
        genericErrorToSnackbar(err, dispatch);
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      onSubmit: handleFormSubmit,
      validationSchema: formSchema,
    });

  return (
    <FlexBox
      sx={{
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {!!response ? (
        <ContactThankYouProduct name={response.attributes.name} />
      ) : (
        <Fragment>
          <Typography variant="h4">Formularz Kontaktowy</Typography>
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minWidth: "40vw",
              maxWidth: 1200,
            }}
          >
            <FlexBox gap="10px" flexWrap="wrap">
              <TextField
                name="name"
                onChange={handleChange}
                label="Imię i nazwisko"
                value={values.name || ""}
                onBlur={handleBlur}
                variant="outlined"
                size="small"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{
                  flex: 1,
                  minWidth: 200,
                }}
              />
              <TextField
                name="place"
                onChange={handleChange}
                label="Miejscowość"
                value={values.place || ""}
                onBlur={handleBlur}
                variant="outlined"
                size="small"
                error={!!touched.place && !!errors.place}
                helperText={touched.place && errors.place}
                sx={{
                  flex: 1,
                  minWidth: 200,
                }}
              />
            </FlexBox>
            <TextField
              name="email"
              onChange={handleChange}
              type="email"
              label="Email"
              value={values.email || ""}
              onBlur={handleBlur}
              variant="outlined"
              size="small"
              error={!!touched.email && !!errors.email}
              helperText={touched.email && errors.email}
            />
            <TextField
              name="phone_number"
              type="tel"
              onChange={handleChange}
              label="Numer Telefonu"
              value={values.phone_number || ""}
              onBlur={handleBlur}
              variant="outlined"
              size="small"
              error={!!touched.phone_number && !!errors.phone_number}
              helperText={touched.phone_number && errors.phone_number}
            />
            <TextField
              name="message"
              multiline={true}
              onChange={handleChange}
              label="Wiadomość"
              value={values.message || ""}
              onBlur={handleBlur}
              variant="outlined"
              size="small"
              error={!!touched.message && !!errors.message}
              helperText={touched.message && errors.message}
              minRows={4}
            />

            <Divider />

            <LoadingButton
              loading={isSending}
              color="primary"
              variant="contained"
              onClick={handleSubmit}
            >
              Wyślij
            </LoadingButton>
          </Box>
        </Fragment>
      )}
    </FlexBox>
  );
};
const formSchema = yup.object().shape({
  name: yup.string().required("Podanie imienia i nazwiska jest wymagane"),
  place: yup.string().required("Podanie miejscowości jest wymagane"),
  email: yup
    .string()
    .email("Proszę podać poprawny adres email")
    .required("Podanie adresu email jest wymagane"),
  phone_number: yup
    .string()
    .matches("^\\+?((\\d*)|( ))*$", "Proszę podać poprawny numer telefonu")
    .required("Podanie numeru telefonu jest wymagane"),
  message: yup.string().required("Wiadomość jest wymagana"),
});
export default ContactFormProduct;
