import FlexBox from "../../components/FlexBox";
import DashboardLayout from "../../components/layout/CustomerDashboardLayout";
import CustomerDashboardNavigation from "../../components/layout/CustomerDashboardNavigation";
import DashboardPageHeader from "../../components/layout/DashboardPageHeader";
import TableRow from "../../components/TableRow";
import PercentIcon from "@mui/icons-material/Percent";
import { Chip, IconButton, Typography } from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { getOffers, saveOffer } from "../../clients/offers";
import { H5 } from "../../components/Typography";
import { Box } from "@mui/system";
import { format } from "date-fns";
import { useAppContext } from "../../contexts/app/AppContext";
import { Link } from "react-router-dom";

const OfferList = (props) => {
  const { state } = useAppContext();
  const { user } = state;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getOffers()
      .then((response) => {
        const { data } = response;
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {});
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case 0:
        return "secondary";
      case 1:
        return "error";
      case 2:
        return "success";
      case 3:
        return "warning";
      case 4:
        return "success";
      case 5:
        return "success";

      default:
        return "";
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 0:
        return "W oczekiwaniu";
      case 1:
        return "Oferta odrzucona";
      case 2:
        return "Oferta zaakceptowana";
      case 3:
        return "Nieaktualna";
      case 4:
        return "Zamówienie";
      case 5:
        return "Zakończone";
      default:
        return "";
    }
  };

  const setOfferStatus = (idOffer, status) => {
    saveOffer({ status: status }, idOffer).then((response) => {
      setData(
        data.map((offer) => {
          if (offer.id === response.data.id) {
            return response.data;
          }
          return offer;
        })
      );
    });
  };

  return (
    <DashboardLayout>
      <DashboardPageHeader
        title="Oferty"
        icon={PercentIcon}
        navigation={<CustomerDashboardNavigation />}
      />

      {isLoading && (
        <TableRow sx={{ my: "1rem", padding: "6px 18px" }}>
          <Typography whiteSpace="pre" m={0.75} textAlign="left">
            <Skeleton variant="text" />
          </Typography>
          <Typography flex="1 1 260px !important" m={0.75} textAlign="left">
            <Skeleton variant="text" />
          </Typography>

          <Typography whiteSpace="pre" textAlign="right" color="grey.600">
            <Skeleton variant="text" />
          </Typography>
        </TableRow>
      )}

      {!isLoading && data.length === 0 && (
        <TableRow sx={{ my: "1rem", padding: "6px 18px" }}>
          <Typography whiteSpace="pre" m={0.75} textAlign="left">
            Brak ofert
          </Typography>
        </TableRow>
      )}

      {data.map((row) => (
        <TableRow sx={{ my: "1rem", p: "15px 18px" }} key={row.id}>
          <Box m={0.75}>
            <Chip
              size="small"
              label={getStatusLabel(row.attributes.status)}
              sx={{
                p: "0.25rem 0.5rem",
                fontSize: 12,
                color: !!getStatusColor(row.attributes.status)
                  ? `${getStatusColor(row.attributes.status)}.900`
                  : "inherit",
                backgroundColor: !!getStatusColor(row.attributes.status)
                  ? `${getStatusColor(row.attributes.status)}.100`
                  : "none",
              }}
            />
          </Box>
          <Typography className="pre" m={0.75} textAlign="left">
            {format(new Date(row.attributes.created_at), "dd.MM.yyyy")}
          </Typography>
          <H5 flex="1 1 250px !important" m={0.75} textAlign="left">
            {
              row.relationships.product.relationships.model.relationships
                .producer.attributes.name
            }{" "}
            / {row.relationships.product.relationships.model.attributes.name}
          </H5>
          <FlexBox alignItems="left" flexWrap="wrap">
            <Typography className="pre" textAlign="left">
              Aktualna cena: {row.relationships.product.attributes.price} PLN
            </Typography>
            <Typography className="pre" textAlign="left">
              Oferowana cena: {row.attributes.price} PLN
            </Typography>
          </FlexBox>
          <Typography whiteSpace="pre" textAlign="right" color="grey.600">
            {row.attributes.status === 2 &&
              row.attributes.fk_buyer === user?.user.id && (
                <Link
                  to={`/product/${row.relationships.product.attributes.slug}`}
                >
                  <IconButton>
                    <AddShoppingCartIcon color="success" />
                  </IconButton>
                </Link>
              )}
            {row.attributes.status === 0 &&
              row.attributes.fk_seller === user?.user.id && (
                <div>
                  <IconButton onClick={() => setOfferStatus(row.id, 1)}>
                    <ThumbDownIcon color="error" />
                  </IconButton>
                  <IconButton onClick={() => setOfferStatus(row.id, 2)}>
                    <ThumbUpAltIcon color="success" />
                  </IconButton>
                </div>
              )}
          </Typography>
        </TableRow>
      ))}
    </DashboardLayout>
  );
};

export default OfferList;
