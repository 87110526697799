import FlexBox from "../FlexBox";
import { H5, H6, Span } from "../Typography";
import {
  Autocomplete,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { SearchOutlinedIcon } from "../search-box/SearchBox";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { LoadingButton } from "@mui/lab";
import { useParams, useSearchParams, Link } from "react-router-dom";

const ProductFilterCard = ({
  formik,
  onSubmitForm,
  filterRanges,
  categories,
  producers,
  isLading,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { category } = useParams();

  const getCurrentRootCategory = () => {
    return categories?.find(
      (rootCategory) =>
        rootCategory.attributes.slug === category ||
        rootCategory?.relationships?.subcategories.find(
          (subCategory) => subCategory.attributes.slug === category
        ) !== undefined
    );
  };

  const currentRootCategory = getCurrentRootCategory();

  const handleChangeProducer = (e, new_value) => {
    formik.setFieldValue("id_producer", new_value);
    let query = {};
    if (category) {
      query.category = category;
    }
    if (new_value?.id) {
      query["filter_id_producer"] = new_value.id;
    }
    setSearchParams(query);
  };

  return (
    <Card sx={{ p: "18px 27px", overflow: "auto" }} elevation={1}>
      <form onSubmit={onSubmitForm}>
        <LoadingButton
          loading={isLading}
          loadingPosition="start"
          type="submit"
          variant="outlined"
          color="primary"
          startIcon={<SearchOutlinedIcon />}
          fullWidth
        >
          Szukaj maszyn
        </LoadingButton>

        <List sx={{ my: "20px" }}>
          {currentRootCategory !== undefined && (
            <ListItem
              sx={{ pt: "0px", px: "0" }}
              secondaryAction={
                <IconButton href={"/catalog"}>
                  <ClearIcon sx={{ width: "0.75em", height: "0.75" }} />
                </IconButton>
              }
            >
              <ListItemText primary={currentRootCategory.attributes.name} />
            </ListItem>
          )}

          {currentRootCategory !== undefined &&
            currentRootCategory.attributes.slug === category &&
            currentRootCategory?.relationships?.subcategories.map(
              (subCategory) => (
                <ListItem
                  key={subCategory.id.toString()}
                  sx={{ pt: "0px", px: "0" }}
                >
                  <ArrowForwardIosIcon
                    sx={{ width: "0.5em", height: "0.5", mr: "5px" }}
                  />
                  <ListItemText
                    primary={
                      <Link
                        to={"/catalog/" + subCategory.attributes.slug}
                        underline="none"
                      >
                        {subCategory.attributes.name}
                      </Link>
                    }
                  />
                </ListItem>
              )
            )}

          {currentRootCategory !== undefined &&
            currentRootCategory.attributes.slug !== category &&
            currentRootCategory?.relationships?.subcategories.map(
              (subCategory) =>
                subCategory.attributes.slug === category && (
                  <ListItem
                    key={subCategory.id.toString()}
                    sx={{ pt: "0px", px: "0" }}
                    secondaryAction={
                      <IconButton
                        href={"/catalog/" + currentRootCategory.attributes.slug}
                      >
                        <ClearIcon sx={{ width: "0.75em", height: "0.75" }} />
                      </IconButton>
                    }
                  >
                    <ArrowForwardIosIcon
                      sx={{ width: "0.5em", height: "0.5", mr: "5px" }}
                    />
                    <ListItemText primary={subCategory.attributes.name} />
                  </ListItem>
                )
            )}
        </List>

        <Divider sx={{ my: "1.5rem" }} />

        <H6 mb={2}>Producent</H6>
        <FlexBox justifyContent="space-between" alignItems="center">
          <Autocomplete
            name="id_producer"
            size="small"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={handleChangeProducer}
            value={formik.values.id_producer || null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={producers.map((producer) => ({
              label: producer.attributes.name,
              id: producer.id,
            }))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Producent"
                error={
                  !!formik.touched.id_producer && !!formik.errors.id_producer
                }
              />
            )}
          />
        </FlexBox>

        <Divider sx={{ my: "1.5rem" }} />

        <H6 mb={2}>Cena</H6>
        <FlexBox justifyContent="space-between" alignItems="center">
          <TextField
            name="min_price"
            placeholder={filterRanges.min_price.toString()}
            type="number"
            size="small"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.min_price || ""}
          />
          <H5 color="grey.600" px={1}>
            -
          </H5>
          <TextField
            name="max_price"
            placeholder={filterRanges.max_price.toString()}
            type="number"
            size="small"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.max_price || ""}
          />
        </FlexBox>

        <Divider sx={{ my: "1.5rem" }} />

        <H6 mb={2}>Rok produkcji</H6>
        <FlexBox justifyContent="space-between" alignItems="center">
          <TextField
            name="min_year"
            placeholder={filterRanges.min_year.toString()}
            type="number"
            size="small"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.min_year || ""}
          />
          <H5 color="grey.600" px={1}>
            -
          </H5>
          <TextField
            name="max_year"
            placeholder={filterRanges.max_year.toString()}
            type="number"
            size="small"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.max_year || ""}
          />
        </FlexBox>

        <Divider sx={{ my: "1.5rem" }} />

        <H6 mb={2}>Roboczogodziny</H6>
        <FlexBox justifyContent="space-between" alignItems="center">
          <TextField
            name="min_hour"
            placeholder={filterRanges.min_hour.toString()}
            type="number"
            size="small"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.min_hour || ""}
          />
          <H5 color="grey.600" px={1}>
            -
          </H5>
          <TextField
            name="max_hour"
            placeholder={filterRanges.max_hour.toString()}
            type="number"
            size="small"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.max_hour || ""}
          />
        </FlexBox>

        <Divider sx={{ my: "1.5rem" }} />

        <H6 mb={2}>Waga w kilogramach</H6>
        <FlexBox justifyContent="space-between" alignItems="center">
          <TextField
            name="min_weight"
            placeholder={filterRanges.min_weight.toString()}
            type="number"
            size="small"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.min_weight || ""}
          />
          <H5 color="grey.600" px={1}>
            -
          </H5>
          <TextField
            name="max_weight"
            placeholder={filterRanges.max_weight.toString()}
            type="number"
            size="small"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.max_weight || ""}
          />
        </FlexBox>

        <Divider sx={{ my: "1.5rem" }} />

        <FormControlLabel
          control={
            <Checkbox
              name="is_featured"
              size="small"
              color="secondary"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.is_featured || ""}
            />
          }
          label={<Span color="inherit">Promocja</Span>}
          sx={{ display: "flex" }}
          key={"is_featured"}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="has_inspection"
              size="small"
              color="secondary"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.has_inspection || ""}
            />
          }
          label={<Span color="inherit">Z inspekcją</Span>}
          sx={{ display: "flex" }}
          key={"with_inspection"}
        />

        <List>
          <ListItem
            sx={{ px: "0" }}
            secondaryAction={
              <IconButton>
                <ClearIcon sx={{ width: "0.75em", height: "0.75" }} />
              </IconButton>
            }
          >
            <ListItemText sx={{}} primary={"Wyczysc filtr"} />
          </ListItem>
        </List>
      </form>
    </Card>
  );
};

export default ProductFilterCard;
