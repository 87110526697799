import { Fragment, useEffect, useState } from "react";
import { postQuestionResults } from "../../../clients/inspection-results";
import QuestionRoot from "../QuestionRoot";
import { CircularProgress, Button, Divider, Paper } from "@mui/material";
import { toBase64 } from "../../../utils/utils";

const QuestionSingleChoice = ({
  definition,
  handle_next,
  handle_prev,
  question_idx,
  disabled,
  inspection_id,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [answer, setAnswer] = useState();
  const [image, setImage] = useState();
  const [isImageLoading, setIsImageLoading] = useState(false);

  // on load try to set values for view only mode
  // this happens when result is not empty
  useEffect(() => {
    if (disabled) {
      try {
        if (!!definition.result[definition.steps.field]) {
          let answer = { label: definition.result[definition.steps.field] };
          try {
            let field_image = definition.steps.answers.find(
              (elem) => elem.label === definition.result[definition.steps.field]
            ).field_image;
            if (field_image) {
              answer.field_image = true;
            }
            setImage({
              data: null,
              field: null,
              uri: definition.result[field_image].s,
            });
          } catch (e) {
            console.log(e);
          }

          setAnswer(answer);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      setAnswer(undefined);
      setImage(undefined);
    }
  }, [definition]);

  const handleSubmit = () => {
    setIsLoading(true);
    // if disabled just navigate to next page
    if (disabled) {
      handle_next();
      setIsLoading(false);
      return;
    }
    // else post results
    // check if answer is selected and if image is added (if required)
    if (!answer || (answer && answer.field_image && !image)) {
      setIsLoading(false);
      return;
    }
    postQuestionResults(
      inspection_id,
      question_idx,
      { [definition.steps.field]: answer.label },
      image
    )
      .then((response) => {
        handle_next(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <QuestionRoot
      question={definition.steps.question}
      question_group={definition.group_name}
      isLoading={isLoading}
      handle_prev={handle_prev}
      handle_next={handleSubmit}
    >
      {definition.steps.answers.map((elem) => (
        <Button
          disabled={disabled}
          key={elem.label}
          color="primary"
          variant={answer?.label === elem.label ? "contained" : "outlined"}
          onClick={() => {
            setAnswer(elem);
          }}
        >
          {elem.label}
        </Button>
      ))}
      <Divider />
      {answer?.field_image && (
        <Fragment>
          <input
            accept="image/*"
            capture="camcorder"
            id="icon-button-image"
            disabled={disabled}
            onChange={(e) => {
              if (!!e.target.files[0]) {
                setIsImageLoading(true);
                toBase64(e.target.files[0]).then((result) => {
                  setImage({
                    uri: URL.createObjectURL(e.target.files[0]),
                    field: answer?.field_image,
                    data: result,
                  });
                  setIsImageLoading(false);
                });
              }
            }}
            type="file"
            style={{ display: "none" }}
          />
          <label htmlFor="icon-button-image" style={{ width: "100%" }}>
            <Button
              color="primary"
              component="span"
              variant="outlined"
              disabled={disabled}
              sx={{
                display: "inline-flex",
                width: "100%",
              }}
            >
              {image ? "Zmień zdjęcie" : "Dodaj zdjęcie"}
            </Button>
          </label>
        </Fragment>
      )}
      {isImageLoading && answer?.field_image && <CircularProgress />}
      {!isImageLoading && image && answer?.field_image && (
        <Paper sx={{ padding: "10px", height: "300px", display: "flex" }}>
          <img
            src={image.uri}
            style={{
              display: "block",
              width: "auto",
              height: "100%",
              margin: "auto",
            }}
          />
        </Paper>
      )}
    </QuestionRoot>
  );
};
export default QuestionSingleChoice;
