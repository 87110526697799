import { Box, Typography } from "@mui/material";
import React from "react";

const ProductSpecification = (props) => {
  const { specification } = props;

  const renderSpecification = () => {
    const specificationList = [];
    for (const i in specification) {
      if (specification[i] !== null) {
        specificationList.push(
          <div key={i}>
            <Typography fontSize={16} component="a">
              {i}
            </Typography>
            :
            <Typography ml={2} fontSize={16} fontWeight={700} component="a">
              {specification[i]}
            </Typography>
          </div>
        );
      }
    }

    return specificationList;
  };

  return (
    <Box>
      <Box>{renderSpecification()}</Box>
    </Box>
  );
};

export default ProductSpecification;
