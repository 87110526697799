import { Dialog, IconButton } from "@mui/material";
import { useAppContext } from "../../contexts/app/AppContext";
import ContactFormProduct from "./ContactFormProduct";
import FlexBox from "../FlexBox";
import { Close } from "@mui/icons-material";

const ContactFormDialog = ({ open, onClose, product }) => {
  const { state } = useAppContext();
  const { user } = state;
  const inititalValues = {
    name: makeUserNameSurname(user),
    place: "",
    email: user?.user?.attributes?.email || "",
    phone_number: "",
    message: "",
    checkbox_required: false,
    fk_product: product.id,
    agreements: ["zapytanie_do_produktu"],
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      sx={{
        maxWidth: "100%",
        zIndex: 2000,
      }}
    >
      <FlexBox width="100%">
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </FlexBox>
      <ContactFormProduct pageContent={null} initialValues={inititalValues} />
    </Dialog>
  );
};
const makeUserNameSurname = (user) => {
  if (user?.user) {
    return (
      user.user.attributes.first_name + " " + user.user.attributes.last_name
    );
  }
  return "";
};
export default ContactFormDialog;
