import Card1 from "../Card1";
import { Button, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import DashboardLayout from "../layout/CustomerDashboardLayout";
import { saveCustomerPassword } from "../../clients/customer-profile";
import { useAppContext } from "../../contexts/app/AppContext";
import { useNavigate } from "react-router-dom";

const PasswordEditor = ({ values, onCloseHandler }) => {
  const { state, dispatch } = useAppContext();
  const navigate = useNavigate();

  const checkoutSchema = yup.object().shape({
    old_password: yup.string().required("required"),
    password: yup.string().required("required"),
    password_confirmation: yup.string().required("required"),
  });

  const passwordForm = useFormik({
    enableReinitialize: true,
    validationSchema: checkoutSchema,
    initialValues: values,
    onSubmit: (values) => {
      saveCustomerPassword(values, values.id)
        .then((response) => {
          const { data, errors, validations } = response;

          if (validations !== undefined) {
            validations.forEach((error) => {
              passwordForm.setFieldError(error.source, error.title);
            });
          }

          if (data !== undefined) {
            dispatch({
              type: "SHOW_SNACKBAR",
              payload: { text: "Hasło zostało zmienione", type: "success" },
            });
            // Allow to overrride default redirect
            if (onCloseHandler === undefined) {
              navigate("/profile");
            } else {
              onCloseHandler();
            }
          }

          passwordForm.setSubmitting(false);
        })
        .catch((error) => {
          dispatch({
            type: "SHOW_SNACKBAR",
            payload: { text: "Wystąpił błąd" + error, type: "error" },
          });
        });
    },
  });

  return (
    <div>
      <Card1>
        <h3>Zamiana hasła</h3>
        <form onSubmit={passwordForm.handleSubmit}>
          <Box mb={4}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  type="password"
                  name="old_password"
                  label="Stare hasło"
                  fullWidth
                  onBlur={passwordForm.handleBlur}
                  onChange={passwordForm.handleChange}
                  value={passwordForm.values.old_password || ""}
                  error={
                    !!passwordForm.touched.old_password &&
                    !!passwordForm.errors.old_password
                  }
                  helperText={
                    passwordForm.touched.old_password &&
                    passwordForm.errors.old_password
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Box mb={4}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  type="password"
                  name="password"
                  label="Nowe hasło"
                  fullWidth
                  onBlur={passwordForm.handleBlur}
                  onChange={passwordForm.handleChange}
                  value={passwordForm.values.password || ""}
                  error={
                    !!passwordForm.touched.password &&
                    !!passwordForm.errors.password
                  }
                  helperText={
                    passwordForm.touched.password &&
                    passwordForm.errors.password
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="password"
                  name="password_confirmation"
                  label="Potwierdzenie hasła"
                  fullWidth
                  onBlur={passwordForm.handleBlur}
                  onChange={passwordForm.handleChange}
                  value={passwordForm.values.password_confirmation || ""}
                  error={
                    !!passwordForm.touched.password_confirmation &&
                    !!passwordForm.errors.password_confirmation
                  }
                  helperText={
                    passwordForm.touched.password_confirmation &&
                    passwordForm.errors.password_confirmation
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={passwordForm.isSubmitting}
          >
            Zapisz
          </Button>
        </form>
      </Card1>
    </div>
  );
};
PasswordEditor.layout = DashboardLayout;
export default PasswordEditor;
