import BazarCard from "../../BazarCard";
import React from "react";
import CategoryMenuItem from "../CategoryMenuItem";
import StyledMegaMenu from "./StyledMegaMenu";

const MegaMenu2 = ({ data, idx }) => {
  return (
    <StyledMegaMenu
      sx={{
        top: idx * 40 || 0,
      }}
    >
      <BazarCard
        elevation={2}
        sx={{
          ml: "0.2rem",
          py: "0.5rem",
        }}
      >
        {data?.map((item) => (
          <CategoryMenuItem
            title={item.attributes.name}
            href={"/catalog/" + item.attributes.slug}
            caret={!!item.relationships?.subcategories}
            key={item.attributes.name}
          ></CategoryMenuItem>
        ))}
      </BazarCard>
    </StyledMegaMenu>
  );
};

export default MegaMenu2;
