import CustomerDashboardLayout from "../../components/layout/CustomerDashboardLayout";
import CustomerPaymentList from "../../components/payment/CustomerPaymentList";
import React from "react";

const PaymentList = () => {
  return (
    <CustomerDashboardLayout>
      <CustomerPaymentList />
    </CustomerDashboardLayout>
  );
};

export default PaymentList;
