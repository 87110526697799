import BazarButton from "../BazarButton";
import BazarTextField from "../BazarTextField";
import FlexBox from "../FlexBox";
import { H6 } from "../Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, Card, Divider, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import React, { useCallback, useState } from "react";
import * as yup from "yup";
import { useAppContext } from "../../contexts/app/AppContext";
import Cookies from "js-cookie";
import { getAccessToken } from "../../clients/access-tokens";
import { genericErrorToSnackbar } from "../../utils/api/error-handling/apiErrorHandler";

const fbStyle = {
  background: "#3B5998",
  color: "white",
};
const googleStyle = {
  background: "#4285F4",
  color: "white",
};
const StyledCard = styled(({ children, passwordVisibility, ...rest }) => (
  <Card {...rest}>{children}</Card>
))(({ theme, passwordVisibility }) => ({
  width: 500,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  ".content": {
    textAlign: "center",
    padding: "3rem 3.75rem 0px",
    [theme.breakpoints.down("xs")]: {
      padding: "1.5rem 1rem 0px",
    },
  },
  ".passwordEye": {
    color: passwordVisibility
      ? theme.palette.grey[600]
      : theme.palette.grey[400],
  },
  ".facebookButton": {
    marginBottom: 10,
    "&:hover": fbStyle,
    ...fbStyle,
  },
  ".googleButton": {
    "&:hover": googleStyle,
    ...googleStyle,
  },
  ".agreement": {
    marginTop: 12,
    marginBottom: 24,
  },
}));

const Login = (props) => {
  const { state, dispatch } = useAppContext();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const togglePasswordVisibility = useCallback(() => {
    setPasswordVisibility((visible) => !visible);
  }, []);

  const { on_success } = props;

  const handleFormSubmit = async (values) => {
    dispatch({
      type: "USER_LOADING",
      payload: {},
    });

    getAccessToken(JSON.stringify({ ...values }))
      .then((response) => {
        const { data } = response;
        Cookies.set("userAuth", data?.attributes?.token, { expires: 7 });
        window.localStorage.setItem("user", JSON.stringify({ ...data }));
        dispatch({
          type: "LOGIN_USER",
          payload: { ...data },
        });
        dispatch({
          type: "SHOW_SNACKBAR",
          payload: {
            text: "Witaj, " + data.attributes.first_name,
            type: "success",
          },
        });
        if (on_success) {
          on_success();
        }
      })
      .catch((error) => {
        if (error.response && error.response.data?.hasOwnProperty("errors")) {
          dispatch({
            type: "SHOW_SNACKBAR",
            payload: { text: error.response.data.errors.title, type: "error" },
          });
        } else {
          genericErrorToSnackbar(error, dispatch);
        }
        dispatch({
          type: "USER_LOADED",
          payload: {},
        });
      });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      onSubmit: handleFormSubmit,
      initialValues,
      validationSchema: formSchema,
    });

  return (
    <StyledCard elevation={3} passwordVisibility={passwordVisibility}>
      <form className="content" onSubmit={handleSubmit}>
        <BazarTextField
          mb={1.5}
          name="email"
          label="Email"
          placeholder="exmple@mail.com"
          variant="outlined"
          size="small"
          type="email"
          fullWidth
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email || ""}
          error={!!touched.email && !!errors.email}
          helperText={touched.email && errors.email}
        />

        <BazarTextField
          mb={2}
          name="password"
          label="Hasło"
          placeholder="*********"
          autoComplete="on"
          type={passwordVisibility ? "text" : "password"}
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            endAdornment: (
              <IconButton
                size="small"
                type="button"
                onClick={togglePasswordVisibility}
              >
                {passwordVisibility ? (
                  <Visibility className="passwordEye" fontSize="small" />
                ) : (
                  <VisibilityOff className="passwordEye" fontSize="small" />
                )}
              </IconButton>
            ),
          }}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.password || ""}
          error={!!touched.password && !!errors.password}
          helperText={touched.password && errors.password}
        />

        <BazarButton
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          sx={{
            mb: "1.65rem",
            height: 44,
          }}
        >
          Zaloguj się
        </BazarButton>

        <Box mb={2}>
          <Box width="200px" mx="auto">
            <Divider />
          </Box>

          <FlexBox justifyContent="center" mt={-1.625}>
            <Box color="grey.600" bgcolor="background.paper" px={2}>
              lub
            </Box>
          </FlexBox>
        </Box>

        <FlexBox justifyContent="center" alignItems="center" my="1.25rem">
          <Box>Nie posiadasz konta?</Box>
          <Link to="/signup">
            <H6 ml={1} borderBottom="1px solid" borderColor="grey.900">
              Zarejestruj się
            </H6>
          </Link>
        </FlexBox>
        <FlexBox justifyContent="center" alignItems="center" my="1.25rem">
          <Box>Zapomiałeś hasła?</Box>
          <Link to="/forgot-password">
            <H6 ml={1} borderBottom="1px solid" borderColor="grey.900">
              Resetowanie hasła
            </H6>
          </Link>
        </FlexBox>
      </form>
    </StyledCard>
  );
};

const initialValues = {
  email: "",
  password: "",
};
const formSchema = yup.object().shape({
  email: yup
    .string()
    .email("Niepoprawny adres email")
    .required("${path} jest wymagany"),
  password: yup.string().required("Hasło jest wymagane"),
});
export default Login;
