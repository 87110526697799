import apiClient from "./api-client";

export const getCustomerPaymentList = async () => {
  const response = await apiClient.get("/customer-payments");
  return response.data;
};

export const getCustomerPaymentDetails = async (id) => {
  const response = await apiClient.get("/customer-payments/" + id);
  return response.data;
};
