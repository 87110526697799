import {
  Dialog,
  DialogTitle,
  Box,
  Divider,
  Card,
  MenuItem,
  TextField,
  FormControl,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import React, { useState, useMemo } from "react";
import * as yup from "yup";
import { useAppContext } from "../../contexts/app/AppContext";
import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import BazarButton from "../BazarButton";
import FlexBox from "../FlexBox";
import { getCompanyAddresses } from "../../clients/company-address.js";
import { useDropzone } from "react-dropzone";
import AddressEditor from "../address/AddressEditor";
import PropTypes from "prop-types";
import Place from "@mui/icons-material/Place";
import LoadingButton from "@mui/lab/LoadingButton";
import apiClient from "../../clients/api-client.js";
import { toBase64 } from "../../utils/utils";
import LinearProgress from "@mui/material/LinearProgress";
import { getMachineModelsBelongingToProducer } from "../../clients/machine-models";
import { useNavigate } from "react-router-dom";
import update from "immutability-helper";
import ImageList from "./DndImageList";
import cuid from "cuid";

// Styles for image preview
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  marginTop: "10px",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

// Style for form container
const FormCard = styled(Card)(({ theme }) => ({
  width: "100%",
  padding: "1.5rem",
}));

// Dialog for adding a new address
function AddAddressDialog(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Place />
        Dodaj nowy adres
      </DialogTitle>
      <AddressEditor
        onCloseHandler={handleClose}
        values={{
          name: "",
          address1: "",
          address2: "",
          post_code: "",
          place: "",
          country: "Polska",
        }}
      />
    </Dialog>
  );
}
AddAddressDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

// Creates array of address menu items
const makeAddressesMenu = (companyAddresses) => {
  let data = [];
  companyAddresses?.data?.forEach((elem) => {
    data.push(
      <MenuItem key={`address${elem.id}`} value={elem.id.toString()}>
        {elem.attributes.name}
      </MenuItem>
    );
  });
  return data;
};

const AddMachine = ({ machineProducers }) => {
  const [companyAddresses, setCompanyAddresses] = React.useState();
  const [machineProducer, setMachineProducer] = useState();
  const [files, setFiles] = useState([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [sendProgress, setSendProgress] = React.useState(0);
  const [models, setModels] = useState([]);

  const { dispatch } = useAppContext();
  const navigate = useNavigate();

  function Dropzone(props) {
    const {
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      accept: {
        "image/*": [".jpeg", ".jpg", ".png"],
      },

      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              uri: URL.createObjectURL(file),
              id: cuid(),
            })
          )
        );
      },
    });

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject]
    );

    // const thumbs = files.map((file) => (
    //   <div style={thumb} key={file.name}>
    //     <div style={thumbInner}>
    //       <img src={file.preview} style={img} />
    //     </div>
    //   </div>
    // ));

    const thumbs = (
      <ImageList
        images={files}
        moveImage={moveImage}
        setImages={setFiles}
        onDragEnd={() => {}}
        makeImageOverride={(image, isDragging, ref) => {
          return (
            <Box
              className="file-item"
              ref={ref}
              key={image.id?.toString()}
              sx={{
                position: "relative",
                opacity: isDragging ? 0 : 1,
              }}
            >
              <div style={thumb} key={cuid()}>
                <div style={thumbInner}>
                  <img
                    alt={`img - ${image.id}`}
                    className="file-img"
                    style={img}
                    src={image.uri}
                  />
                </div>
              </div>
            </Box>
          );
        }}
      />
    );

    return (
      <section className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>Kliknij lub przeciągnij tutaj zdjęcia</p>
        </div>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </section>
    );
  }

  // Fetch company addresses
  React.useEffect(() => {
    getCompanyAddresses()
      .then((response) => {
        setCompanyAddresses(response);
      })
      .catch(() => {});
  }, []);

  React.useEffect(() => {
    if (machineProducer !== undefined && machineProducer !== null) {
      makeModelOptions(machineProducer);
    } else {
      setModels([]);
    }
  }, [machineProducer]);

  // Creates array of model menu items
  const makeModelOptions = (machineProducer) => {
    if (machineProducer !== undefined && machineProducer !== null) {
      getMachineModelsBelongingToProducer(machineProducer.id).then(
        (response) => {
          let data = [];
          response.data.forEach((model) => {
            data = [
              ...data,
              {
                label: model.attributes.name,
                id: model.id,
              },
            ];
          });
          setModels(data);
        }
      );
    }
  };

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    // Check for images
    if (files.length == 0) {
      dispatch({
        type: "SHOW_SNACKBAR",
        payload: {
          text: "Proszę dodać co najmniej jedno zdjęcie",
          type: "error",
        },
      });
      setIsLoading(false);
      return;
    }
    // Remap form values to db data
    const requestData = {
      fk_company_address: values.company_address,
      fk_machine_model: values.model.id,
      machine_year: values.machine_year,
      machine_hour: values.machine_hour,
      machine_weight: values.machine_weight,
      vin: values.vin,
      seller_price: values.price,
    };
    // conditionally add registration_number and first_registration_date fields
    if (values.registration_number) {
      requestData.registration_number = values.registration_number;
    }
    if (values.first_registration_date) {
      requestData.first_registration_date = values.first_registration_date;
    }
    // Create new product
    setSendProgress(5);
    const response = await apiClient.post(
      "/products",
      JSON.stringify(requestData)
    );

    if (response.status == 201) {
      // On success encode all images to b64
      let files_encoded = await Promise.all(
        files.map((elem) => toBase64(elem))
      );
      // send all images
      let idx = 1;
      for (const elem of files_encoded) {
        let image_data = {
          fk_product: response.data.data.id,
          image_order: idx,
          base64_image: elem,
        };
        await apiClient.post("/product-images", JSON.stringify(image_data));
        setSendProgress(
          (sendProgress) => sendProgress + (1 / files.length) * 100
        );
        idx++;
      }
      // await sending files and push to list view
      navigate(`/machines/${response.data.data.id}`);
    } else {
      // on err show err
      dispatch({
        type: "SHOW_SNACKBAR",
        payload: { text: "Błąd podczas przesyłania danych", type: "error" },
      });
      setIsLoading(false);
      return;
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema: formSchema,
  });

  const handleDialogkOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    // On dialog close refresh addresses list
    getCompanyAddresses()
      .then((response) => {
        setCompanyAddresses(response);
      })
      .catch(() => {});
    companyAddressesMenu = makeAddressesMenu(companyAddresses);
  };

  // on image dragged update
  const moveImage = (dragIndex, hoverIndex) => {
    // Get the dragged element
    const draggedImage = files[dragIndex];
    /*
        - copy the dragged image before hovered element (i.e., [hoverIndex, 0, draggedImage])
        - remove the previous reference of dragged element (i.e., [dragIndex, 1])
        - here we are using this update helper method from immutability-helper package
      */
    let new_images_order = update(files, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, draggedImage],
      ],
    });
    setFiles(new_images_order);
  };

  let companyAddressesMenu = makeAddressesMenu(companyAddresses);

  return (
    <FormCard className="form-card">
      <AddAddressDialog open={dialogOpen} onClose={handleDialogClose} />
      <FormControl fullWidth>
        <FlexBox
          flexWrap="wrap"
          alignItems="start"
          justifyContent="space-between"
          gap="10px"
          fullWidth
          sx={{
            marginBottom: "15px",
          }}
        >
          <Autocomplete
            name="producer"
            value={machineProducer || null}
            noOptionsText="Brak wyszukań"
            disabled={isLoading}
            sx={{ flex: 1 }}
            onChange={(e, newValue) => {
              setMachineProducer(newValue || null);
              setFieldValue("model", null);
            }}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            options={
              machineProducers?.data?.map((producer) => ({
                label: producer.attributes.name,
                id: producer.id,
              })) || []
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Producent"
                error={!!touched.model && !!errors.model}
              />
            )}
          />
          <Autocomplete
            name="model"
            value={values.model || null}
            sx={{ flex: 1 }}
            noOptionsText="Brak wyszukań"
            disabled={!models.length || isLoading}
            onChange={(e, new_value) => {
              setFieldValue("model", new_value);
            }}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            options={models}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Model"
                onBlur={handleBlur}
                error={!!touched.model && !!errors.model}
              />
            )}
          />
        </FlexBox>
        <FlexBox
          flexWrap="wrap"
          alignItems="start"
          justifyContent="space-between"
          gap="10px"
          fullWidth
          sx={{
            marginBottom: "15px",
          }}
        >
          <TextField
            name="machine_weight"
            label="Waga"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.machine_weight || ""}
            error={!!touched.machine_weight && !!errors.machine_weight}
            helperText={touched.machine_weight && errors.machine_weight}
            variant="outlined"
            size="small"
            disabled={isLoading}
            InputProps={{
              endAdornment: values.machine_weight ? (
                <InputAdornment position="end">kg</InputAdornment>
              ) : (
                ""
              ),
            }}
            sx={{
              flex: 1,
              minWidth: "200px",
            }}
          />
          <TextField
            name="machine_hour"
            label="Przepracowane godziny"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.machine_hour || ""}
            error={!!touched.machine_hour && !!errors.machine_hour}
            helperText={touched.machine_hour && errors.machine_hour}
            variant="outlined"
            size="small"
            disabled={isLoading}
            InputProps={{
              endAdornment: values.machine_hour ? (
                <InputAdornment position="end">h</InputAdornment>
              ) : (
                ""
              ),
            }}
            sx={{
              flex: 1,
              minWidth: "200px",
            }}
          />
        </FlexBox>
        <FlexBox
          flexWrap="wrap"
          alignItems="start"
          justifyContent="space-between"
          gap="10px"
          fullWidth
          sx={{
            marginBottom: "15px",
          }}
        >
          <TextField
            name="machine_year"
            label="Rok Produkcji"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.machine_year || ""}
            error={!!touched.machine_year && !!errors.machine_year}
            helperText={touched.machine_year && errors.machine_year}
            variant="outlined"
            size="small"
            disabled={isLoading}
            sx={{
              flex: 1,
              minWidth: "200px",
            }}
          />
          <TextField
            name="vin"
            label="VIN"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.vin || ""}
            error={!!touched.vin && !!errors.vin}
            helperText={touched.vin && errors.vin}
            variant="outlined"
            size="small"
            disabled={isLoading}
            sx={{
              flex: 1,
              minWidth: "200px",
            }}
          />
        </FlexBox>
        <TextField
          name="price"
          label="Cena"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.price || ""}
          error={!!touched.price && !!errors.price}
          helperText={touched.price && errors.price}
          variant="outlined"
          size="small"
          disabled={isLoading}
          InputProps={{
            endAdornment: values.price ? (
              <InputAdornment position="end">PLN</InputAdornment>
            ) : (
              ""
            ),
          }}
          sx={{
            flex: 1,
            minWidth: "200px",
            marginBottom: "15px",
          }}
        />
        <FlexBox
          flexWrap="wrap"
          alignItems="start"
          justifyContent="space-between"
          gap="10px"
          fullWidth
          sx={{
            marginBottom: "20px",
          }}
        >
          <TextField
            name="company_address"
            onChange={handleChange}
            label="Adres maszyny"
            value={values.company_address || ""}
            onBlur={handleBlur}
            variant="outlined"
            size="small"
            disabled={isLoading}
            error={!!touched.company_address && !!errors.company_address}
            helperText={touched.company_address && errors.company_address}
            select
            sx={{
              flex: 1,
              minWidth: "200px",
            }}
          >
            {companyAddressesMenu}
          </TextField>
          <Box
            sx={{
              margin: "auto",
            }}
          >
            <span>lub</span>
          </Box>
          <BazarButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
            onClick={handleDialogkOpen}
          >
            Dodaj Nowy Adres
          </BazarButton>
        </FlexBox>

        <Box>
          <Box width="100%" mx="auto">
            <Divider />
          </Box>

          <FlexBox justifyContent="center" mt={-1.625}>
            <Box color="grey.600" bgcolor="background.paper" px={2}>
              opcjonalne
            </Box>
          </FlexBox>
        </Box>

        <FlexBox
          flexWrap="wrap"
          alignItems="start"
          justifyContent="space-between"
          gap="10px"
          fullWidth
          sx={{
            marginTop: "10px",
            marginBottom: "20px",
          }}
        >
          <TextField
            name="registration_number"
            label="Numer rejestracyjny"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.registration_number || ""}
            error={
              !!touched.registration_number && !!errors.registration_number
            }
            helperText={
              touched.registration_number && errors.registration_number
            }
            variant="outlined"
            size="small"
            disabled={isLoading}
            sx={{
              flex: 1,
              minWidth: "200px",
            }}
          />
          <TextField
            name="first_registration_date"
            label="Data Pierwszej rejestracji"
            onChange={handleChange}
            onBlur={(e) => {
              e.target.type = "text";
              handleBlur(e);
            }}
            onFocus={(e) => {
              e.target.type = "date";
            }}
            disabled={isLoading}
            value={values.first_registration_date || ""}
            error={
              !!touched.first_registration_date &&
              !!errors.first_registration_date
            }
            helperText={
              touched.first_registration_date && errors.first_registration_date
            }
            variant="outlined"
            size="small"
            placeholder=""
            sx={{
              flex: 1,
              minWidth: "200px",
            }}
          />
        </FlexBox>

        <Box>
          <Box width="100%" mx="auto">
            <Divider />
          </Box>

          <FlexBox justifyContent="center" mt={-1.625}>
            <Box color="grey.600" bgcolor="background.paper" px={2}>
              Zdjęcia
            </Box>
          </FlexBox>
        </Box>

        <Dropzone disabled={isLoading} />

        <Box sx={{ width: "100%" }}>
          {!!sendProgress && (
            <LinearProgress variant="determinate" value={sendProgress} />
          )}
        </Box>

        <LoadingButton
          size="small"
          color="primary"
          onClick={handleSubmit}
          loading={isLoading}
          loadingPosition="center"
          variant="contained"
          sx={{
            height: 44,
            marginTop: "5px",
          }}
        >
          Dodaj Maszynę
        </LoadingButton>
      </FormControl>
    </FormCard>
  );
};

const initialValues = {
  producer: "",
  model: "",
  machine_weight: "",
  machine_hour: "",
  machine_year: "",
  registration_number: "",
  vin: "",
  price: "",
  first_registration_date: "",
  company_address: "",
};

// const validation messages
const required = "Pole wymagane";
const numeric_required = "Proszę podać wartość numeryczną";
const year_valid = "Proszę podać poprawny rok";
const integer = "Proszę podać liczbę całkowitą";

const formSchema = yup.object().shape(
  {
    model: yup.object().required(),
    machine_weight: yup
      .number()
      .typeError(numeric_required)
      .required(required)
      .integer(integer),
    machine_hour: yup
      .number()
      .typeError(numeric_required)
      .required(required)
      .integer(integer),
    machine_year: yup
      .number()
      .typeError(numeric_required)
      .min(1900, year_valid)
      .max(new Date().getFullYear() + 1, year_valid)
      .required(required),
    vin: yup.string().required(required),
    price: yup.number().typeError(numeric_required).required(required),
    company_address: yup.number().required(required),
    registration_number: yup.string().when("first_registration_date", {
      is: (first_registration_date) => first_registration_date !== undefined,
      then: yup
        .string()
        .required("Pole wymagane przy podaniu daty pierwszej rejestracji"),
    }),
    first_registration_date: yup.string().when("registration_number", {
      is: (registration_number) => registration_number !== undefined,
      then: yup
        .string()
        .required("Pole wymagane przy podaniu numeru rejestracyjnego"),
    }),
  },
  [["first_registration_date", "registration_number"]]
);

export default AddMachine;
