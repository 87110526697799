import React from "react";
import { Grid } from "@mui/material";
import ProductCardSkeleton from "../product-cards/ProductCardSkeleton";

const ProductLoadingList = () => {
  return (
    <div>
      <Grid container spacing={3}>
        {[1, 2, 3, 4, 5, 6].map((item, ind) => (
          <Grid item lg={4} sm={6} xs={12} key={ind}>
            <ProductCardSkeleton />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ProductLoadingList;
