import Footer from "../footer/Footer";
import Header from "../header/Header";
import MobileNavigationBar from "../mobile-navigation/MobileNavigationBar";
import Sticky from "../sticky/Sticky";
import Topbar from "../topbar/Topbar";
import React, { Fragment, useCallback, useState } from "react";
import { useAppContext } from "../../contexts/app/AppContext";
import { Alert, Snackbar } from "@mui/material";
import WithLoading from "../loading/WithLoading";

const AppLayout = ({ children, navbar, title = "machiner.pro" }) => {
  const { state, dispatch } = useAppContext();
  const { user } = state;
  const { snackbar } = state;
  const [isFixed, setIsFixed] = useState(false);
  const toggleIsFixed = useCallback((fixed) => {
    setIsFixed(fixed);
  }, []);

  const handleClose = () => {
    dispatch({
      type: "HIDE_SNACKBAR",
      payload: {},
    });
  };

  return (
    <Fragment>
      <header>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </header>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbar?.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackbar?.type}
          sx={{ width: "100%" }}
        >
          {snackbar?.text}
        </Alert>
      </Snackbar>

      <Topbar />

      <Sticky fixedOn={0} onSticky={toggleIsFixed}>
        <Header isFixed={isFixed} />
      </Sticky>

      {navbar && <div className="section-after-sticky">{navbar}</div>}
      {!navbar ? (
        <div className="section-after-sticky">{children}</div>
      ) : (
        children
      )}

      <MobileNavigationBar />
      <Footer />

      {state?.user?.loading && <WithLoading loading={state?.user?.loading} />}
    </Fragment>
  );
};

export default AppLayout;
