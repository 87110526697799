import { getPageContent } from "../clients/cms";
import AppLayout from "../components/layout/AppLayout";
import Navbar from "../components/navbar/Navbar";
import ContactForm from "../components/about/ContactForm";
import { useEffect, useState } from "react";
import { genericErrorToSnackbar } from "../utils/api/error-handling/apiErrorHandler.js";
import { useAppContext } from "../contexts/app/AppContext";
import LoadingCard from "../components/loading/LoadingCard";

const HelpPage = () => {
  const [pageContent, setPageContent] = useState();
  const { dispatch } = useAppContext();

  useEffect(() => {
    getPageContent("help-checkboxes")
      .then((response) => {
        setPageContent(response.data.attributes);
      })
      .catch((err) => {
        genericErrorToSnackbar(err, dispatch);
      });
  }, []);

  if (!pageContent) {
    return (
      <AppLayout navbar={<Navbar />}>
        <LoadingCard />
      </AppLayout>
    );
  }

  return (
    <AppLayout navbar={<Navbar />}>
      <ContactForm pageContent={pageContent} />
    </AppLayout>
  );
};
export default HelpPage;
