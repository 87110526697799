import Cookies from "js-cookie";

const handleLogout = (dispatch, user) => {
  if (user.user != null) {
    dispatch({
      type: "LOGOUT_USER",
      payload: {},
    });
    Cookies.remove("userAuth");
    window.localStorage.removeItem("user");
  }
};
export default handleLogout;
