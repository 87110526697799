import TableRow from "../TableRow";
import { H5 } from "../Typography";
import East from "@mui/icons-material/East";
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import React from "react"; // component props interface
import Order from "../icons/Order";
import OrderStatus from "./OrderStatus";
import Price from "../Price";

const OrderRow = ({ item }) => {
  return (
    <Link to={"/orders/" + item.id}>
      <TableRow
        sx={{
          my: "1rem",
          padding: "6px 18px",
        }}
      >
        <Typography
          textAlign="center"
          color="grey.600"
          sx={{
            flex: "0 0 0 !important",
            display: {
              xs: "none",
              md: "block",
            },
          }}
        >
          <Order name={item.attributes.action} />
        </Typography>
        <Box m={0.75}>
          <OrderStatus status={item.attributes.status} />
        </Box>
        <H5 m={0.75} textAlign="left">
          #{item.id}
        </H5>
        <Typography className="pre" m={0.75} textAlign="left">
          {new Date(item.attributes.order_date).toISOString().split("T")[0]}
        </Typography>
        <Box m={0.75}>
          <Price
            currency={"PLN"}
            value={item.attributes.price}
            fontWeight={"bold"}
          />
          <Price currency={"EUR"} value={item.attributes.price_euro} />
        </Box>
        <Typography m={0.75} textAlign="left">
          <Price currency={"PLN"} value={item.attributes.transport_price} />
        </Typography>
        <Typography m={0.75} textAlign="left" fontWeight={"bold"}>
          <Price
            currency={"PLN"}
            value={item.attributes.transport_price + item.attributes.price}
            fontWeight={"bold"}
          />
        </Typography>
        <Typography
          textAlign="center"
          color="grey.600"
          sx={{
            flex: "0 0 0 !important",
            display: {
              xs: "none",
              md: "block",
            },
          }}
        >
          <IconButton>
            <East fontSize="small" color="inherit" />
          </IconButton>
        </Typography>
      </TableRow>
    </Link>
  );
};

export default OrderRow;
