import { Button, CircularProgress, Paper } from "@mui/material";
import { useState, useEffect } from "react";
import QuestionRoot from "../QuestionRoot";
import { postQuestionVideo } from "../../../clients/inspection-results";

const QuestionVideo = ({
  definition,
  handle_next,
  handle_prev,
  question_idx,
  disabled,
  inspection_id,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [video, setVideo] = useState();

  useEffect(() => {
    if (disabled) {
      try {
        setVideo({
          uri: definition.result[definition.steps.field],
        });
      } catch (e) {}
    } else {
      setVideo(undefined);
    }
  }, [definition]);

  const handleSubmit = () => {
    setIsLoading(true);
    // if disabled just navigate to next page
    if (disabled) {
      handle_next();
      setIsLoading(false);
      return;
    }
    if (!video) {
      setIsLoading(false);
      return;
    }
    postQuestionVideo(
      inspection_id,
      question_idx,
      definition.steps.field,
      video.data
    )
      .then((response) => {
        handle_next(true);
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <QuestionRoot
      question={definition.steps.question}
      question_group={definition.group_name}
      isLoading={isLoading}
      handle_prev={handle_prev}
      handle_next={handleSubmit}
    >
      <input
        accept="video/*"
        capture="camcorder"
        id="icon-button-video"
        disabled={disabled}
        onChange={(e) => {
          if (!!e.target.files[0]) {
            setIsVideoLoading(true);
            setVideo({
              uri: URL.createObjectURL(e.target.files[0]),
              data: e.target.files[0],
            });
            setIsVideoLoading(false);
          }
        }}
        type="file"
        style={{ display: "none" }}
      />
      <label htmlFor="icon-button-video" style={{ width: "100%" }}>
        <Button
          color="primary"
          component="span"
          variant="outlined"
          disabled={disabled}
          sx={{
            display: "inline-flex",
            width: "100%",
          }}
        >
          Dodaj Video
        </Button>
      </label>

      {isVideoLoading && <CircularProgress />}

      {!isVideoLoading && video && (
        <Paper
          sx={{
            padding: "10px",
            height: "300px",
            display: "flex",
          }}
        >
          <video
            style={{
              display: "block",
              width: "auto",
              height: "100%",
              margin: "auto",
            }}
            src={video.uri}
            controls
          />
        </Paper>
      )}
    </QuestionRoot>
  );
};
export default QuestionVideo;
