export const showSnackbar = (config, dispatch) => {
  let conf = {
    text: config.text || "",
    type: config.type || "success",
  };
  dispatch({
    type: "SHOW_SNACKBAR",
    payload: { text: conf.text, type: conf.type },
  });
};
