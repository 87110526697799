import { SvgIcon } from "@mui/material";
import React from "react";

const User2 = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M6.65 7.5C6.65 9.21452 7.46395 10.7392 8.72162 11.7184C4.89924 13.0726 2.15 16.7186 2.15 21C2.15 21.47 2.53084 21.85 3 21.85H21C21.4692 21.85 21.85 21.47 21.85 21C21.85 16.7186 19.1008 13.0726 15.2784 11.7184C16.536 10.7392 17.35 9.21452 17.35 7.5C17.35 4.55053 14.9502 2.15 12 2.15C9.04976 2.15 6.65 4.55053 6.65 7.5ZM8.35 7.5C8.35 5.48748 9.98748 3.85 12 3.85C14.0125 3.85 15.65 5.48748 15.65 7.5C15.65 9.51252 14.0125 11.15 12 11.15C9.98748 11.15 8.35 9.51252 8.35 7.5ZM20.106 20.15H3.89395C4.32041 16.0538 7.79275 12.85 12 12.85C16.2072 12.85 19.6796 16.0538 20.106 20.15Z"
        stroke="#395589"
        strokeWidth="0.2"
      />
    </SvgIcon>
  );
};

export default User2;
