import { Card, CircularProgress } from "@mui/material";
import QuestionMultiChoice from "./question-types/QuestionMultiChoice";
import QuestionInputStatic from "./question-types/QuestionInputStatic";
import QuestionInputDynamic from "./question-types/QuestionInputDynamic";
import QuestionVideo from "./question-types/QuestionVideo";
import QuestionSingleChoice from "./question-types/QuestionSingleChoice";
import QuestionImage from "./question-types/QuestionImage";

const QuestionHandler = ({ step, handle_next, handle_prev, inspection_id }) => {
  if (!step) {
    return (
      <Card
        sx={{
          minHeight: "40vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Card>
    );
  }
  const disabled = step.result !== null;
  const question_idx = step.id_step;

  switch (step.step_type) {
    case "single-choice": {
      return (
        <QuestionSingleChoice
          definition={step}
          handle_next={handle_next}
          handle_prev={handle_prev}
          question_idx={question_idx}
          inspection_id={inspection_id}
          disabled={disabled}
        />
      );
    }
    case "multi-choice": {
      return (
        <QuestionMultiChoice
          definition={step}
          handle_next={handle_next}
          handle_prev={handle_prev}
          question_idx={question_idx}
          inspection_id={inspection_id}
          disabled={disabled}
        />
      );
    }
    case "input": {
      if (step.steps.type === "static") {
        return (
          <QuestionInputStatic
            definition={step}
            handle_next={handle_next}
            handle_prev={handle_prev}
            question_idx={question_idx}
            inspection_id={inspection_id}
            disabled={disabled}
          />
        );
      } else if (step.steps.type === "dynamic") {
        return (
          <QuestionInputDynamic
            definition={step}
            handle_next={handle_next}
            handle_prev={handle_prev}
            question_idx={question_idx}
            inspection_id={inspection_id}
            disabled={disabled}
          />
        );
      }
    }
    case "image": {
      return (
        <QuestionImage
          definition={step}
          handle_next={handle_next}
          handle_prev={handle_prev}
          question_idx={question_idx}
          inspection_id={inspection_id}
          disabled={disabled}
        />
      );
    }
    case "video": {
      return (
        <QuestionVideo
          definition={step}
          handle_next={handle_next}
          handle_prev={handle_prev}
          question_idx={question_idx}
          inspection_id={inspection_id}
          disabled={disabled}
        />
      );
    }
    default: {
      return "Błąd";
    }
  }
};
export default QuestionHandler;
