import { Typography } from "@mui/material";
import FlexBox from "../../FlexBox";
import ReactMarkdown from "react-markdown";
import { Card, useTheme } from "@mui/material";
import remarkGfm from "remark-gfm";

const Template4 = ({ pageContent }) => {
  const theme = useTheme();

  // parse paragraphs from pages api content
  // any key containing "paragraph" will be treated as markdown
  const paragraphs = Object.entries(pageContent).filter((elem) =>
    elem[0].includes("paragraph")
  );
  return (
    <FlexBox
      flexDirection="column"
      minHeight="60vh"
      alignItems="center"
      marginBottom="30px"
    >
      <FlexBox
        sx={{
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
          minWidth: "60%",
          maxWidth: "60%",
          padding: "5px",
          [theme.breakpoints.down("md")]: {
            minWidth: "95% !important",
            maxWidth: "unset !important",
          },
        }}
      >
        <Typography
          sx={{
            margin: "20px 0 20px 0",
          }}
          color="grey"
          variant="h4"
        >
          {pageContent.title}
        </Typography>
        {paragraphs.map((elem) => {
          return (
            <Card
              sx={{
                width: "60%",
                padding: "0 15px",
                marginRight: "auto",
                "&:nth-of-type(even)": {
                  marginLeft: "auto",
                  marginRight: "unset",
                },
                [theme.breakpoints.down("md")]: {
                  marginLeft: "unset !important",
                  marginRight: "unset !important",
                  width: "100%",
                },
              }}
              key={elem[0]}
            >
              <ReactMarkdown children={elem[1]} remarkPlugins={[remarkGfm]} />
            </Card>
          );
        })}
      </FlexBox>
    </FlexBox>
  );
};
export default Template4;
