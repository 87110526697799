import FlexBox from "../../components/FlexBox";
import AddMachine from "../../components/machines/AddMachine";
import React, { useEffect, useState } from "react";
import { getMachineProducers } from "../../clients/machine-producers.js";
import CustomerDashboardNavigation from "../../components/layout/CustomerDashboardNavigation";
import DashboardLayout from "../../components/layout/CustomerDashboardLayout";
import DashboardPageHeader from "../../components/layout/DashboardPageHeader";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import { genericErrorToSnackbar } from "../../utils/api/error-handling/apiErrorHandler.js";
import { useAppContext } from "../../contexts/app/AppContext";
import LoadingCard from "../../components/loading/LoadingCard";

const AddMachinePage = () => {
  const { dispatch } = useAppContext();
  const [machineProducers, setMachineProducers] = useState();

  useEffect(() => {
    getMachineProducers()
      .then((response) => {
        setMachineProducers(response);
      })
      .catch((err) => {
        genericErrorToSnackbar(err, dispatch);
      });
  }, []);

  if (!machineProducers) {
    return (
      <DashboardLayout>
        <DashboardPageHeader
          icon={AgricultureIcon}
          title="Dodaj maszynę"
          navigation={<CustomerDashboardNavigation />}
        />
        <LoadingCard />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardPageHeader
        icon={AgricultureIcon}
        title="Dodaj maszynę"
        navigation={<CustomerDashboardNavigation />}
      />
      <FlexBox flexDirection="column" justifyContent="center" width="100%">
        <AddMachine machineProducers={machineProducers} />
      </FlexBox>
    </DashboardLayout>
  );
};
export default AddMachinePage;
