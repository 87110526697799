import "nprogress/nprogress.css";
import nProgress from "nprogress";
import MuiTheme from "../theme/MuiTheme";
import "simplebar/dist/simplebar.min.css";
import OpenGraphTags from "../utils/OpenGraphTags";
import React, { Fragment, useEffect, useState } from "react";
import GoogleAnalytics from "../utils/GoogleAnalytics";
import { AppProvider } from "../contexts/app/AppContext";
import createEmotionCache from "../createEmotionCache.js";
import { CacheProvider } from "@emotion/react"; // Client-side cache, shared for the whole session of the user in the browser.
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import CookiesInformation from "./sessions/Cookies";

const clientSideEmotionCache = createEmotionCache();

const isTouchDevice = () => {
  if ("ontouchstart" in window) {
    return true;
  }
  return false;
};

const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;

nProgress.configure({
  showSpinner: false,
});

const App = ({ children, emotionCache = clientSideEmotionCache }) => {
  const [showCookieInformation, setShowCookieInformation] = useState(false);

  // On load set state var from localstorage
  useEffect(() => {
    setShowCookieInformation(!window.localStorage.getItem("cookies_accepted"));
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <header>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;900&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <GoogleAnalytics />
        <OpenGraphTags />
      </header>
      <AppProvider>
        <MuiTheme>
          <DndProvider backend={backendForDND}>
            <Fragment>{children}</Fragment>
            {showCookieInformation && (
              <CookiesInformation on_accept={setShowCookieInformation} />
            )}
          </DndProvider>
        </MuiTheme>
      </AppProvider>
    </CacheProvider>
  );
};
export default App;
