import apiClient from "./api-client";

export const getOffers = async () => {
  const response = await apiClient.get("/offers");
  return response.data;
};

export const saveOffer = async (payload, idOffer) => {
  if (parseInt(idOffer)) {
    const response = await apiClient.patch(
      "/offers/" + idOffer,
      JSON.stringify(payload)
    );
    return response.data;
  }

  const response = await apiClient.post("/offers", JSON.stringify(payload));
  return response.data;
};
