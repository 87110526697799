import { useEffect, useState } from "react";
import { postQuestionResults } from "../../../clients/inspection-results";
import QuestionRoot from "../QuestionRoot";
import { CircularProgress, Button, Paper } from "@mui/material";
import { toBase64 } from "../../../utils/utils";
import FlexBox from "../../FlexBox";

const QuestionImage = ({
  definition,
  handle_next,
  handle_prev,
  question_idx,
  disabled,
  inspection_id,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState();
  const [isImageLoading, setIsImageLoading] = useState(false);

  // on definistion update try to set values for view only mode
  // this happens when result is not empty
  useEffect(() => {
    if (disabled) {
      try {
        setImage({
          uri: definition.result[definition.steps.field].s,
        });
      } catch {}
    } else {
      setImage(undefined);
    }
  }, [definition]);

  const handleSubmit = () => {
    setIsLoading(true);
    // if disabled just navigate to next page
    if (disabled) {
      handle_next();
      setIsLoading(false);
      return;
    }
    // else post results
    // check if image is added
    if (!image) {
      setIsLoading(false);
      return;
    }
    postQuestionResults(inspection_id, question_idx, {
      [image.field]: image.data,
    })
      .then((response) => {
        handle_next(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <QuestionRoot
      question={definition.steps.question}
      question_group={definition.group_name}
      isLoading={isLoading}
      handle_prev={handle_prev}
      handle_next={handleSubmit}
    >
      <input
        accept="image/*"
        capture="camcorder"
        id="icon-button-image"
        disabled={disabled}
        onChange={(e) => {
          if (!!e.target.files[0]) {
            setIsImageLoading(true);
            toBase64(e.target.files[0]).then((result) => {
              setImage({
                uri: URL.createObjectURL(e.target.files[0]),
                field: definition.steps.field,
                data: result,
              });
              setIsImageLoading(false);
            });
          }
        }}
        type="file"
        style={{ display: "none" }}
      />
      <label htmlFor="icon-button-image" style={{ width: "100%" }}>
        <Button
          color="primary"
          component="span"
          variant="outlined"
          disabled={disabled}
          sx={{
            display: "inline-flex",
            width: "100%",
          }}
        >
          {image ? "Zmień zdjęcie" : "Dodaj zdjęcie"}
        </Button>
      </label>

      {isImageLoading && (
        <FlexBox
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </FlexBox>
      )}
      {!isImageLoading && image && (
        <Paper
          sx={{
            padding: "10px",
            height: "300px",
            display: "flex",
          }}
        >
          <img
            src={image.uri}
            style={{
              display: "block",
              width: "auto",
              height: "100%",
              margin: "auto",
            }}
          />
        </Paper>
      )}
    </QuestionRoot>
  );
};
export default QuestionImage;
