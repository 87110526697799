import { useEffect } from "react";
import { getInspectionDetails } from "../../clients/inspections";
import { genericErrorToSnackbar } from "../../utils/api/error-handling/apiErrorHandler";
import { useAppContext } from "../../contexts/app/AppContext";
import { useState } from "react";
import {
  Card,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  Box,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import FlexBox from "../FlexBox";
import { Link } from "react-router-dom";

export const InspectionDetails = (props) => {
  const { id } = props;
  const { dispatch } = useAppContext();
  const [inspectionData, setInspectionData] = useState();

  useEffect(() => {
    if (id !== undefined) {
      getInspectionDetails(id)
        .then((response) => {
          setInspectionData(response.data.data);
        })
        .catch((err) => {
          genericErrorToSnackbar(err, dispatch);
        });
    }
  }, [id]);

  if (!inspectionData) {
    return (
      <Card
        sx={{
          minHeight: "40vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Card>
    );
  }

  return (
    <Card>
      <FlexBox sx={{ flexDirection: "column", gap: "10px" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {inspection_wanted_attributes(inspectionData).map((elem) => (
                <TableRow
                  key={elem[1]}
                  sx={{
                    "&:first-of-type": { border: "none" },
                    borderTop: "2px solid",
                    borderColor: "grey.300",
                  }}
                >
                  <TableCell
                    sx={{ fontWeight: "bold" }}
                  >{`${elem[1]}:`}</TableCell>
                  <TableCell align="right">{`${elem[0] ? elem[0] : ""} ${
                    elem[0] && elem[2] ? elem[2] : ""
                  }`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {(inspectionData.attributes.is_done_by_inspector === 0 ||
          inspectionData.attributes.is_finished === 1) && (
          <Link to={`/make-inspection/${inspectionData.id}`}>
            <Box p="10px" width="100%">
              <Button fullWidth variant="outlined" color="primary">
                {inspectionData.attributes.is_finished
                  ? "Podgląd Inspekcji"
                  : "Inspekcja"}
              </Button>
            </Box>
          </Link>
        )}
      </FlexBox>
    </Card>
  );
};

const inspection_wanted_attributes = (inspectionData) => {
  return [
    [inspectionData.attributes.scheduled_date.split("T")[0], "Data Inspekcji"],
    [
      inspectionData.attributes.is_public ? "TAK" : "NIE",
      "Inspekcja Opublikowana",
    ],
    [
      inspectionData.attributes.is_refused ? "TAK" : "NIE",
      "Inspekcja Odrzucona",
    ],
    [
      inspectionData.attributes.is_finished ? "TAK" : "NIE",
      "Inspekcja Ukończona",
    ],
    [inspectionData.attributes.price, "Cena", "PLN"],
  ];
};
