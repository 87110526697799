import { useAppContext } from "../../contexts/app/AppContext";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { styled } from "@mui/material/styles";
import BazarButton from "../BazarButton";
import FlexBox from "../FlexBox";
import { useState } from "react";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { toBase64 } from "../../utils/utils";
import { Box, Button, Card, Input, Divider, Typography } from "@mui/material";
import { sendVerificationFile } from "../../clients/email-verification";
import { genericErrorToSnackbar } from "../../utils/api/error-handling/apiErrorHandler.js";

const StyledCard = styled(({ children, ...rest }) => (
  <Card {...rest}>{children}</Card>
))(({ theme }) => ({
  maxWidth: 800,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  ".content": {
    padding: "3rem 3.75rem 0px",
    [theme.breakpoints.down("xs")]: {
      padding: "1.5rem 1rem 0px",
    },
  },
  ".btn": {
    marginBottom: 15,
  },
}));

const VerifyBuyerSeller = (props) => {
  const { id_customer, id_company } = props;

  const { state, dispatch } = useAppContext();
  const navigate = useNavigate();

  const [file1, setFile1] = useState();
  const [file2, setFile2] = useState();
  const [file3, setFile3] = useState();

  const handleFormSubmit = async () => {
    dispatch({
      type: "USER_LOADING",
      payload: {},
    });
    if (file1 == undefined || file2 == undefined || file3 == undefined) {
      dispatch({
        type: "SHOW_SNACKBAR",
        payload: {
          text: "Pola faktura1, faktura2, faktura3 są wymagane",
          type: "error",
        },
      });
      dispatch({
        type: "USER_LOADED",
        payload: {},
      });
      return;
    }
    let files_encoded = await Promise.all([
      toBase64(file1),
      toBase64(file2),
      toBase64(file3),
    ]);

    // Check if any of the selected files are the same
    // Long strings comparisons are faster than hashing and comparing
    if (
      files_encoded[0] === files_encoded[1] ||
      files_encoded[1] === files_encoded[2] ||
      files_encoded[0] === files_encoded[2]
    ) {
      dispatch({
        type: "SHOW_SNACKBAR",
        payload: { text: "Proszę przesłać 3 różne pliki", type: "error" },
      });
      dispatch({
        type: "USER_LOADED",
        payload: {},
      });
      return;
    }

    let success_counter = 0;
    files_encoded.forEach((elem) => {
      sendVerificationFile(id_customer, id_company, elem)
        .then((response) => {
          if (response.data.hasOwnProperty("data")) {
            // success
            success_counter += 1;
            if (success_counter == 3) {
              dispatch({
                type: "USER_LOADED",
                payload: {},
              });
              navigate("/auth/welcome2");
            }
          } else {
            dispatch({
              type: "SHOW_SNACKBAR",
              payload: {
                text: "Wystąpił błąd przy wysyłaniu plików",
                type: "error",
              },
            });
            dispatch({
              type: "USER_LOADED",
              payload: {},
            });
            return;
          }
        })
        .catch((error) => {
          genericErrorToSnackbar(error, dispatch);
          dispatch({
            type: "USER_LOADED",
            payload: {},
          });
          return;
        });
    });
  };

  const { handleSubmit } = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema: formSchema,
  });

  return (
    <StyledCard elevation={3}>
      <Box
        sx={{
          padding: 2,
        }}
      >
        <Typography variant="body1" fontWeight={600} textAlign="justify">
          Machiner.pro weryfikuje wiarygodność podawanych informacji i podmioty
          aktywnie handlujące maszynami. Dodaj 3 ostatnie faktury na podstawie,
          których twoja firma dokonała ostatnio transakcji zakupu lub sprzedaży
          maszyn ( dane kontrahenta mogą być zamazane ) byśmy wiedzieli że
          jesteś profesjonalistą zawodowym.
        </Typography>
      </Box>
      <form className="content" onSubmit={handleSubmit}>
        <FlexBox
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            fontWeight: "bold",
          }}
        >
          Faktura 1
        </FlexBox>

        <Button
          variant="contained"
          component="label"
          fullWidth
          className="btn"
          startIcon={<InsertDriveFileIcon />}
        >
          <Input
            multiple
            type="file"
            name="file1"
            inputProps={{ accept: "application/pdf" }}
            sx={{
              display: "none",
            }}
            onChange={(e) => {
              setFile1(e.target.files[0]);
            }}
          />
          {file1 == undefined ? "Wybierz" : file1.name}
        </Button>

        <FlexBox
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            fontWeight: "bold",
          }}
        >
          Faktura 2
        </FlexBox>

        <Button
          variant="contained"
          component="label"
          fullWidth
          className="btn"
          startIcon={<InsertDriveFileIcon />}
        >
          <Input
            multiple
            type="file"
            name="file2"
            inputProps={{ accept: "application/pdf" }}
            sx={{
              display: "none",
            }}
            onChange={(e) => {
              setFile2(e.target.files[0]);
            }}
          />
          {file2 == undefined ? "Wybierz" : file2.name}
        </Button>

        <FlexBox
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            fontWeight: "bold",
          }}
        >
          Faktura 3
        </FlexBox>

        <Button
          variant="contained"
          component="label"
          fullWidth
          className="btn"
          startIcon={<InsertDriveFileIcon />}
        >
          <Input
            multiple
            type="file"
            name="file3"
            inputProps={{ accept: "application/pdf" }}
            sx={{
              display: "none",
            }}
            onChange={(e) => {
              setFile3(e.target.files[0]);
            }}
          />
          {file3 == undefined ? "Wybierz" : file3.name}
        </Button>

        <Box mb={2} mt={3.3}>
          <Box width="200px" mx="auto">
            <Divider />
          </Box>
        </Box>

        <BazarButton
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          sx={{
            height: 44,
            marginBottom: 5,
          }}
        >
          Utwórz Konto
        </BazarButton>
      </form>
    </StyledCard>
  );
};
const initialValues = {
  file1: null,
  file2: null,
  file3: null,
};

// Form verification
const formSchema = yup.object().shape({});
export default VerifyBuyerSeller;
