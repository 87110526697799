import { LoadingButton } from "@mui/lab";
import { Card, TextField, Typography, IconButton } from "@mui/material";
import FlexBox from "../../components/FlexBox";
import AppLayout from "../../components/layout/AppLayout";
import Navbar from "../../components/navbar/Navbar";
import * as yup from "yup";
import { useFormik } from "formik";
import { postResetPassword } from "../../clients/reset-password";
import { handleApiResponse } from "../../utils/api/error-handling/apiErrorHandler";
import { useAppContext } from "../../contexts/app/AppContext";
import { Fragment, useState, useCallback } from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useSearchParams } from "react-router-dom";

const ResetPasswordPage = () => {
  const [isSent, setIsSent] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchParams] = useSearchParams();
  const { dispatch } = useAppContext();

  const togglePasswordVisibility = useCallback(() => {
    setPasswordVisibility((visible) => !visible);
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      values.token = searchParams.get("token");
      postResetPassword(values).then((response) => {
        handleApiResponse(response, dispatch, {
          on_success: () => {
            setIsSent(true);
            setIsSubmitting(false);
          },
          on_error: () => {
            setIsSubmitting(false);
          },
        });
      });
    },
  });

  return (
    <AppLayout navbar={<Navbar />}>
      <FlexBox
        sx={{
          minHeight: "40vh",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 2,
        }}
      >
        <Card
          sx={{
            padding: 2,
          }}
        >
          <FlexBox
            sx={{
              flexDirection: "column",
              gap: 1,
            }}
          >
            {!isSent && (
              <Fragment>
                <Typography variant="h4">Resetowanie Hasła</Typography>
                <TextField
                  name="email"
                  label="Email"
                  placeholder="exmple@mail.com"
                  variant="outlined"
                  size="normal"
                  type="email"
                  disabled={isSubmitting}
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email || ""}
                  error={!!formik.touched.email && !!formik.errors.email}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                  name="password"
                  label="Hasło"
                  placeholder="*********"
                  autoComplete="on"
                  type={passwordVisibility ? "text" : "password"}
                  variant="outlined"
                  size="normal"
                  disabled={isSubmitting}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        size="small"
                        type="button"
                        onClick={togglePasswordVisibility}
                      >
                        {passwordVisibility ? (
                          <Visibility
                            className="passwordEye"
                            fontSize="small"
                          />
                        ) : (
                          <VisibilityOff
                            className="passwordEye"
                            fontSize="small"
                          />
                        )}
                      </IconButton>
                    ),
                  }}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password || ""}
                  error={!!formik.touched.password && !!formik.errors.password}
                  helperText={formik.touched.password && formik.errors.password}
                />

                <TextField
                  name="password_confirmation"
                  label="Powtórz Hasło"
                  placeholder="*********"
                  autoComplete="on"
                  type={passwordVisibility ? "text" : "password"}
                  variant="outlined"
                  disabled={isSubmitting}
                  size="normal"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        size="small"
                        type="button"
                        onClick={togglePasswordVisibility}
                      >
                        {passwordVisibility ? (
                          <Visibility
                            className="passwordEye"
                            fontSize="small"
                          />
                        ) : (
                          <VisibilityOff
                            className="passwordEye"
                            fontSize="small"
                          />
                        )}
                      </IconButton>
                    ),
                  }}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password_confirmation || ""}
                  error={
                    !!formik.touched.password_confirmation &&
                    !!formik.errors.password_confirmation
                  }
                  helperText={
                    formik.touched.password_confirmation &&
                    formik.errors.password_confirmation
                  }
                />
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  onClick={formik.handleSubmit}
                >
                  Resetuj Hasło
                </LoadingButton>
              </Fragment>
            )}
            {isSent && (
              <Fragment>
                <Typography component="span" sx={{ display: "flex", gap: 1 }}>
                  <CheckCircleOutlinedIcon color="success" />
                  Hasło zostało zresetowane.
                </Typography>
              </Fragment>
            )}
          </FlexBox>
        </Card>
      </FlexBox>
    </AppLayout>
  );
};
const initialValues = {
  email: "",
  password: "",
  password_confirmation: "",
};
const validationSchema = yup.object({
  email: yup
    .string()
    .email("Niepoprawny adres email")
    .required("Pole wymagane"),
  password: yup
    .string()
    .required("Hasło jest wymagane")
    .min(8, "Hasło nie może mieć mniej niż 8 znaków"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Hasła nie są identyczne")
    .required("Proszę powtórzyć hasło"),
});
export default ResetPasswordPage;
