import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import CustomerDashboardNavigation from "../../components/layout/CustomerDashboardNavigation";
import DashboardLayout from "../../components/layout/CustomerDashboardLayout";
import DashboardPageHeader from "../../components/layout/DashboardPageHeader";
import InspectorInspectionList from "../../components/inspector-inspections/InspectorInspectionList";

export const InspectorInspectionsHistory = () => {
  return (
    <DashboardLayout>
      <DashboardPageHeader
        icon={FactCheckOutlinedIcon}
        title="Inspekcje"
        navigation={<CustomerDashboardNavigation />}
      />
      <InspectorInspectionList historical={true} />
    </DashboardLayout>
  );
};
export default InspectorInspectionsHistory;
