import apiClient from "./api-client";
import { sortProductImages } from "../utils/utils";

export const getSearchProducts = async (payload, page = 1) => {
  const response = await apiClient.post(
    "/search-products?page=" + page,
    JSON.stringify(payload)
  );
  return response.data;
};

export const getFilterRanges = async () => {
  const response = await apiClient.get("/filter-ranges");
  return response.data;
};

export const getFeaturedProducts = async () => {
  const response = await apiClient.get("/search-products/featured");
  response.data.data.map((product) => sortProductImages(product));
  return response.data;
};
