import apiClient from "./api-client";
import { sortProductImages } from "../utils/utils";

export const getDetailProduct = async (slug) => {
  const response = await apiClient.get("/detail-products/" + slug);
  response.data.data = sortProductImages(response.data.data);
  return response.data;
};

export const getVehicleHistory = async (slug) => {
  const response = await apiClient.get("/vehicle-histories/" + slug);
  return response.data;
};

export const getSlugProducts = async () => {
  const response = await apiClient.get("/slugs/products", {
    cache: {
      maxAge: 60 * 60 * 1000,
    },
  });
  return response.data;
};

export const getFormattedSlugProducts = async () => {
  const response = await getSlugProducts();
  const { data } = response;
  return data.map((product) => {
    return {
      params: {
        slug: product.attributes.slug,
      },
    };
  });
};

export const getProductPledge = async (id) => {
  return await apiClient.get(`vehicle-pledges/${id}`);
};
