/* eslint-disable react-hooks/exhaustive-deps */
import FlexBox from "../FlexBox";
import { useAppContext } from "../../contexts/app/AppContext";
import {
  Box,
  Divider,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { notificationTypes } from "./NotificationTypes";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/system";
import { Link } from "react-router-dom";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { deleteNotification } from "../../clients/notifications";
import { Fragment } from "react";

const NotificationSidePanel = ({ close_action }) => {
  const theme = useTheme();
  const { state, dispatch } = useAppContext();

  const notifications = state.user.messages;

  // show loading while data is being fetched
  if (!notifications) {
    return (
      <Fragment>
        <Box p={2}>
          <Button variant="outlined" color="primary" onClick={close_action}>
            Zamknij
          </Button>
        </Box>

        <Box
          width="380px"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      </Fragment>
    );
  }

  // make notification from api response data
  const makeNotification = (notification) => {
    // get style from NotificationTypes
    let notificationStyle = notificationTypes[notification.attributes.type];
    // Default fallback
    if (!notificationStyle) {
      notificationStyle = notificationTypes.default;
    }
    return (
      <Box
        key={notification.id.toString()}
        sx={{
          padding: "10px",
        }}
      >
        <Divider />
        <FlexBox
          color={notificationStyle.color}
          sx={{
            alignItems: "center",
          }}
        >
          {notificationStyle.icon}
          <Typography variant="h6">
            {notificationStyle.title ? notificationStyle.title : ""}
          </Typography>
          <IconButton
            sx={{
              marginLeft: "auto",
            }}
            onClick={() => {
              deleteNotification(notification.id);
              let new_messages = state.user.messages.messages.filter(
                (elem) => elem.id != notification.id
              );
              dispatch({
                type: "SET_NOTIFICATIONS",
                payload: {
                  ...state.user.messages,
                  messages: new_messages,
                  attributes: {
                    ...state.user.messages.attributes,
                    counters: {
                      ...state.user.messages.attributes.counters,
                      messages: new_messages.length,
                    },
                  },
                },
              });
            }}
          >
            <CloseIcon />
          </IconButton>
        </FlexBox>

        <Typography align="justify" variant="body2">
          {notification.attributes.message}
        </Typography>
        {!!notification.attributes.action && (
          <Box
            sx={{
              display: "flex",
              marginBottom: "10px",
              flexDirection: "row-reverse",
            }}
          >
            <a href={notification.attributes.action}>
              <Button variant="outlined" color="primary" size="small">
                Podgląd
              </Button>
            </a>
          </Box>
        )}
        <Divider />
      </Box>
    );
  };

  return (
    <Box width="100%">
      <Box overflow="auto">
        <FlexBox
          alignItems="center"
          m="0px 20px"
          height="74px"
          color="primary.main"
        >
          <NotificationsNoneIcon color="inherit" />
          <Box fontWeight={600} fontSize="16px" ml={1}>
            Powiadomienia - {notifications.attributes.counters.messages}
          </Box>
          <Box
            sx={{
              marginLeft: "auto",
              display: "none",
              [theme.breakpoints.down("md")]: {
                display: "block !important",
              },
            }}
          >
            <Button variant="outlined" color="primary" onClick={close_action}>
              Zamknij
            </Button>
          </Box>
        </FlexBox>

        <Divider />
        {notifications.messages.map((elem) => makeNotification(elem))}
        {notifications.attributes.counters.messages === 0 && (
          <FlexBox
            sx={{
              flexDirection: "column",
              color: "grey.700",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <SentimentSatisfiedAltIcon fontSize="large" />
            <Typography>Brak nowych powiadomień</Typography>
          </FlexBox>
        )}
      </Box>
    </Box>
  );
};

NotificationSidePanel.defaultProps = {
  toggleSidenav: () => {},
};
export default NotificationSidePanel;
