import QuestionRoot from "./QuestionRoot";
import { finishInspection } from "../../clients/inspection-results";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import FlexBox from "../FlexBox";
import { useAppContext } from "../../contexts/app/AppContext";

const FinishInspection = ({ handle_prev, data, inspection_id }) => {
  const { state } = useAppContext();
  const isInspector =
    state.user?.user?.attributes?.claim?.includes("inspector");

  useEffect(() => {
    if (!data.attributes.is_finished) {
      finishInspection(inspection_id)
        .then((response) => {
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <QuestionRoot question="Inspekcja Ukończona" handle_prev={handle_prev}>
      <FlexBox
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CheckCircleOutlineOutlinedIcon
          sx={{ height: "100px", width: "unset" }}
          color="success"
        />
      </FlexBox>
      <Link to={isInspector ? "/inspector-inspections" : "/inspections"}>
        <Button fullWidth color="primary" variant="outlined">
          Powrót do listy inspekcji
        </Button>
      </Link>
    </QuestionRoot>
  );
};
export default FinishInspection;
