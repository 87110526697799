import QuestionRoot from "../QuestionRoot";
import { Switch, FormControlLabel } from "@mui/material";
import { useState, useEffect } from "react";
import { postQuestionResults } from "../../../clients/inspection-results";

const QuestionMultiChoice = ({
  definition,
  handle_next,
  handle_prev,
  question_idx,
  disabled,
  inspection_id,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [answers, setAnswers] = useState([]);

  // try setting initial values from results
  useEffect(() => {
    if (disabled) {
      try {
        setAnswers(definition.result[definition.steps.field]);
      } catch {}
    } else {
      setAnswers([]);
    }
  }, [definition]);

  const handleSubmit = () => {
    setIsLoading(true);
    // if disabled just navigate to next page
    if (disabled) {
      handle_next();
      setIsLoading(false);
      return;
    }
    // else post results
    postQuestionResults(inspection_id, question_idx, {
      [definition.steps.field]: answers,
    })
      .then((response) => {
        handle_next(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <QuestionRoot
      question={definition.steps.question}
      question_group={definition.group_name}
      isLoading={isLoading}
      handle_prev={handle_prev}
      handle_next={handleSubmit}
    >
      {definition.steps.answers.map((elem) => (
        <FormControlLabel
          key={elem.label}
          disabled={disabled || isLoading}
          control={
            <Switch
              value={answers.includes(elem.label) || ""}
              checked={!!answers.includes(elem.label)}
              onChange={() => {
                if (!answers.includes(elem.label)) {
                  setAnswers((answers) => [...answers, elem.label]);
                } else {
                  setAnswers((answers) =>
                    answers.filter((o) => o !== elem.label)
                  );
                }
              }}
            />
          }
          label={elem.label}
        />
      ))}
    </QuestionRoot>
  );
};
export default QuestionMultiChoice;
