import FlexBox from "../FlexBox";
import { Link } from "react-router-dom";
import { useAppContext } from "../../contexts/app/AppContext";
import {
  Card,
  IconButton,
  Skeleton,
  Typography,
  Button,
  useTheme,
} from "@mui/material";
import { getCurrentInspections } from "../../clients/inspector-inspections";
import {
  handleApiResponse,
  genericErrorToSnackbar,
} from "../../utils/api/error-handling/apiErrorHandler.js";
import { useState, useEffect, Fragment } from "react";
import { format } from "date-fns";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const InspectorInspectionList = ({ historical }) => {
  const { dispatch } = useAppContext();
  const [data, setData] = useState();
  const [openMap, setOpenMap] = useState({});
  const theme = useTheme();

  // handle opening and closing of elements
  const handleCollapseElement = (id) => {
    setOpenMap((openMap) => ({ ...openMap, [id]: !openMap[id] }));
  };

  // get data from api and fetch data from api
  useEffect(() => {
    getCurrentInspections()
      .then((response) => {
        handleApiResponse(response, dispatch, {
          on_success: () => {
            setData(
              response.data.data.filter(
                (elem) => !!elem.attributes.is_finished === historical
              )
            );
          },
        });
      })
      .catch((err) => {
        genericErrorToSnackbar(err, dispatch);
      });
  }, []);

  if (!data) {
    return (
      <Card
        sx={{
          display: "flex",
          padding: "10px",
          margin: "10px 0 10px 0",
        }}
      >
        <FlexBox
          sx={{
            flex: 1,
          }}
        >
          <Typography whiteSpace="pre" m={0.75} textAlign="left">
            <Skeleton variant="text" width="30ch" />
          </Typography>
        </FlexBox>
        <FlexBox>
          <Typography flex="1 1 260px !important" m={0.75} textAlign="left">
            <Skeleton variant="text" width="40ch" />
          </Typography>
        </FlexBox>
        <FlexBox>
          <Typography flex="1 1 260px !important" m={0.75} textAlign="left">
            <Skeleton variant="text" width="3ch" />
          </Typography>
        </FlexBox>
      </Card>
    );
  }

  if (data.length == 0) {
    return (
      <Fragment>
        <Card
          sx={{
            display: "flex",
            padding: "10px",
            margin: "10px 0 10px 0",
          }}
        >
          <FlexBox
            sx={{
              flex: 1,
            }}
          >
            <Typography whiteSpace="pre" m={0.75} textAlign="left">
              {!historical
                ? "Nie masz żadnych inspekcji do wykonania"
                : "Nie masz historii wykonanych inspekcji"}
            </Typography>
          </FlexBox>
        </Card>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {data.map((row) => (
        <Card
          key={row.id}
          sx={{
            display: "flex",
            padding: "10px",
            margin: "10px 0 10px 0",
            flexDirection: "column",
          }}
        >
          <FlexBox>
            <FlexBox
              sx={{
                flex: 1,
              }}
            >
              <Typography whiteSpace="pre" m={0.75} textAlign="left">
                {row.relationships.product.relationships.model.relationships
                  .producer.attributes.name +
                  " / " +
                  row.relationships.product.relationships.model.attributes.name}
              </Typography>
            </FlexBox>
            <FlexBox>
              <Typography flex="1 1 260px !important" m={0.75} textAlign="left">
                {format(new Date(row.attributes.scheduled_date), "dd.MM.yyyy") +
                  ", " +
                  row.relationships.product.relationships.address.attributes
                    .post_code +
                  " " +
                  row.relationships.product.relationships.address.attributes
                    .place}
              </Typography>
            </FlexBox>
            <FlexBox>
              <Typography whiteSpace="pre" textAlign="right" color="grey.600">
                <IconButton
                  onClick={() => {
                    handleCollapseElement(row.id.toString());
                  }}
                >
                  {!!openMap[row.id.toString()] ? (
                    <ExpandLessIcon fontSize="small" color="inherit" />
                  ) : (
                    <ExpandMoreIcon fontSize="small" color="inherit" />
                  )}
                </IconButton>
              </Typography>
            </FlexBox>
          </FlexBox>

          {!!openMap[row.id.toString()] && (
            <Fragment>
              <FlexBox
                sx={{
                  flexDirection: "column",
                }}
              >
                <Typography variant="h7" color="grey.600">
                  Maszyna
                </Typography>
                <Card
                  elevation={0}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    padding: "5px",
                    [theme.breakpoints.down("md")]: {
                      flexDirection: "column",
                    },
                  }}
                >
                  <Card elevation={1} sx={{ padding: "10px", flex: 1 }}>
                    <Typography>
                      VIN: {row.relationships.product.attributes.vin}
                    </Typography>
                  </Card>
                  <Card elevation={1} sx={{ padding: "10px", flex: 1 }}>
                    <Typography>
                      Rocznik:{" "}
                      {row.relationships.product.attributes.machine_year}
                    </Typography>
                  </Card>
                  <Card elevation={1} sx={{ padding: "10px", flex: 1 }}>
                    <Typography>
                      Roboczogodziny:{" "}
                      {row.relationships.product.attributes.machine_hour}h
                    </Typography>
                  </Card>
                </Card>
              </FlexBox>
              <FlexBox
                sx={{
                  flexDirection: "column",
                }}
              >
                <Typography variant="h7" color="grey.600">
                  Właściciel
                </Typography>
                <Card
                  elevation={0}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    padding: "5px",
                    [theme.breakpoints.down("md")]: {
                      flexDirection: "column",
                    },
                  }}
                >
                  <Card elevation={1} sx={{ padding: "10px", flex: 1 }}>
                    <Typography>
                      {row.relationships.customer.attributes.first_name}{" "}
                      {row.relationships.customer.attributes.last_name}
                    </Typography>
                  </Card>
                  <Card elevation={1} sx={{ padding: "10px", flex: 1 }}>
                    <a
                      href={`tel:${row.relationships.customer.attributes.phone_number}`}
                    >
                      <Typography>
                        tel.:{" "}
                        {row.relationships.customer.attributes.phone_number}
                      </Typography>
                    </a>
                  </Card>
                  <Card elevation={1} sx={{ padding: "10px", flex: 1 }}>
                    <a
                      href={`mailto:${row.relationships.customer.attributes.email}`}
                    >
                      <Typography>
                        email: {row.relationships.customer.attributes.email}
                      </Typography>
                    </a>
                  </Card>
                </Card>
              </FlexBox>
              <FlexBox
                sx={{
                  flexDirection: "column",
                }}
              >
                <Typography variant="h7" color="grey.600">
                  Adres
                </Typography>
                <Card
                  elevation={0}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    padding: "5px",
                    [theme.breakpoints.down("md")]: {
                      flexDirection: "column",
                    },
                  }}
                >
                  <Card elevation={1} sx={{ padding: "10px", flex: 1 }}>
                    <Typography>
                      {
                        row.relationships.product.relationships.address
                          .attributes.address1
                      }
                    </Typography>
                  </Card>
                  <Card elevation={1} sx={{ padding: "10px", flex: 1 }}>
                    <Typography>
                      {
                        row.relationships.product.relationships.address
                          .attributes.post_code
                      }{" "}
                      {
                        row.relationships.product.relationships.address
                          .attributes.place
                      }
                    </Typography>
                  </Card>
                  <Card elevation={1} sx={{ padding: "10px", flex: 1 }}>
                    <Typography>
                      {
                        row.relationships.product.relationships.address
                          .attributes.country
                      }
                    </Typography>
                  </Card>
                </Card>
              </FlexBox>
              <Link to={`/make-inspection/${row.id}`}>
                <Button fullWidth variant="outlined" color="primary">
                  Inspekcja
                </Button>
              </Link>
            </Fragment>
          )}
        </Card>
      ))}
    </Fragment>
  );
};
export default InspectorInspectionList;
