import CategoryOutlined from "../icons/CategoryOutline";
import Home from "../icons/Home";
import ShoppingBagOutlined from "../icons/ShoppingBagOutlined";
import User2 from "../icons/User2";
import NavLink from "../nav-link/NavLink";
import { useAppContext } from "../../contexts/app/AppContext";
import useWindowSize from "../../hooks/useWindowSize";
import { Button, Box, styled, Typography } from "@mui/material";
import { layoutConstant } from "../../utils/constants";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "none",
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  height: layoutConstant.mobileNavHeight,
  justifyContent: "space-around",
  backgroundColor: theme.palette.secondary.main,
  boxShadow: "0px 1px 4px 3px rgba(0, 0, 0, 0.1)",
  zIndex: 999,
  "@media only screen and (max-width: 900px)": {
    display: "flex",
    width: "100vw",
  },
}));
const StyledNavLink = styled(NavLink)(() => ({
  flex: "1 1 0",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "13px",
}));

const StyledButton = styled(Button)(() => ({
  flex: "1 1 0",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "13px",
  fontWeight: 400,
}));

const iconStyle = {
  marginBottom: "4px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const MobileNavigationBar = () => {
  const width = useWindowSize();
  const { state, dispatch } = useAppContext();
  const { user } = state;

  const list = [
    {
      title: "Home",
      icon: Home,
      href: "/",
    },
    {
      title: "Maszyny",
      icon: CategoryOutlined,
      href: "/mobile-category-nav",
    },
    {
      title: "Ulubione",
      icon: ShoppingBagOutlined,
      href: "/wish-list",
    },
    {
      title: "Konto",
      icon: User2,
      href: "/profile",
    },
    {
      title: "Komunikaty",
      onlyLoggedIn: true,
      icon: NotificationsNoneIcon,
      action: () => {
        if (user.showNotifications) {
          dispatch({
            type: "HIDE_NOTIFICATIONS",
            payload: {},
          });
        } else {
          dispatch({
            type: "SHOW_NOTIFICATIONS",
            payload: {},
          });
        }
      },
    },
  ];

  return width <= 900 ? (
    <Wrapper>
      {list.map((item) => {
        if (!!item.onlyLoggedIn && !user?.user) {
          return null;
        }
        if (item.href) {
          return (
            <StyledNavLink
              href={item.href}
              key={item.title}
              style={{ fontWeight: 600 }}
            >
              {<item.icon sx={iconStyle} fontSize="small" />}
              {item.title}
            </StyledNavLink>
          );
        } else if (item.action) {
          return (
            <StyledButton onClick={item.action} key={item.title}>
              {<item.icon sx={iconStyle} fontSize="small" />}
              <Typography fontWeight={600}>{item.title}</Typography>
            </StyledButton>
          );
        }
      })}
    </Wrapper>
  ) : null;
};

export default MobileNavigationBar;
