import CustomerDashboardNavigation from "../layout/CustomerDashboardNavigation";
import DashboardPageHeader from "../layout/DashboardPageHeader";
import TableRow from "../TableRow";
import { H5 } from "../Typography";
import { Skeleton, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import PercentIcon from "@mui/icons-material/Percent";
import { getCustomerPaymentList } from "../../clients/payments";
import PaymentRow from "./PaymentRow";

const CustomerPaymentList = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    getCustomerPaymentList()
      .then((response) => {
        const { data } = response;
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {});
  }, []);

  return (
    <Fragment>
      <DashboardPageHeader
        title="Moje płatności"
        icon={PercentIcon}
        navigation={<CustomerDashboardNavigation />}
      />

      <TableRow
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
          padding: "0px 18px",
          background: "none",
        }}
        elevation={0}
      >
        <H5 color="grey.600" my="0px" mx={0.75} textAlign="left">
          Typ
        </H5>
        <H5 color="grey.600" my="0px" mx={0.75} textAlign="left">
          Referencja
        </H5>
        <H5 color="grey.600" my="0px" mx={0.75} textAlign="left">
          Cena
        </H5>
        <H5 color="grey.600" my="0px" mx={0.75} textAlign="left">
          Czy zapłacono
        </H5>
        <H5
          flex="0 0 0 !important"
          color="grey.600"
          px={2.75}
          py={0.5}
          my={0}
        ></H5>
      </TableRow>

      {isLoading && (
        <TableRow sx={{ my: "1rem", padding: "6px 18px" }}>
          <Typography flex="0 0 190px !important" m={0.75} textAlign="left">
            <Skeleton variant="text" />
          </Typography>
          <Typography flex="0 0 200px !important" m={0.75} textAlign="left">
            <Skeleton variant="text" />
          </Typography>
          <Typography flex="0 0 200px !important" m={0.75} textAlign="left">
            <Skeleton variant="text" />
          </Typography>
          <Typography flex="0 0 150px !important" m={0.75} textAlign="left">
            <Skeleton variant="text" />
          </Typography>
          <Typography whiteSpace="pre" textAlign="right" color="grey.600">
            <Skeleton variant="text" />
          </Typography>
        </TableRow>
      )}
      {data.map((row) => (
        <PaymentRow key={row.id} item={row} />
      ))}
      {!isLoading && data.length === 0 && (
        <TableRow sx={{ my: "1rem", padding: "6px 18px" }}>
          <Typography flex="0 0 190px !important" m={0.75} textAlign="left">
            Brak płatności
          </Typography>
        </TableRow>
      )}
    </Fragment>
  );
};

export default CustomerPaymentList;
