import React from "react";
import KeyboardDoubleArrowLeftSharpIcon from "@mui/icons-material/KeyboardDoubleArrowLeftSharp";
import KeyboardDoubleArrowRightSharpIcon from "@mui/icons-material/KeyboardDoubleArrowRightSharp";
import { error, success } from "../../theme/themeColors";

const Order = ({ name }) => {
  if (name == "buy")
    return (
      <KeyboardDoubleArrowLeftSharpIcon
        fontSize="medium"
        color="inherit"
        sx={{ color: error[800] }}
      />
    );

  return (
    <KeyboardDoubleArrowRightSharpIcon
      fontSize="medium"
      color="inherit"
      sx={{ color: success[800] }}
    />
  );
};

export default Order;
