import apiClient from "./api-client";

export const getAvilableInspectors = async (date, product_id) => {
  try {
    let response = await apiClient.get(
      `/inspectors/available/${product_id}/${date}`
    );
    if (response.data.data) {
      return response.data.data;
    }
    return [];
  } catch {
    return [];
  }
};

export const orderInspection = async (data) => {
  return await apiClient.post("order-inspections", data);
};

export const getInspectionDetails = async (id) => {
  return await apiClient.get(`/order-inspections/${id}`);
};

export const getInspections = async () => {
  return await apiClient.get("/inspections");
};

export const createInspection = async (id) => {
  let data = {
    fk_product: id,
  };
  return await apiClient.post("/inspections", JSON.stringify(data));
};
