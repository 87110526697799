import { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import QuestionRoot from "../QuestionRoot";
import { TextField } from "@mui/material";
import { postQuestionResults } from "../../../clients/inspection-results";
import { useEffect } from "react";

const QuestionInputStatic = ({
  definition,
  handle_next,
  handle_prev,
  question_idx,
  disabled,
  inspection_id,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const shape = {};
  definition.steps.answers.forEach((elem) => {
    shape[elem.field] = Yup.string().required("Pole wymagane");
  });
  // make all fields required
  const validationSchema = Yup.object().shape(shape);

  const initialValues = {};
  definition.steps.answers.forEach((elem) => {
    initialValues[elem.field] = definition.result?.[elem.field] || "";
  });

  const formik = useFormik({
    // if data is already present - aka this step was already done
    // set initial values to show data
    initialValues: initialValues,
    onSubmit: async (values) => {
      // if disabled - this step was already done navigate to next
      if (disabled) {
        handle_next();
        return;
      }
      setIsLoading(true);
      postQuestionResults(inspection_id, question_idx, values)
        .then((response) => {
          handle_next(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    validationSchema: validationSchema,
  });

  useEffect(() => {
    if (disabled) {
      let read_only_values = {};
      definition.steps.answers.forEach((elem) => {
        read_only_values[elem.field] = definition.result?.[elem.field] || "";
      });
      formik.setValues(read_only_values);
    }
  }, [definition]);

  return (
    <QuestionRoot
      question={definition.steps.question}
      question_group={definition.group_name}
      isLoading={isLoading}
      handle_prev={handle_prev}
      handle_next={formik.handleSubmit}
    >
      {definition.steps.answers.map((elem) => (
        <TextField
          key={elem.label}
          label={elem.label}
          value={formik.values[elem.field] || ""}
          onChange={formik.handleChange(elem.field)}
          onBlur={formik.handleBlur(elem.field)}
          error={formik.touched[elem.field] && !!formik.errors[elem.field]}
          helperText={formik.touched[elem.field] && formik.errors[elem.field]}
          mode="outlined"
          disabled={disabled || isLoading}
        />
      ))}
    </QuestionRoot>
  );
};
export default QuestionInputStatic;
