import FlexBox from "../../components/FlexBox";
import DashboardLayout from "../../components/layout/CustomerDashboardLayout";
import CustomerDashboardNavigation from "../../components/layout/CustomerDashboardNavigation";
import DashboardPageHeader from "../../components/layout/DashboardPageHeader";
import TableRow from "../../components/TableRow";
import { H5, Paragraph } from "../../components/Typography";
import ShoppingBag from "@mui/icons-material/ShoppingBag";
import {
  Card,
  CircularProgress,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { Box, useTheme } from "@mui/system";
import React, { Fragment, useEffect, useState } from "react";
import Price from "../../components/Price";
import { useAppContext } from "../../contexts/app/AppContext";
import { genericErrorToSnackbar } from "../../utils/api/error-handling/apiErrorHandler";
import { getCustomerPaymentDetails } from "../../clients/payments";
import { format } from "date-fns";
import { useParams } from "react-router-dom";

const yesNo = function (value) {
  if (value === 0) return "Nie";
  else return "Tak";
};

const renderType = function (type) {
  switch (type) {
    case "order":
      return "zamowienie ";
    case "inspection":
      return "inspekcja ";
    default:
      return "inny";
  }
};

const orderInfo = (paymentData) => {
  return (
    <Grid item lg={6} md={6} xs={12}>
      <Card
        sx={{
          p: "20px 30px",
        }}
      >
        <Grid item lg={6} md={6} xs={12} my="20px">
          <H5 mt={0} mb={0}>
            Producent
          </H5>
          <Paragraph fontSize="14px" my="1px" mx="8px">
            {
              paymentData.relationships.product.relationships.model
                .relationships.producer.attributes.name
            }
          </Paragraph>
        </Grid>
        <Grid item lg={6} md={6} xs={12} my="20px">
          <H5 mt={0} mb={0}>
            VIN
          </H5>
          <Paragraph fontSize="14px" my="1px" mx="8px">
            {paymentData.relationships.product.attributes.vin}
          </Paragraph>
        </Grid>
        <Grid item lg={6} md={6} xs={12} my="20px">
          <H5 mt={0} mb={0}>
            Model
          </H5>
          <Paragraph fontSize="14px" my="1px" mx="8px">
            {
              paymentData.relationships.product.relationships.model.attributes
                .name
            }
          </Paragraph>
        </Grid>
        <Grid item lg={6} md={6} xs={12} my="20px">
          <H5 mt={0} mb={0}>
            Rok produkcji
          </H5>
          <Paragraph fontSize="14px" my="1px" mx="8px">
            {paymentData.relationships.product.attributes.machine_year}
          </Paragraph>
        </Grid>
      </Card>
    </Grid>
  );
};

const inspectionInfo = (paymentData) => {
  return (
    <Grid item lg={6} md={6} xs={12}>
      <Card
        sx={{
          p: "20px 30px",
        }}
      >
        <Grid item lg={10} md={10} xs={12} my="20px">
          <H5 mt={0} mb={0}>
            Czy inspekcja zakończona?
          </H5>
          <Paragraph fontSize="14px" my="1px" mx="8px">
            {yesNo(paymentData.relationships.inspection.attributes.is_finished)}
          </Paragraph>
        </Grid>
      </Card>
    </Grid>
  );
};

const paymentInfo = (paymentData) => {
  switch (paymentData.attributes.type) {
    case "order":
      return orderInfo(paymentData);
    case "inspection":
      return (
        <Grid container spacing={3}>
          {" "}
          {orderInfo(paymentData)} {inspectionInfo(paymentData)}
        </Grid>
      );
    default:
      return "";
  }
};

const PaymentDetails = () => {
  const [paymentData, setPaymentData] = useState();
  const { dispatch } = useAppContext();
  const { id } = useParams();

  useEffect(() => {
    if (id !== undefined) {
      getCustomerPaymentDetails(id)
        .then((response) => {
          setPaymentData(response.data);
        })
        .catch((err) => {
          genericErrorToSnackbar(err, dispatch);
        });
    }
  }, [id]);

  if (!paymentData) {
    return (
      <DashboardLayout>
        <DashboardPageHeader
          title="Szczegóły płatności"
          icon={ShoppingBag}
          navigation={<CustomerDashboardNavigation />}
        />
        <Card
          sx={{
            minHeight: "40vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Card>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardPageHeader
        title="Szczegóły płatności"
        icon={ShoppingBag}
        navigation={<CustomerDashboardNavigation />}
      />
      <Card
        sx={{
          p: "0px",
          mb: "30px",
        }}
      >
        <TableRow
          sx={{
            bgcolor: "grey.200",
            p: "12px",
            boxShadow: "none",
            borderRadius: 0,
          }}
        >
          <FlexBox className="pre" m={0.75} alignItems="center">
            <Typography fontSize="14px" color="grey.600" mr={0.5}>
              Typ:
            </Typography>
            <Typography fontSize="14px">
              {" "}
              {renderType(paymentData.attributes.type)}{" "}
            </Typography>
          </FlexBox>
          <FlexBox className="pre" m={0.75} alignItems="center">
            <Typography fontSize="14px" color="grey.600" mr={0.5}>
              Referencja:
            </Typography>
            <Typography fontSize="14px">
              {paymentData.attributes.reference}
            </Typography>
          </FlexBox>

          <FlexBox className="pre" m={0.75} alignItems="center" flexWrap="wrap">
            <a
              href={paymentData.attributes.temporary_path}
              target="_blank"
              rel="noreferrer"
            >
              <Button
                target="_blank"
                color="primary"
                variant="outlined"
                value="download"
              >
                Pobierz dokument
              </Button>
            </a>
            <Typography variant="caption" component="span">
              Link jest ważny 10 minut <br />
              od odświeżenia strony
            </Typography>
          </FlexBox>

          <FlexBox className="pre" m={0.75} alignItems="center">
            <Typography fontSize="14px" color="grey.600" mr={0.5}></Typography>
            <Typography fontSize="14px"></Typography>
          </FlexBox>
        </TableRow>
        <Box py={1}>
          <FlexBox px={2} py={0} flexWrap="wrap" alignItems="center">
            <Grid item lg={6} md={6} xs={12} my="20px">
              <H5 mt={0} mb={0}>
                Tytuł
              </H5>
              <Paragraph fontSize="14px" my="1px" mx="8px">
                {paymentData.attributes.subject}
              </Paragraph>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <H5 mt={0} mb={0}>
                Cena
              </H5>
              <Price currency={"PLN"} value={paymentData.attributes.price} />
            </Grid>
            <Grid item lg={6} md={6} xs={12} my="20px">
              <H5 mt={0} mb={0}>
                Płatne do
              </H5>
              <Paragraph fontSize="14px" my="1px" mx="8px">
                {format(
                  new Date(paymentData.attributes.payable_to),
                  "dd.MM.yyyy"
                )}
              </Paragraph>
            </Grid>
            <Grid item lg={6} md={6} xs={12} my="20px">
              <H5 mt={0} mb={0}>
                Zapłacono
              </H5>
              <Paragraph fontSize="14px" my="1px" mx="8px">
                {yesNo(paymentData.attributes.is_paid)}
              </Paragraph>
            </Grid>
          </FlexBox>
        </Box>
      </Card>
      {paymentInfo(paymentData)}
    </DashboardLayout>
  );
};

export default PaymentDetails;
