import { makeDisplayElement, routes } from "../../components/about/Routes";
import { CircularProgress } from "@mui/material";
import AppLayout from "../../components/layout/AppLayout";
import Navbar from "../../components/navbar/Navbar";
import FlexBox from "../../components/FlexBox";
import Error404 from "../404";
import { getPageContent } from "../../clients/cms";
import { useState, useEffect } from "react";
import { genericErrorToSnackbar } from "../../utils/api/error-handling/apiErrorHandler.js";
import { useAppContext } from "../../contexts/app/AppContext";
import { useParams } from "react-router-dom";

const AboutPages = () => {
  const { page } = useParams();
  const { dispatch } = useAppContext();
  const [pageContent, setPageContent] = useState();

  useEffect(() => {
    if (page) {
      setPageContent();
      getPageContent(page)
        .then((response) => {
          setPageContent(response.data.attributes);
        })
        .catch((err) => {
          genericErrorToSnackbar(err, dispatch);
          setPageContent(err);
        });
    }
  }, [page]);

  if (!page || !pageContent) {
    return (
      <AppLayout navbar={<Navbar />}>
        <FlexBox
          flexDirection="column"
          minHeight="60vh"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </FlexBox>
      </AppLayout>
    );
  }

  if (pageContent instanceof Error) {
    return <Error404 />;
  }

  return (
    <AppLayout title={routes[page].display_name} navbar={<Navbar />}>
      {makeDisplayElement(pageContent)}
    </AppLayout>
  );
};

export default AboutPages;
