import { Box, styled } from "@mui/material";
import CategoryMenuItem from "./CategoryMenuItem";
import MegaMenu1 from "./mega-menu/MegaMenu1";
import MegaMenu2 from "./mega-menu/MegaMenu2";

// styled component
const Wrapper = styled(Box)(({ theme, position, open }) => ({
  position: position || "unset",
  padding: "0.5rem 0px",
  left: 0,
  right: "auto",
  top: position === "absolute" ? "calc(100% + 0.7rem)" : "0.5rem",
  borderRadius: 4,
  transform: open ? "scaleY(1)" : "scaleY(0)",
  transformOrigin: "top",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  transition: "all 250ms ease-in-out",
  zIndex: 98,
}));

const CategoryMenuCard = ({ categories, open, position }) => {
  const megaMenu = {
    MegaMenu1,
    MegaMenu2,
  };
  return (
    <Wrapper open={open} position={position}>
      {categories?.map((item, idx) => {
        let MegaMenu = megaMenu["MegaMenu2"];
        return (
          <CategoryMenuItem
            title={item.attributes.name}
            href={"/catalog/" + item.attributes.slug}
            caret={!!item.relationships?.subcategories}
            key={item.attributes.name}
          >
            <MegaMenu
              idx={idx}
              data={item.relationships?.subcategories || {}}
            />
          </CategoryMenuItem>
        );
      })}
    </Wrapper>
  );
};

CategoryMenuCard.defaultProps = {
  position: "absolute",
};
export default CategoryMenuCard;
