import DashboardLayout from "../../components/layout/CustomerDashboardLayout";
import DashboardPageHeader from "../../components/layout/DashboardPageHeader";
import CustomerDashboardNavigation from "../../components/layout/CustomerDashboardNavigation";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { InspectionDetails } from "../../components/inspections/InspectionDetails";
import { useParams } from "react-router-dom";

const InspectionDetailsPage = () => {
  // get id from url
  const { id } = useParams();

  return (
    <DashboardLayout>
      <DashboardPageHeader
        icon={FactCheckOutlinedIcon}
        title={"Widok inspekcji"}
        navigation={<CustomerDashboardNavigation />}
      />
      <InspectionDetails id={id} />
    </DashboardLayout>
  );
};

export default InspectionDetailsPage;
