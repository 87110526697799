import { getInspections } from "../../clients/inspections";
import { useAppContext } from "../../contexts/app/AppContext";
import { useEffect, useState, Fragment } from "react";
import {
  genericErrorToSnackbar,
  handleApiResponse,
} from "../../utils/api/error-handling/apiErrorHandler";
import { Card, Typography, IconButton, Skeleton, Box } from "@mui/material";
import FlexBox from "../FlexBox";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { format } from "date-fns";
import { Link } from "react-router-dom";

const InspectionList = () => {
  const { dispatch } = useAppContext();
  const [data, setData] = useState();

  // get inspections list from api
  useEffect(() => {
    getInspections()
      .then((response) => {
        handleApiResponse(response, dispatch, {
          on_success: () => {
            setData(response.data.data);
          },
        });
      })
      .catch((err) => {
        genericErrorToSnackbar(err, dispatch);
      });
  }, []);

  const determineColor = (row) => {
    if (!!row.attributes.is_finished) {
      return "success.100";
    } else if (row.relationships.result !== null) {
      return "warning.100";
    } else {
      return "error.100";
    }
  };

  const makeInspectionRow = (row) => {
    return (
      <Card
        key={row.id}
        sx={{
          display: "flex",
          padding: "10px",
          margin: "10px 0 10px 0",
          flexDirection: "column",
          backgroundColor: determineColor(row),
        }}
      >
        <FlexBox>
          <FlexBox
            sx={{
              flex: 1,
              justifyContent: "space-between",
            }}
          >
            <Typography whiteSpace="pre" m={0.75} textAlign="left">
              {row.relationships.product.relationships.model.relationships
                .producer.attributes.name +
                " " +
                row.relationships.product.relationships.model.attributes.name}
            </Typography>
            <Typography
              sx={{ float: "right" }}
              whiteSpace="pre"
              m={0.75}
              textAlign="left"
            >
              {format(new Date(row.attributes.scheduled_date), "dd.MM.yyyy")}
            </Typography>
          </FlexBox>
          <FlexBox>
            <Typography whiteSpace="pre" textAlign="right" color="grey.600">
              <Link to={`/inspections/${row.id}`}>
                <IconButton>
                  <ArrowForwardIosIcon fontSize="small" color="inherit" />
                </IconButton>
              </Link>
            </Typography>
          </FlexBox>
        </FlexBox>
      </Card>
    );
  };

  if (!data) {
    return (
      <Fragment>
        <Card
          sx={{
            display: "flex",
            padding: "10px",
            margin: "10px 0 10px 0",
          }}
        >
          <FlexBox
            sx={{
              flex: 1,
            }}
          >
            <Skeleton variant="text" width="100%" />
          </FlexBox>
        </Card>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Typography variant="h6">Rozszerzone</Typography>
      {data.filter((elem) => elem.attributes.fk_inspection_type === 2)
        .length === 0 && (
        <Fragment>
          <Card
            sx={{
              display: "flex",
              padding: "10px",
              margin: "10px 0 10px 0",
            }}
          >
            <FlexBox
              sx={{
                flex: 1,
              }}
            >
              <Typography whiteSpace="pre" m={0.75} textAlign="left">
                Brak zleconych inspekcji
              </Typography>
            </FlexBox>
          </Card>
        </Fragment>
      )}
      {data
        .filter((elem) => elem.attributes.fk_inspection_type === 2)
        .map((row) => makeInspectionRow(row))}
      <Typography variant="h6">Podstawowe</Typography>
      {data.filter((elem) => elem.attributes.fk_inspection_type === 1)
        .length === 0 && (
        <Fragment>
          <Card
            sx={{
              display: "flex",
              padding: "10px",
              margin: "10px 0 10px 0",
            }}
          >
            <FlexBox
              sx={{
                flex: 1,
              }}
            >
              <Typography whiteSpace="pre" m={0.75} textAlign="left">
                Brak inspekcji podstawowych
              </Typography>
            </FlexBox>
          </Card>
        </Fragment>
      )}
      {data
        .filter((elem) => elem.attributes.fk_inspection_type === 1)
        .map((row) => makeInspectionRow(row))}
      <FlexBox flexDirection="column" gap="5px">
        <FlexBox>
          <Box
            sx={{
              width: "3ch",
              border: "2px solid",
              borderColor: "grey.500",
              backgroundColor: "error.100",
            }}
          ></Box>
          <Typography ml="1ch">- Nie rozpoczęte</Typography>
        </FlexBox>
        <FlexBox>
          <Box
            sx={{
              width: "3ch",
              border: "2px solid",
              borderColor: "grey.500",
              backgroundColor: "warning.100",
            }}
          ></Box>
          <Typography ml="1ch">- Rozpoczęte</Typography>
        </FlexBox>
        <FlexBox>
          <Box
            sx={{
              width: "3ch",
              border: "2px solid",
              borderColor: "grey.500",
              backgroundColor: "success.100",
            }}
          ></Box>
          <Typography ml="1ch">- Ukończone</Typography>
        </FlexBox>
      </FlexBox>
    </Fragment>
  );
};
export default InspectionList;
