import {
  Box,
  TextField,
  InputAdornment,
  styled,
  useTheme,
  Tooltip,
} from "@mui/material";
import { getPricePrediction } from "../../clients/price-prediction";
import FlexBox from "../FlexBox";
import { useState, Fragment, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { updatePrice } from "../../clients/products-management";
import { LoadingButton } from "@mui/lab";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as chartTooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  chartTooltip,
  Legend
);

const chart_options = {
  responsive: true,
  scales: {
    y: {
      ticks: {
        callback: function (value, index, ticks) {
          return value + " PLN";
        },
      },
    },
  },
};

// stack charts on top of each other in mobile
const ChartContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  position: "relative",
  display: "block",
  minHeight: "150px",
  [theme.breakpoints.down("md")]: {
    width: "100% !important",
  },
}));

const PricePrediction = ({ machine_id, setVisibleCallback, current_price }) => {
  const [data, setData] = useState();
  const [priceAccepted, setPriceAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  // get price and Chart data
  useEffect(() => {
    if (machine_id) {
      getPricePrediction(machine_id)
        .then((response) => {
          if (!!response.data?.data) {
            setData(response.data.data);
            setVisibleCallback(true);
          }
        })
        .catch((err) => {});
    }
  }, [machine_id]);

  return (
    <Box
      sx={{
        padding: "10px",
      }}
    >
      <FlexBox
        sx={{
          flexWrap: "wrap",
        }}
      >
        <FlexBox width="100%" flexWrap="wrap">
          <ChartContainer>
            <Line
              options={chart_options}
              data={{
                labels:
                  data?.attributes.chartByYear.map((elem) => `${elem.x} lat`) ||
                  [],
                datasets: [
                  {
                    label: "Cena w zależności od wieku maszyny",
                    data:
                      data?.attributes.chartByYear.map((elem) =>
                        elem.y.toFixed(2)
                      ) || [],
                    borderColor: theme.palette.primary.main,
                    backgroundColor: theme.palette.primary[200],
                  },
                ],
              }}
            />
          </ChartContainer>
          <ChartContainer>
            <Line
              options={chart_options}
              data={{
                labels:
                  data?.attributes.chartByHour.map((elem) => `${elem.x} h`) ||
                  [],
                datasets: [
                  {
                    label: "Cena w zależności od roboczogodzin",
                    data:
                      data?.attributes.chartByHour.map((elem) =>
                        elem.y.toFixed(2)
                      ) || [],
                    borderColor: theme.palette.primary.main,
                    backgroundColor: theme.palette.primary[200],
                  },
                ],
              }}
            />
          </ChartContainer>
        </FlexBox>
      </FlexBox>
      <FlexBox mt="20px" gap="10px">
        <TextField
          color="primary"
          label="Propozycja Ceny"
          InputProps={{
            endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
          }}
          value={data?.attributes.price.toFixed(2) || 0}
        />
        <Tooltip
          title={
            priceAccepted ||
            Math.abs(data?.attributes.price - current_price) < 1
              ? "Cena Zaakceptowana"
              : ""
          }
        >
          <span style={{ width: "100%" }}>
            <LoadingButton
              variant="outlined"
              onClick={() => {
                setIsLoading(true);
                updatePrice(machine_id, data?.attributes.price.toFixed(2) || 0)
                  .then((response) => {})
                  .catch((err) => {})
                  .finally(() => {
                    setPriceAccepted(true);
                    setIsLoading(false);
                  });
              }}
              loading={isLoading}
              color="primary"
              disabled={
                priceAccepted ||
                Math.abs(data?.attributes.price - current_price) < 1
              }
              fullWidth
            >
              Akceptuj Cenę
            </LoadingButton>
          </span>
        </Tooltip>
      </FlexBox>
    </Box>
  );
};
export default PricePrediction;
