import DashboardLayout from "../../../components/layout/CustomerDashboardLayout";
import DashboardPageHeader from "../../../components/layout/DashboardPageHeader";
import CustomerDashboardNavigation from "../../../components/layout/CustomerDashboardNavigation";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import MachineSettings from "../../../components/machines/MachineSettings";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";

const MachineSettingsPage = () => {
  // get id from url
  const { id } = useParams();

  return (
    <DashboardLayout>
      <DashboardPageHeader
        icon={AgricultureIcon}
        title="Ustawienia Oferty"
        navigation={<CustomerDashboardNavigation />}
        button={
          <Link to={`../${id}`}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                px: "2rem",
              }}
            >
              Powrót do maszyny
            </Button>
          </Link>
        }
      />
      <MachineSettings machineId={id} />
    </DashboardLayout>
  );
};

export default MachineSettingsPage;
