import {
  Button,
  Card,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  useTheme,
  FormHelperText,
  Divider,
} from "@mui/material";
import FlexBox from "../FlexBox";
import { useFormik } from "formik";
import * as yup from "yup";
import { Fragment, useState } from "react";
import { omit } from "lodash-es";
import { sendHelpMessage } from "../../clients/help-contact";
import { useAppContext } from "../../contexts/app/AppContext";
import {
  genericErrorToSnackbar,
  handleApiResponse,
} from "../../utils/api/error-handling/apiErrorHandler";
import ContactThankYou from "./ContactThankYou";
import { LoadingButton } from "@mui/lab";

const ContactForm = ({ pageContent }) => {
  const { palette } = useTheme();
  const [agreements, setAgreements] = useState([]);
  const [response, setResponse] = useState();
  const [isSending, setIsSending] = useState(false);
  const { dispatch } = useAppContext();

  // function for handling agreements checkboxes
  // on change add or remove agreement from agreements array
  const handleAgreementsChange = (e) => {
    if (e.target.checked) {
      if (!agreements.includes(e.target.value)) {
        setAgreements((agreements) => [...agreements, e.target.value]);
      }
    } else {
      setAgreements((agreements) =>
        agreements.filter((elem) => elem != e.target.value)
      );
    }
  };

  const handleFormSubmit = (values) => {
    const data = omit(values, ["checkbox_required"]);
    data.agreements = agreements;
    setIsSending(true);
    sendHelpMessage(data)
      .then((response) => {
        handleApiResponse(response, dispatch, {
          on_success: () => {
            setResponse(response.data.data);
          },
        });
      })
      .catch((err) => {
        genericErrorToSnackbar(err, dispatch);
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit: handleFormSubmit,
      validationSchema: formSchema,
    });

  return (
    <FlexBox
      sx={{
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {!!response ? (
        <ContactThankYou name={response.attributes.name} />
      ) : (
        <Fragment>
          <Typography variant="h4">Formularz Kontaktowy</Typography>
          <Card
            sx={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minWidth: "40vw",
              maxWidth: 1200,
            }}
          >
            <FlexBox gap="10px" flexWrap="wrap">
              <TextField
                name="name"
                onChange={handleChange}
                label="Imię i nazwisko"
                value={values.name || ""}
                onBlur={handleBlur}
                variant="outlined"
                size="small"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{
                  flex: 1,
                  minWidth: 200,
                }}
              />
              <TextField
                name="place"
                onChange={handleChange}
                label="Miejscowość"
                value={values.place || ""}
                onBlur={handleBlur}
                variant="outlined"
                size="small"
                error={!!touched.place && !!errors.place}
                helperText={touched.place && errors.place}
                sx={{
                  flex: 1,
                  minWidth: 200,
                }}
              />
            </FlexBox>
            <TextField
              name="email"
              onChange={handleChange}
              type="email"
              label="Email"
              value={values.email || ""}
              onBlur={handleBlur}
              variant="outlined"
              size="small"
              error={!!touched.email && !!errors.email}
              helperText={touched.email && errors.email}
            />
            <TextField
              name="phone_number"
              type="tel"
              onChange={handleChange}
              label="Numer Telefonu"
              value={values.phone_number || ""}
              onBlur={handleBlur}
              variant="outlined"
              size="small"
              error={!!touched.phone_number && !!errors.phone_number}
              helperText={touched.phone_number && errors.phone_number}
            />
            <TextField
              name="message"
              multiline={true}
              onChange={handleChange}
              label="Wiadomość"
              value={values.message || ""}
              onBlur={handleBlur}
              variant="outlined"
              size="small"
              error={!!touched.message && !!errors.message}
              helperText={touched.message && errors.message}
              minRows={4}
            />

            <Typography variant="subtitle1" fontWeight={500} mt={2}>
              Zgoda na przetwarzanie danych
            </Typography>

            <Divider />

            <FormControl fullWidth>
              <FormControlLabel
                name="checkbox_required"
                onChange={(e) => {
                  handleChange(e);
                  handleAgreementsChange(e);
                }}
                value={Object.keys(pageContent)[0]}
                control={
                  <Checkbox
                    size="small"
                    color="secondary"
                    checked={values.checkbox_required || false}
                  />
                }
                label={
                  <FlexBox
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Typography color={palette.grey[700]} variant="body2">
                      {Object.values(pageContent)[0]}
                    </Typography>
                  </FlexBox>
                }
              />
              <FormHelperText error>
                {errors.checkbox_required
                  ? touched.checkbox_required && errors.checkbox_required
                  : ""}
              </FormHelperText>
            </FormControl>

            <Typography variant="subtitle1" fontWeight={500} mt={2}>
              Zgoda na otrzymywanie informacji handlowych
            </Typography>

            <Divider />

            {Object.entries(pageContent)
              .slice(1)
              .map(([key, val]) => (
                <FormControl fullWidth key={key}>
                  <FormControlLabel
                    onChange={(e) => {
                      handleAgreementsChange(e);
                    }}
                    value={key}
                    control={<Checkbox size="small" color="secondary" />}
                    label={
                      <FlexBox
                        flexWrap="wrap"
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Typography color={palette.grey[700]} variant="body2">
                          {val}
                        </Typography>
                      </FlexBox>
                    }
                  />
                </FormControl>
              ))}
            <LoadingButton
              loading={isSending}
              color="primary"
              variant="contained"
              onClick={handleSubmit}
            >
              Wyślij
            </LoadingButton>
          </Card>
        </Fragment>
      )}
    </FlexBox>
  );
};
const formSchema = yup.object().shape({
  name: yup.string().required("Podanie imienia i nazwiska jest wymagane"),
  place: yup.string().required("Podanie miejscowości jest wymagane"),
  email: yup
    .string()
    .email("Proszę podać poprawny adres email")
    .required("Podanie adresu email jest wymagane"),
  phone_number: yup
    .string()
    .matches("^\\+?((\\d*)|( ))*$", "Proszę podać poprawny numer telefonu")
    .required("Podanie numeru telefonu jest wymagane"),
  message: yup.string().required("Wiadomość jest wymagana"),
  checkbox_required: yup.boolean().oneOf([true], "Zgoda wymagana"),
});
const initialValues = {
  name: "",
  place: "",
  email: "",
  phone_number: "",
  message: "",
  checkbox_required: false,
};
export default ContactForm;
