import apiClient from "./api-client";

export const getMachineProducers = async () => {
  const response = await apiClient.get("/machine-producers", {
    cache: {
      maxAge: 60 * 60 * 1000,
    },
  });
  return response.data;
};
