import combineReducers from "./combineReducers";
import { layoutInitialState, layoutReducer } from "./layoutReducer";
import { machinesInitialState, machinesReducer } from "./machinesReducer";
import { snackbarInitialState, snackbarReducer } from "./snackbarReducer";
import { userInitialState, userReducer } from "./userReducer";
export const initialState = {
  layout: layoutInitialState,
  user: userInitialState,
  machines: machinesInitialState,
  snackbar: snackbarInitialState,
};
export const rootReducer = combineReducers({
  layout: layoutReducer,
  user: userReducer,
  machines: machinesReducer,
  snackbar: snackbarReducer,
});
