import FlexBox from "../FlexBox";
import ProductCard from "../product-cards/ProductCard";
import { Span } from "../Typography";
import { Grid, Pagination } from "@mui/material";
import React from "react";

const ProductCard1List = ({ products, pagination, changePage }) => {
  return (
    <div>
      <Grid container spacing={3}>
        {products.map((item, ind) => (
          <Grid item lg={4} sm={6} xs={12} key={ind}>
            <ProductCard item={item} />
          </Grid>
        ))}
      </Grid>

      <FlexBox
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        mt={4}
      >
        <Span color="grey.600">
          Wynik {pagination.from}-{pagination.to} z wszystkich maszyn{" "}
          {pagination.total}
        </Span>
        <Pagination
          count={pagination.last_page}
          page={pagination.current_page}
          variant="outlined"
          color="primary"
          onChange={changePage}
        />
      </FlexBox>
    </div>
  );
};

export default ProductCard1List;
