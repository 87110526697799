/* eslint-disable react-hooks/exhaustive-deps */
import FlexBox from "../FlexBox";
import BazarCard from "../BazarCard";
import { H3 } from "../Typography";
import React from "react";
import { Box, styled } from "@mui/material";
import { Skeleton } from "@mui/material";
const StyledBazarCard = styled(BazarCard)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  margin: "auto",
  overflow: "hidden",
  transition: "all 250ms ease-in-out",
  borderRadius: "10px 10px 10px 10px",
  "&:hover": {
    boxShadow: theme.shadows[2],
    "& .css-1i2n18j": {
      display: "flex",
    },
    "& .controlBox": {
      display: "block",
    },
  },
}));
const ImageWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "inline-block",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));
const ImageBox = styled(Box)(({ theme }) => ({
  padding: 0,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));
const ContentWrapper = styled(Box)(() => ({
  padding: "1rem",
  "& .title, & .categories": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const ProductCardSkeleton = () => {
  return (
    <StyledBazarCard>
      <ImageWrapper>
        <ImageBox>
          <Skeleton variant="rectangular" height={270} />
        </ImageBox>
      </ImageWrapper>

      <ContentWrapper>
        <Box flex="1 1 0" minWidth="0px" mr={1}>
          <H3
            className="title"
            fontSize="14px"
            textAlign="left"
            fontWeight="600"
            color="text.secondary"
            mb={1}
          >
            <Skeleton variant="text" />
          </H3>
          <H3
            className="subtitle1"
            fontSize="13px"
            textAlign="left"
            fontWeight="100"
            color="text.secondary"
            mb={1}
          >
            <Skeleton variant="text" />
          </H3>

          <FlexBox alignItems="center" mt={0.5}>
            <Box pr={1} fontWeight="600" color="primary.main">
              <Skeleton variant="text" width={70} />
            </Box>
          </FlexBox>
        </Box>
      </ContentWrapper>
    </StyledBazarCard>
  );
};

export default ProductCardSkeleton;
